import { Button, Col, Modal, Rate, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AgentImg from "../../assets/images/face-1.jpg";
import Currency from "../../components/Currency";
import { dateString, formatPhone } from "../../helper/functions";
import lang from "../../helper/langHelper";

const ViewOrderTransaction = ({ show, hide, data }) => {
  const [order, setOrder] = useState();

  useEffect(() => {
    if (!data) return;
    setOrder(data);
  }, [data]);

  return (
    <Modal
      open={show}
      width={950}
      okText="Add"
      onCancel={hide}
      cancelText={null}
      footer={[
        <Button key="okay" type="primary" onClick={hide}>
          {lang("Okay")}
        </Button>,
      ]}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal edit_orders"
    >
      <h4 className="modal_title_cls">{lang("Transaction Details")}</h4>
     
      <Row gutter={[45, 0]}>
         <Col span={24} sm={24} lg={12}>
          <div className="order-dtl-card">
            <div className="customer-dtl">
            <div className="bill-info">
                <h6>{lang("Category")}</h6>
                <h5>
                {
                    order?.type == "Credit" ? lang("Wallet Deposit"): lang("Withdraw")
                  }
                   
                </h5>
              </div>
              <div className="bill-info">
                <h6>{lang("Type")}</h6>
                <h5>{order?.type}</h5>
              </div> 
              <div className="bill-info">
                <h6>{lang("Payment Mode")}</h6>
                <h5>
                  {lang("Wire")}
                </h5>
              </div>
              <div className="bill-info">
                <h6>{lang("Amount")}</h6>
                <h5 style={{ textTransform: "uppercase" }}>
                  {
                    order?.type == "Credit" ? "+": "-" 
                  }{" "}
                    <Currency price={order?.amount} />
                </h5>
              </div> 

              <div className="bill-info">
                <h6>{lang("Transaction Date")}</h6>
                <h5 >
                   {dateString(order?.created_at)}
                </h5>
              </div>
              
            </div>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default ViewOrderTransaction;
