import {
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  DatePicker,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import notfound from "../../assets/images/not_found.png";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import "react-phone-input-2/lib/style.css";
import { useAppContext } from "../../context/AppContext";
import apiPath from "../../constants/apiPath";
import lang from "../../helper/langHelper";
import SingleImageUpload from "../../components/SingleImageUpload";
import Currency from "../../components/Currency";

const FileType = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/avif",
  "image/webp",
  "image/gif",
];

const DepsiteModal = ({ show, hide, data, refresh }) => {

  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();

  const onCreate = (values) => {
    const { amount, receipt_number, payment_mod } = values;
    console.log(values, "values");

    let payload = {
      amount,
      receipt_number,
      image,
      payment_mod
    };
    setLoading(true);
    // payload = removeNullValues(payload);

    request({
      url: `${apiPath.deposit}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleUploadLegal = (data) => {
    setImage(data[0]?.url);
  };

  return (
    <Modal
      open={show}
      width={800}
      okText={`${lang("Add")}`}
      cancelText={lang("Cancel")}
      onCancel={hide}
      okButtonProps={{
        form: "create-payment",
        htmlType: "submit",
        loading: loading,
      }}
      className="tab_modal"
    >
      <h4 className="modal_title_cls">{`${lang("Deposit")
        }`}</h4>
      <h6>
        {lang("Total Amount")} :{" "}
        <Currency
          price={data?.balance ? data?.balance : 0}
        />{" "}
      </h6>
      <Form id="create-payment" form={form} onFinish={onCreate} layout="vertical">
        <Row gutter={[16, 16]}>

          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={lang(`Deposit Amount`)}
              name="amount"
              rules={[
                {
                  required: true,
                  message: lang("Deposit Account is required!"),
                },
              ]}
            >
              <InputNumber
                autoComplete="off"
                placeholder={lang(`Deposit Amount`)}
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={lang(`Receipt number`)}
              name="receipt_number"
              rules={[
                {
                  required: true,
                  message: lang("Receipt number is required!"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={lang(`C. Chris`)} />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={lang("Choose a Payment Method")}
              name="payment_mod"
              rules={[{ required: true, message: "please select a type" }]}
            >
              <Radio.Group>
                <Radio value={"Wire"}>{lang("Wire Transfer")}</Radio>
                <Radio value={"Cash"}>{lang("Cash")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              rules={[
                {
                  validator: (_, value) => {
                    if (image !== undefined && image?.length > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(lang("Receipt is required")),
                    );
                  },
                },
              ]}
              label={lang("Upload Receipt")}
              name="image"
              placeholder={lang("Upload Receipt ")}
            >
              <SingleImageUpload
                value={document}
                fileType={FileType}
                imageType={"legal"}
                onChange={(data) => handleUploadLegal(data)}
              />
              {image && image.length > 0 && (
                <div className="mt-2">
                  <Image
                    width={120}
                    src={image !== "" ? image : notfound}
                  ></Image>
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default DepsiteModal;
