import React, { useEffect, useState, useMemo } from "react";

const AudioPlayer = ({
  play,
  src = "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/tawasi-s3/tawasi/audio/file_1721279955800.mp3",
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.createRef();

  useEffect(() => {
    // if(!play) return
    if (!audioRef.current) return;
  
    console.log(audioRef.current, "audioRef.current");
    
    const audio = audioRef.current;

    audio.play().catch((error) => {
      console.error("Autoplay was prevented:", error);
      audioRef?.current?.pause()?.then(() => {
        audio.play();
      });
    });

    return ()=>{
      audioRef?.current?.pause()?.then(() => {
        // audio.play();
      });
    }
  }, [audioRef,play]); 



  return (
    <div style={{ display: 'none' }}>
      <audio controls ref={audioRef} src={src} />
      {/* <button onClick={togglePlay}>{isPlaying ? "Pause" : "Play"}</button> */}
    </div>
  );
};

export default AudioPlayer;
