import { Button, Card, Col, Row, Tabs,Input } from "antd";
import React, { useEffect, useState } from "react";

import apiPath from "../../constants/apiPath";
import lang from "../../helper/langHelper";
import useRequest from "../../hooks/useRequest";
import AddOnCategories from "./_AddOnCategories";
import AddOnItems from "./_AddOnItems";
import FoodCategory from "./_FoodCategory";
import FoodChoice from "./_FoodChoice";
import FoodItems from "./_FoodItems";
import ItemSizes from "./_ItemSizes";
import ImportForm from "../../components/ImportForm";
import useDebounce from "../../hooks/useDebounce";


const { TabPane } = Tabs;

export const MenuTabs = {
  UPLOAD: "Upload Menu",
  FOOD_CATEGORY: "Food Category",
  ITEM_SIZES: "Item Sizes",
  CHOICES: "Choices",
  ADDONS: "Add-ons",
  ITEMS: "Items",
};

function Index() {
  const sectionName = "Menu";
  const routeName = "menu";

  const api = {
    import: apiPath.uploadMenu,
  };

  const { request } = useRequest();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isUploadVisible, setIsUploadVisible] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [selectedTab, setSelectedTab] = useState(lang(MenuTabs.UPLOAD));

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const path = urlParams.get("tab");
    switch (path) {
      case MenuTabs.UPLOAD:
        return setSelectedTab(MenuTabs.UPLOAD);
      default:
        break;
    }
  }, []);

  useEffect(() => {
    setLoading(true);
  }, [refresh, selectedTab]);

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <Tabs
                className="main_tabs"
                onTabClick={handleTabChange}
                activeKey={selectedTab}
                tabBarStyle={{ color: "green" }}
              >
                <TabPane
                  tab={lang(MenuTabs.UPLOAD)}
                  key={lang(MenuTabs.UPLOAD)}
                >
                  <div className="tab_inner_tit menu-tab-mannage">
                    <div className="tab-upload-wrap">
                      <h3>{lang("Upload Excel")}</h3>
                      <p>{lang("uploadMenuDescription")}</p>
                      <div className="btn_grp">
                        <Button
                          className="ms-sm-2 mt-xs-2 outline-btn manag-btn"
                          onClick={(e) => {
                            setIsUploadVisible(true);
                          }}
                        >
                          {lang("withExcel")}
                        </Button>
                        <Button
                          className="ms-sm-2 mt-xs-2 primary_btn manag-btn"
                          onClick={() => {
                            handleTabChange(lang(MenuTabs.ITEMS));
                          }}
                        >
                          {lang("manually")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  tab={lang(MenuTabs.FOOD_CATEGORY)}
                  key={lang(MenuTabs.FOOD_CATEGORY)}
                >
                  <FoodCategory section={lang(sectionName)} selectedTab={selectedTab}/>
                </TabPane>

                <TabPane
                  tab={lang(MenuTabs.ITEM_SIZES)}
                  key={lang(MenuTabs.ITEM_SIZES)}
                >
                  <ItemSizes section={lang(sectionName)}  selectedTab={selectedTab}/>
                </TabPane>

                <TabPane
                  tab={lang(MenuTabs.CHOICES)}
                  key={lang(MenuTabs.CHOICES)}
                >
                  <FoodChoice section={lang(sectionName)}  selectedTab={selectedTab}/>
                </TabPane>

                <TabPane
                  tab={lang(MenuTabs.ADDONS)}
                  key={lang(MenuTabs.ADDONS)}
                >
                  <AddOnCategories section={lang(sectionName)}  selectedTab={selectedTab}/>
                </TabPane>

                <TabPane tab={lang(MenuTabs.ITEMS)} key={lang(MenuTabs.ITEMS)}>
                  <FoodItems section={lang(sectionName)}  selectedTab={selectedTab}/>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
        {selectedTab === lang(MenuTabs.ADDONS) && (
          <AddOnItems section={lang(sectionName)} />
        )}

        {isUploadVisible && (
          <ImportForm
            path={api.import}
            sectionName={sectionName}
            show={isUploadVisible}
            hide={() => setIsUploadVisible(false)}
            existingData={[]}
            refresh={() => setRefresh((prev) => !prev)}
          />
        )}
      </div>
    </>
  );
}

export default Index;
