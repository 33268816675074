import { Col, Form, Image, Input, Modal, Radio, Rate, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";

import notfound from "../../assets/images/not_found.png";
import SingleImageUpload from "../../components/SingleImageUpload";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { AuthContext } from "../../context/AuthContext";

const AddForm = ({ section, api, show, hide, data, refresh }) => {
  const { userProfile } = useContext(AuthContext);
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const handleImage = (data) => {
    setImage(data);
    data.length > 0 ? setFile(data[0].url) : setFile([]);
  };

  useEffect(() => {
    if (!data) return;
    console.log(data);
    form.setFieldsValue({ ...data });
    setFile([data.image]);
  }, [data]);

  const onCreate = (values) => {
    const { name, ar_name, status } = values;
    console.log(values, "values");
    const payload = {};
    setLoading(true);
    payload.name = name;
    payload.ar_name = ar_name;
    payload.is_active = status;
    payload.image = image && image.length > 0 ? image[0].url : "";

    console.log(payload, "hfdjhjkhgjkfhgjkfhg");
    request({
      url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      visible={show}
      width={950}
      // title={`${data ? "Update " + section : "Create a New " + section}`}
      okText="Add"
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <h4 className="modal_title_cls">Order Details</h4>
        <div className="order-head">
          <h4>{userProfile?.name ? userProfile.name : "Your Restaurant"}</h4>
          <span>
            <Rate disabled defaultValue={0} />
            <span className="no-rating">0</span>(0 Reviews)
          </span>
          <p>Order ID : 15548</p>
        </div>
        <Row gutter={[16, 0]}>
          <Col span={24} sm={12}>
            <div className="order-dtl-card">
              <div className="order-header">
                <h3>Order Details</h3>
                <p>06 july, 2023, 03:23 PM</p>
              </div>
              <div className="order-dtl-list">
                <div className="single-order-dtl">
                  <div className="order-dtl-left">
                    <h6>1x</h6>
                  </div>
                  <div className="order-middle">
                    <h4>1 x American Dream Pizza</h4>
                    <p>Mixed vegetables</p>
                  </div>
                  <div className="order-right">
                    <h4>$ 17.20</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="order-dtl-card">
              <div className="order-header">
                <h3>Customer Details</h3>
              </div>
              <div className="customer-dtl">
                <div className="customer-info">
                  <h6>Name :</h6>
                  <h5>Skylar Schleifer</h5>
                </div>
                <div className="customer-info">
                  <h6>Phone Number :</h6>
                  <h5>+966-585-551-354</h5>
                </div>
                <div className="customer-info">
                  <h6>Address :</h6>
                  <h5>Box No. 2399, Abu Dhabi, Emirates</h5>
                </div>
              </div>
            </div>
            <div className="order-dtl-card">
              <div className="order-header">
                <h3>Bill Summary</h3>
              </div>
              <div className="customer-dtl">
                <div className="bill-info">
                  <h6>Basket Total</h6>
                  <h5>AED 30.00</h5>
                </div>
                <div className="bill-info">
                  <h6>Delivery Fee:</h6>
                  <h5>AED 5.00</h5>
                </div>
                <div className="bill-info">
                  <h6>10% Discount</h6>
                  <h5>AED -3.00</h5>
                </div>
                <div className="bill-info">
                  <h6>Tip</h6>
                  <h5>AED 5.00</h5>
                </div>
              </div>
              <div className="total-price">
                <div className="bill-info">
                  <h6>TOTAL</h6>
                  <h5>AED 40.00</h5>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;
