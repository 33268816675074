import { Badge, Button, Card, Col, Input, Row, Select, Table, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { UndoOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import apiPath from "../../constants/apiPath";
import { OrderStatus } from "../../constants/order-status.constants";
import { Last20Years, Months } from "../../constants/var";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";
import Currency from "../../components/Currency";
import { dateString } from "../../helper/functions";
import ViewOrderModal from "../../components/ViewOrderModal";
import { useAppContext } from "../../context/AppContext";
import ExcelDownload from "../Finance/_Excel";

function Index() {
  const api = {
    list: apiPath.order + "/history",
  };
  const [visible, setVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { language } = useAppContext()
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const debouncedSearchText = useDebounce(searchText, 300);
  const [exportLoading, setExportLoading] = useState(false);
  const [selected, setSelected] = useState();
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [hidePdfBtn, setHidePdfBtn] = useState(false);
  const [selectedTab, setSelectedTab] = useState("all");
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const columns = [
    {
      title: lang("Order ID"),
      dataIndex: "index",
      key: "index",
      render: (_, { uid }) => (uid ? <span className="cap">#{uid}</span> : "-"),
    },
    {
      title: lang("Restaurant Order id"),
      dataIndex: "rest_order_id",
      key: "rest_order_id",
      render: (_, { created_at, rest_order_id }) =>
        rest_order_id ? (
          <span className="cap">
            #{`${moment(created_at).format("YY-MM")}-${rest_order_id}`}
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("customer"),
      dataIndex: "name",
      key: "name",
      render: (_, { customer_id,company_id }) =>
        customer_id ? (
          <div style={{ display: "flex", gap: 2, flexDirection: "column" }}>
            {customer_id?.name && (
              <span className="cap">{customer_id.name}</span>
            )}
            {customer_id?.country_code && customer_id?.mobile_number && (
              <span className="cap" style={{ fontSize: "14px", color: "gray" }}>
                ({customer_id.country_code}) {customer_id.mobile_number}
              </span>
            )}
            {customer_id?.email && (
              <span style={{ fontSize: "14px", color: "gray" }}>
                {customer_id.email}
              </span>
            )}
            {company_id?.name && (
              <span style={{ fontSize: "14px", color: "gray" }}>
                {company_id.name}
              </span>
            )}
          </div>
        ) : "-",
    },
    {
      title: lang("items"),
      key: "items",
      render: (_, { items }) =>
        items ? (
          <>
            {items.map((item, index) => {
              return (
                <span className="cap" key={index}>
                  {language !== "en" && language !== null
                    ? item?.food_id?.[`${language}_name`] ?? item?.food_id?.name
                    : item?.food_id?.name}
                  {" X " + item?.qty}
                </span>
              );
            })}
          </>
        ) : (
          "-"
        ),
    },
    {
      title: lang("QYT"),
      dataIndex: lang("quantity"),
      key: "quantity",
      render: (_, { items }) =>
        items ? <span className="cap">{items.length}</span> : "-",
    },
    {
      title: lang("Payment Mod"),
      dataIndex: "payment_mod",
      key: "payment_mod",
      render: (_, { payment_mod }) =>
        payment_mod ? <span className="cap">
          {payment_mod == "cod" ? lang(payment_mod) : lang('Online Payment')}
        </span> : "-",
    },
    {
      title: lang("date"),
      key: "created_at",
      render: (_, { created_at }) =>
        created_at ? (
          <span className="cap">{dateString(created_at, "lll")}</span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("amount"),
      key: "total_payable",
      render: (_, { total_payable }) =>
        total_payable ? (
          <span className="cap">
            <Currency price={total_payable} />
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Platform Commission"), //TODO: pending
      key: "admin_commission",
      render: (_, { platform_commission }) =>
        platform_commission ? (
          <span className="cap">
            <Currency price={platform_commission.restaurant} />
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("deliveryAgent"),
      key: "driver_id",
      render: (_, { driver_id }) =>
        driver_id ? (
          <span className="cap">
            {language !== "en" && language !== null
              ? driver_id?.[`${language}_name`] ?? driver_id?.name
              : driver_id?.name}

          </span>
        ) : (
          lang("Not Assigned")
        ),
    },
    {
      title: lang("View"),
      key: "info",
      render: (_, record) => {
        return (
          <Tag
            title="View"
            className="btnOutlineDelete"
            onClick={() => {
              setSelected(record);
              setIsViewOpen(true);
              setHidePdfBtn(true);
            }}
            style={{
              cursor: "pointer",
              padding: "5px 10px",
              borderRadius: "50px",
            }}
          >
            {lang("View")}
          </Tag>
        );
      },
    },
    {
      title: lang("Status"),
      key: "action",
      render: (_, { status, cancelled_by }) => {
        let statusColor = status === OrderStatus.CANCEL ? "red" : "green";
        let text =
          status === OrderStatus.CANCEL && cancelled_by == "Vendor"
            ? lang("Rejected")
            : lang(status);
        return (
          <Badge
            className="cap"
            status={statusColor ? statusColor : "default"}
            text={text}
          />
        );
      },
    },
  ];

  const getFilter = () => {
    request({
      url: `${api.list}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    // fetchData({ current: 1 }, selectedTab);
    fetchData({ ...pagination, current: 1 });
    getFilter();
  }, [refresh, debouncedSearchText, filter]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url:
        api.list +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${pagination ? pagination.current : 1
        }&pageSize=${pagination && pagination.pageSize ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          setList(data);

          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);

        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const checkFilterValue = () => {
    console.log("filtervalue", filter, filter.year);
    if (filter.year && filter.month) {
      getExportData({
        year:filter.year,
        month: filter.month
      });
    } else {
      ShowToast("Please select a year and months", Severty.ERROR);
    }
  };

  const handleExport = (exportData) => {
    if (!exportData.length) return;

    const data = exportData.map((row) => ({
      OrderId: row.uid,
      CustomerName: row.customer_id.name,
      OrderItems: row.items
        .map((item, index) => item?.food?.name + " X " + item?.qty)
        .join(","),
      Restaurant: row.restaurant_id.name,
      PaymentMode: row.payment_mod,
      Phone: `${row.customer_id?.country_code}-${row.customer_id?.mobile_number}`,
      Amount: row.total_payable + "(ILS)",
      Status: row.status,
      //TransactionOn: moment(row.created_at).format("DD-MM-YYYY h:m A"),
    }));
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(workbook, worksheet, "OrderHistory");
    XLSX.writeFile(workbook, `${moment().format("DD-MM-YYYY h:m:s")}.xlsx`);
  };  

  const download =(exportData)=>{
    const data= {
      order:{
        data:exportData.filter(item=>!item.coupon && item.type !== "Driver Request").map((row) => ({
          Category: row.type + (row.is_vendor_pay_delivery ? ("-VENDOR FREE DELIVERY") : (row.coupon ? "-VENDOR DISCOUNT" : (row.delivery_coupon ? "-ADMIN DISCOUNT" : (row.type == "Driver Request" ? "" : "-NO DISCOUNT")))),
          "Order ID": row.uid || "-",
          "Order Date": row.created_at ? moment(row.created_at).format("ll") : "-",
          "Order Time": row.created_at ? moment(row.created_at).format("HH:mm a") : "-",
          "Order Type": row.type || "-",
          "Order Status": row.status || "-",
          "Payment Mode": row.payment_mod || "-",
          "Order Total": row.total_payable || 0,
          "Coupon Code": row.coupon?.code || "NO DISCOUNT",
          "Discount Amount": row.discount || 0,
          "Delivery Coupon Code": row.delivery_coupon?.code || "NO DISCOUNT",
          "Delivery Discount Amount": row.delivery_discount || 0,
          "Platform Commission - Restaurant (Percentage)": row.platform_commission_rates?.restaurant || 0,
          "Platform Commission - Restaurant (Amount)": row.platform_commission?.restaurant || 0,
          "Tax (Percentage)": row.platform_commission_rates?.tax || 0,
          "Tax (Amount)": row.tax > 0 ? row.tax : 0,
          "Total for Restaurant": ((row?.total_amount) - (row?.discount) - (row?.platform_commission?.restaurant ?? 0) + (row?.tax ?? 0) - (row?.is_vendor_pay_delivery ? row.delivery_discount : 0)) || 0,
        })),
        filename:'Orders Without Discount'
      },
      discount:{
        data:exportData.filter(item=>item.coupon && item.type !== "Driver Request").map((row) => ({
          Category: row.type + (row.is_vendor_pay_delivery ? ("-VENDOR FREE DELIVERY") : (row.coupon ? "-VENDOR DISCOUNT" : (row.delivery_coupon ? "-ADMIN DISCOUNT" : (row.type == "Driver Request" ? "" : "-NO DISCOUNT")))),
          "Order ID": row.uid || "-",
          "Order Date": row.created_at ? moment(row.created_at).format("ll") : "-",
          "Order Time": row.created_at ? moment(row.created_at).format("HH:mm a") : "-",
          "Order Type": row.type || "-",
          "Order Status": row.status || "-",
          "Payment Mode": row.payment_mod || "-",
          "Order Total": row.total_payable || 0,
          "Coupon Code": row.coupon?.code || "NO DISCOUNT",
          "Discount Amount": row.discount || 0,
          "Delivery Coupon Code": row.delivery_coupon?.code || "NO DISCOUNT",
          "Delivery Discount Amount": row.delivery_discount || 0,
          "Platform Commission - Restaurant (Percentage)": row.platform_commission_rates?.restaurant || 0,
          "Platform Commission - Restaurant (Amount)":  row.platform_commission?.restaurant || 0,
          "Tax (Percentage)": row.platform_commission_rates?.tax || 0,
          "Tax (Amount)": row.tax > 0 ? row.tax : 0,
        "Total for Restaurant": ((row?.total_amount) - (row?.discount) - (row?.platform_commission?.restaurant ?? 0) + (row?.tax ?? 0) - (row?.is_vendor_pay_delivery ? row.delivery_discount : 0)) || 0,
        })),
        filename:'Orders With Discount'
      },
      request:{
        data:exportData.filter(item=>item.type === "Driver Request").map((row) => ({
          Category: row.type + (row.is_vendor_pay_delivery ? ("-VENDOR FREE DELIVERY") : (row.coupon ? "-VENDOR DISCOUNT" : (row.delivery_coupon ? "-ADMIN DISCOUNT" : (row.type == "Driver Request" ? "" : "-NO DISCOUNT")))),
          "Order ID": row.uid || "-",
          "Order Date": row.created_at ? moment(row.created_at).format("ll") : "-",
          "Order Time": row.created_at ? moment(row.created_at).format("HH:mm a") : "-",
          "Order Type": row.type || "-",
          "Order Status": row.status || "-",
          "Payment Mode": row.payment_mod || "-",
          "Order Total": row.total_payable || 0,
          "Coupon Code": row.coupon?.code || "NO DISCOUNT",
          "Discount Amount": row.discount || 0,
          "Delivery Coupon Code": row.delivery_coupon?.code || "NO DISCOUNT",
          "Delivery Discount Amount": row.delivery_discount || 0,
          "Platform Commission - Restaurant (Percentage)": row.platform_commission_rates?.restaurant || 0,
          "Platform Commission - Restaurant (Amount)":  row.platform_commission?.restaurant || 0,
          "Tax (Percentage)": row.platform_commission_rates?.tax || 0,
          "Tax (Amount)": row.tax > 0 ? row.tax : 0,
        "Total for Restaurant": ((row?.total_amount) - (row?.discount) - (row?.platform_commission?.restaurant ?? 0) + (row?.tax ?? 0) - (row?.is_vendor_pay_delivery ? row.delivery_discount : 0)) || 0,
        })),
        filename:'Driver Request Orders'
      }
    }
    ExcelDownload({data})
  }

  const getExportData = async () => {
    try {
      setExportLoading(true); 

      const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");
      request({
        url:
          api.list +
          `?page=1&pageSize=${pagination ? pagination.total : 100000}${queryString ? `&${queryString}` : ""}`,
        method: "GET",
        onSuccess: ({ data, status, total, message }) => {
          setExportLoading(false);
          if (status) {
            download(data ?? []);
          }
        },
        onError: (error) => {
          console.log(error);
          setExportLoading(false);
          ShowToast(error, Severty.ERROR);
        },
      });
    } catch (err) {
      console.log(err);
      setExportLoading(false);
    }
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  // const handleChange = (pagination, sorter, filters) => {
  //   if (!sorter) {
  //     fetchData(pagination);
  //   }
  // };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    setPagination({ current: 1 });
  };

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <div className="tab_inner_tit">
                <div className="tab-upload-wrap d-flex align-items-center justify-content-between flex-wrap">
                  <h3>{lang("orderHistory")}</h3>
                  <div className="d-flex align-items-center gap-3 flex-wrap">
                    <div className="pageHeadingSearch">
                      <Input.Search
                        className="searchInput"
                        placeholder={lang("search")}
                        onChange={onSearch}
                        allowClear
                      />
                    </div>
                    <div className="role-wrap">
                      <Select
                        width="110px"
                        placeholder={lang("year")}
                        showSearch
                        value={filter.year}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        options={years.map((item) => ({
                          value: item,
                          label: item,
                        }))}
                        onChange={(value) => onChange("year", value)}
                      />
                    </div>
                    <div className="role-wrap">
                      <Select
                        width="110px"
                        placeholder={lang("month")}
                        showSearch
                        value={filter.month}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        options={months.map((item) => ({
                          ...item,
                        }))}
                        onChange={(value) => onChange("month", value)}
                      />
                    </div>
                    <Button
                      onClick={() =>
                        setFilter({
                          country_id: undefined,
                          city_id: undefined,
                          year: undefined,
                          month: undefined,
                        })
                      }
                      type="primary"
                      className="resetBtn"
                      icon={<UndoOutlined />}
                    >
                      {lang("reset")}
                    </Button>
                    <div className="btn_grp">
                      <Button
                        loading={exportLoading}
                        onClick={() => list.length && checkFilterValue()}
                        className="primary_btn btnStyle"
                      >
                        {lang("Export To Excel")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive customPagination withOutSearilNo">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{
                    defaultPageSize: 10,
                    responsive: true,
                    total: pagination.total,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30", "50"],
                  }}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      {isViewOpen && selected && (
        <ViewOrderModal
          show={isViewOpen}
          hide={() => {
            setIsViewOpen(false);
            setRefresh(true);
            setHidePdfBtn(false);
          }}
          data={selected}
          hidePdfBtn={hidePdfBtn}
        />
      )}
    </>
  );
}

export default Index;
