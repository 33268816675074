import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import signinLogo from "../../assets/images/Logo.png";
import signinbg from "../../assets/images/log_in.png";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import { AuthContext } from "../../context/AuthContext";
import encryptDecrypt from "../../helper/encryptDecrypt";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import useFirebase from "../../hooks/useFirebase";
import lang from "../../helper/langHelper";
import { Timezone } from "../../helper/timeZone";

const { Title } = Typography;
const { Content } = Layout;

const SignIn = () => {
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const { restaurant, setRestaurant } = useContext(AppStateContext);

  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const { request } = useRequest();
  const [visible, setVisible] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [selected, setSelected] = useState();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [rememberMe, setRememberMe] = useState(false);
  const { fcmToken } = useFirebase();

  const handleRememberMeChange = (checked) => {
    setRememberMe(checked);
  };

  const onFinish = (values) => {
    onSubmit(values);
  };

  const onSubmit = (values) => {
    const { email, password } = values;

    if (!email)
      return ShowToast("Please enter email to sign in", Severty.ERROR);

    const payload = {
      password,
      device_token: fcmToken,
      device_type: "web",
      timezone:Timezone
    };

    if (!email) return ShowToast("Please enter valid email ", Severty.ERROR);

    console.log(payload, "fkjdhkd");

    setLoading(true);
    payload.email = email;
    payload.type = "Admin";
    request({
      url: apiPath.login,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        console.log(data, "fghdjh data");
        if (data.status) {
          setIsLoggedIn(true);
          if (rememberMe?.target?.checked === true || rememberMe === true) {
            // Store the login state in local storage
            console.log("rememberMe", rememberMe);
            var emailEncrypt = encryptDecrypt.encryptEmail(values.email);
            var passwordEncrypt = encryptDecrypt.encryptPassword(
              values.password,
            );
            localStorage.setItem("rememberMe", true);
            localStorage.setItem("ykmCe2AYEFTHobn", emailEncrypt);
            localStorage.setItem("ouUsippetc8S4Ry", passwordEncrypt);
          } else {
            localStorage.removeItem("rememberMe");
            localStorage.removeItem("ykmCe2AYEFTHobn");
            localStorage.removeItem("ouUsippetc8S4Ry");
          }
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("userProfile", JSON.stringify(data.data.user));

          ShowToast(data.message, Severty.SUCCESS);
          setUserProfile(data.data.user);
          setRestaurant({
            name: data.data.user.name,
            ratings: 2,
            review: 1,
          });
          setTimeout(() => navigate("/dashboard"), 200);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleForgotPassword = () => {
    setVisible(true);
  };

  const handleResetPassword = (email) => {
    // Send the OTP to the user's email
    setResetLoading(true);
    let payload = {};
    payload.email = email.email;
    request({
      url: apiPath.forgotPassword,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status === false) {
          setResetLoading(false);
          ShowToast(data.message, Severty.ERROR);
        } else {
          var emailResetEncrypt = encryptDecrypt.encryptEmail(email.email);
          localStorage.setItem("UGwdfdR2uHMM24N", emailResetEncrypt);
          setResetLoading(false);
          ShowToast(data.message, Severty.SUCCESS);
          setVisible(false);
          setOtpModal(true);
          setSelected({ email });
        }
      },
      onError: (error) => {
        setResetLoading(false);
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const handleVerifyOTP = (values) => {
    const { email, otp } = values;
    setVerifyLoading(true);
    // Verify the OTP entered by the user
    let payload = {};
    const savedEmail = localStorage.getItem("UGwdfdR2uHMM24N");
    var originalEmail = encryptDecrypt.decryptEmail(savedEmail);
    payload.email = originalEmail;
    payload.otp = otp;
    payload.use_for = "ForgetPassword";
    payload.type = "Vendor";
    request({
      url: apiPath.verifyOTP,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status === false) {
          setVerifyLoading(false);
          ShowToast(data.message, Severty.ERROR);
        } else {
          setVerifyLoading(false);
          ShowToast(data.message, Severty.SUCCESS);
          setOtpModal(false);
          setSelected(null);
          setVisible(false);
          setResetModal(true);
        }
      },
      onError: (error) => {
        setVerifyLoading(false);
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const handleReset = (values) => {
    const { email, newPassword } = values;
    setPasswordLoading(true);
    // Reset the password with the new password entered by the user
    let payload = {};

    const savedEmail = localStorage.getItem("UGwdfdR2uHMM24N");
    var originalEmail = encryptDecrypt.decryptEmail(savedEmail);
    payload.email = originalEmail;

    payload.password = newPassword;
    request({
      url: apiPath.resetPassword,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status === false) {
          setPasswordLoading(false);
          ShowToast(data.message, Severty.ERROR);
        } else {
          setPasswordLoading(false);
          ShowToast(data.message, Severty.SUCCESS);
          setResetModal(false);
        }
      },
      onError: (error) => {
        setPasswordLoading(false);
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const handleCancelReset = () => {
    setResetModal(false);
    // form.resetFields()
  };

  // ouUsippetc8S4Ry = Email,
  // ouUsippetc8S4Ry = password,
  // UGwdfdR2uHMM24N = resetEmail

  useEffect(() => {
    const savedEmail = localStorage.getItem("ykmCe2AYEFTHobn");
    const savedPassword = localStorage.getItem("ouUsippetc8S4Ry");

    if (savedEmail && savedPassword) {
      var originalEmail = encryptDecrypt.decryptEmail(savedEmail);
      var originalPassword = encryptDecrypt.decryptEmail(savedPassword);
      form.setFieldsValue({
        email: originalEmail,
        password: originalPassword,
        remember: true,
      });
      setRememberMe(true);
    } else {
      setRememberMe(false);
    }
  }, []);

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row>
            <Col span={24} md={12} className="signin-bg ">
              <div className="signin_img">
                <img src={signinbg} />
              </div>
            </Col>
            <Col span={24} md={12}>
              <div className="signin-box">
                <div className="signup-logo">
                  <img src={signinLogo} alt="" />
                </div>
                <Row justify="space-around">
                  <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
                    <div className="signup-form">
                      <Title className="mb-15">
                        {lang("Login to your Account Vendor")}
                      </Title>
                      <Title className="font-regular text-muted" level={5}>
                        {lang("See what is going on with your business")}
                      </Title>
                      <Form
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                        className="row-col"
                      >
                        <Form.Item
                          className="username"
                          label={lang("Email Address")}
                          name="email"
                          rules={[
                            {
                              type: "email",
                              message: lang(
                                "Please enter a valid email address!",
                              ),
                            },
                            {
                              max: 255,
                              message: lang(
                                "Email address not more then 255 characters!",
                              ),
                            },
                            {
                              required: true,
                              message: lang("Please enter your email!"),
                            },
                          ]}
                        >
                          <Input placeholder={lang("Enter Email Address")} />
                        </Form.Item>
                        <Form.Item
                          className="password"
                          label={lang("Password")}
                          name="password"
                          rules={[
                            {
                              max: 255,
                              message: lang(
                                "Password should contain more then 255 characters!",
                              ),
                            },
                            {
                              required: true,
                              message: lang("Please enter your password!"),
                            },
                          ]}
                        >
                          <Input.Password
                            onCut={(e) => e.preventDefault()}
                            onCopy={(e) => e.preventDefault()}
                            onPaste={(e) => e.preventDefault()}
                            autoComplete="off"
                            placeholder={lang("Enter Password")}
                          />
                        </Form.Item>
                        <div className="forgot-pass">
                          <Form.Item
                            name="remember"
                            className="aligin-center"
                            valuePropName="checked"
                          >
                            <Checkbox
                              checked={rememberMe}
                              onChange={handleRememberMeChange}
                            >
                              {lang("Remember me")}
                            </Checkbox>
                          </Form.Item>
                          <Form.Item
                            name="remember"
                            className="aligin-center"
                            valuePropName="checked"
                          >
                            <a onClick={handleForgotPassword}>
                              <a>{lang("Forgot Password?")}</a>
                            </a>
                          </Form.Item>
                        </div>
                        <Form.Item>
                          <Button
                            className="float-right"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                          >
                            {lang("SIGN IN")}
                          </Button>
                        </Form.Item>
                      </Form>

                      <div style={{ textAlign: "center", margin: "32px 0" }}>
                        {lang("Not Registered Yet?")}{" "}
                        <span className="ac-create">
                          <Link to={"/sign-up"}>
                            {lang("CREATE AN ACCOUNT")}
                          </Link>
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Content>

        {visible ? (
          <Modal
            className="tab_modal deleteWarningModal"
            visible={visible}
            title={lang("Forgot Password")}
            okText={lang("Send OTP")}
            onCancel={() => {
              setVisible(false);
              // form.resetFields()
            }}
            okButtonProps={{
              form: "forget-pasword",
              htmlType: "submit",
              loading: resetLoading,
            }}
          >
            <Form
              id="forget-pasword"
              onFinish={handleResetPassword}
              layout="vertical"
            >
              <Form.Item
                label={lang("Email Address")}
                name="email"
                rules={[
                  {
                    type: "email",
                    message: lang("Please enter a valid email address!"),
                  },
                  {
                    max: 255,
                    message: lang(
                      "Email address not more then 255 characters!",
                    ),
                  },
                  {
                    required: true,
                    message: lang("Please enter your email!"),
                  },
                ]}
              >
                <Input
                  autoComplete="off"
                  placeholder={lang("Enter Email Address")}
                />
              </Form.Item>
            </Form>
          </Modal>
        ) : null}

        {otpModal ? (
          <Modal
            className="tab_modal deleteWarningModal"
            open={otpModal}
            title={lang("Verify OTP")}
            okText={lang("Verify")}
            onCancel={(e) => {
              setOtpModal(false);
              // form.resetFields()
            }}
            okButtonProps={{
              form: "verify-otp",
              htmlType: "submit",
              loading: verifyLoading,
            }}
          >
            <Form
              id="verify-otp"
              onFinish={(e) => handleVerifyOTP(e)}
              layout="vertical"
            >
              <Form.Item
                label={lang("OTP")}
                name="otp"
                rules={[
                  {
                    required: true,
                    message: lang("Please enter the OTP"),
                  },
                  {
                    len: 4,
                    message: lang("OTP must be 4 digits"),
                  },
                ]}
              >
                <Input
                  autoComplete="off"
                  type="number"
                  maxLength={4}
                  placeholder={lang("Enter OTP")}
                />
              </Form.Item>
            </Form>
          </Modal>
        ) : null}

        {resetModal ? (
          <Modal
            className="tab_modal deleteWarningModal"
            open={resetModal}
            title={lang("Reset Password")}
            okText={lang("Update Password")}
            onCancel={handleCancelReset}
            okButtonProps={{
              form: "reset-password",
              htmlType: "submit",
              loading: passwordLoading,
            }}
          >
            <Form
              id="reset-password"
              onFinish={(e) => handleReset(e)}
              layout="vertical"
            >
              <Form.Item
                label={lang("New Password")}
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: lang("Please enter your new password!"),
                  },
                  {
                    pattern: new RegExp(
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
                    ),
                    message: lang(
                      "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
                    ),
                  },
                ]}
              >
                <Input.Password placeholder={lang("Enter New Password")} />
              </Form.Item>
              <Form.Item
                label={lang("Confirm New Password")}
                name="confirm_new_password"
                dependencies={["newPassword"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: lang("Please enter the confirm password!"),
                  },
                  {
                    pattern: new RegExp(
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
                    ),
                    message: lang(
                      "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
                    ),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          lang("Password that you entered doesn't match!"),
                        ),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder={lang("Enter Confirm Password")} />
              </Form.Item>
            </Form>
          </Modal>
        ) : null}
      </Layout>
    </>
  );
};

export default SignIn;
