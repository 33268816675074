import { Button, Col, Modal, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { useReactToPrint } from "react-to-print";
import { convertTime } from "../helper/getTime";
import lang from "../helper/langHelper";
import Currency, { CurrencySymbol } from "./Currency";
import PdfLogo from "../assets/images/t-logo.png";
import moment from "moment";
import { dateString, formatPhone } from "../helper/functions";
import { useAuthContext } from "../context/AuthContext";
import { useAppContext } from "../context/AppContext";
import { Link } from 'react-router-dom'

const ViewOrderModal = ({ show, hide, data, hidePdfBtn }) => {
  const [order, setOrder] = useState();
  const [pdflang, setPdflang] = useState(null);
  const { userProfile } = useAuthContext();
  const { language } = useAppContext();
  const contentToPrint = useRef(null);
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  let curLang = language || "en";
  let arabic_restaurant_name = curLang === "en" ? order?.restaurant_id?.name : order?.restaurant_id?.ar_name;

  const DriverOrderStatus = {
    CANCEL: "cancelled",
    PENDING: "pending",
    ACCEPT: "confirmed",
    ARRIVED: "arrived at restaurant",
    PICKUP: "picked up",
    DROP: "arrived at drop location",
    OUT: "out for delivery",
    DELIVERED: "delivered",
  };


  const driverStatus = (order) => {
    let status = "Driver not assigned";
    if (order?.driver_status === DriverOrderStatus.ACCEPT || order?.driver_status == "accepted") {
      status = "Driver assigned";
    } else if (order?.driver_status === DriverOrderStatus.ARRIVED) {
      status = "Driver at restaurant";
    } else if (order?.driver_status === DriverOrderStatus.DROP) {
      status = "Arrived at customer";
    } else if (order?.driver_status === DriverOrderStatus.PICKUP) {
      status = "Order Picked Up";
    } else if (order?.driver_status === DriverOrderStatus.OUT) {
      status = "Out for delivery";
    } else if (order?.driver_status === DriverOrderStatus.DELIVERED) {
      status = "Delivered";
    } else if (order?.driver_status === DriverOrderStatus.CANCEL) {
      if (order?.cancelled_by == "Vendor") status = "Rejected by Restaurant";
      else status = "Cancelled";
    } else {
      // status = order?.driver_status;
      if (order?.status === "processing") status = "Looking for driver";
      if (order?.status === "cancelled") {
        status = "Cancelled";
      }
      if (order?.status === "cancelled" && order?.cancelled_by === "Vendor") {
        status = "Rejected by Restaurant";
      }
    }
    setDeliveryStatus(lang(status));
  };

  useEffect(() => {
    if (!data) return;
    console.log(data);
    setOrder(data);
    driverStatus(data);
    const getlang = localStorage.getItem("languageSet");
    setPdflang(getlang);
    console.log(getlang, "getlang");
  }, [data]);

  const approxDeliveryTime = moment(
    order?.restaurant_id?.approx_delivery_time,
    "HH:mm",
  );
  // const estimatedDeliveryTime = moment(order?.created_at)
  //   .add(approxDeliveryTime.hour(), "hours")
  //   .add(approxDeliveryTime.minute(), "minutes");

  const estimatedDeliveryTime = moment(order?.created_at).add(
    (order?.time ?? 0) - 600,
    "seconds",
  );

  return (
    <>
      {console.log("order1", order)}

      <Modal
        open={show}
        width={950}
        okText="Add"
        onCancel={hide}
        cancelText={null}
        footer={[
          <Button key="okay" type="primary" onClick={hide}>
            {lang("Okay")}
          </Button>,
        ]}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="tab_modal edit_orders"
      >
        <div>
          <div className="modal_title_cls">
            <h4 className="modal_title_cls">{lang("Order Details")}</h4>
          </div>
          <Modal
            open={show}
            width={950}
            okText="Add"
            onCancel={hide}
            cancelText={null}
            footer={[
              <Button key="okay" type="primary" onClick={hide}>
                {lang("Okay")}
              </Button>,
            ]}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="tab_modal edit_orders"
          >
            <div>
              <div className="modal_title_cls">
                <h4 className="modal_title_cls">{lang("Order Details")}</h4>
              </div>
              {!hidePdfBtn && (
                <Button
                  className="button-print-now primary_btn btnStyle"
                  onClick={() => {
                    handlePrint(null, () => contentToPrint.current);
                  }}
                >
                  <i class="fas fa-print"></i>
                </Button>
              )}
            </div>

            <div>
              <div className="order-head">
                <p style={{ fontWeight: "bold" }}>
                  {lang("Order ID")} : {order?.uid}
                </p>
              </div>
              <Row gutter={[45, 0]}>
                <Col span={24} sm={24} lg={24}>
                  <div className="order-dtl-card">
                    <div className="order-header">
                      <h3>{lang("")}</h3>
                      <p className="order-date">

                      </p>
                      <p
                        className="arbic-reverse"
                        style={{ fontSize: "14px", fontWeight: 600 }}
                      >
                        {language !== "en" && language !== null
                          ? " : حالة الطلب " ?? "Order Status :"
                          : "Order Status :"}{" "}
                        <span>{lang(order?.status)}</span>
                      </p>
                    </div>
                    <div className="order-header">
                      <h3>{lang("Order Details")}</h3>
                      <p className="order-date">
                        {order?.created_at
                          ? dateString(order.created_at, "lll")
                          : ""}
                      </p>
                      <p
                        className="arbic-reverse"
                        style={{ fontSize: "14px", fontWeight: 600 }}
                      >
                        {language !== "en" && language !== null
                          ? " : وقت التحضير " ?? "Preparation ETA :"
                          : "Preparation ETA :"}{" "}
                        <span>{estimatedDeliveryTime?.format("hh:mm a")}</span>
                      </p>
                    </div>
                    <div className="order-dtl-list">
                      {order?.items?.map((item, index) => (
                        <div className="single-order-dtl mb-2" key={index}>
                          <div className="order-dtl-left">
                            <h6>{item.qty} x</h6>
                          </div>
                          <div className="order-middle w-100">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <h4>
                                {language !== "en" && language !== null
                                  ? item?.food_id?.[`${language}_name`] ??
                                  item?.food_id?.name
                                  : item?.food_id?.name}
                              </h4>
                              {item?.food_id?.name ? (
                                <span
                                  className="order-price-cc"
                                  style={{ color: "black" }}
                                >
                                  <span>{item?.qty}</span> X{" "}
                                  <Currency price={item?.price} /> ={" "}
                                  <Currency price={item?.item_price} />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>

                            <p>
                              {language !== "en" && language !== null
                                ? item?.size_id?.[`${language}_name`] ??
                                item?.size_id?.name
                                : item?.size_id?.name}
                            </p>

                            {item?.choice && item.choice.length ? (
                              <>
                                {item.choice.map((ch) => (
                                  <div key={ch.choice_id._id}>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        margin: "5px 0px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      <h6
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: 600,
                                          margin: "10px 0px 0px",
                                        }}
                                      >
                                        ||
                                        {/* {ch.choice_id.name} */}
                                        {ch.choice_id.name &&
                                          ` ${language !== "en" &&
                                            language !== null
                                            ? ch.choice_id?.[
                                            `${language}_name`
                                            ] ?? ch.choice_id?.name
                                            : ch.choice_id?.name
                                          } `}
                                        ||
                                      </h6>
                                    </p>
                                    <ul>
                                      {/* {ch.options.map((op, index) => (
                                        <li
                                          className="order-option"
                                          key={index}
                                        >
                                          <div className="product-item-inn">
                                            {op}
                                          </div>
                                        </li>
                                      ))} */}
                                      {ch.options.map((op, index) => {
                                        if (typeof op == 'string') {
                                          return (
                                            <li className="order-option" key={index}>
                                              <div className="product-item-inn">{op}</div>
                                            </li>
                                          )
                                        } else {
                                          return (
                                            <li className="order-option" key={index}>
                                              <div className="product-item-inn">
                                                {
                                                  language !== "en" && language !== null
                                                    ? op?.[`${language}_name`] ??
                                                    op?.name
                                                    : op?.name
                                                }
                                              </div>
                                            </li>)
                                        }
                                      })}
                                    </ul>
                                  </div>
                                ))}
                              </>
                            ) : null}

                            {item?.add_on_with_qty &&
                              item?.add_on_with_qty?.length > 0 && (
                                <>
                                  {Object.entries(
                                    item?.add_on_with_qty.reduce(
                                      (accumulator, addOn) => {
                                        const category =
                                          language !== "en" && language !== null
                                            ? addOn?.add_cat_id?.[
                                            `${language}_name`
                                            ] ?? addOn?.add_cat_id?.name
                                            : addOn?.add_cat_id?.name;

                                        if (!accumulator[category]) {
                                          accumulator[category] = [];
                                        }
                                        accumulator[category].push(addOn);
                                        return accumulator;
                                      },
                                      {},
                                    ),
                                  ).map(([category, addOns], categoryIndex) => (
                                    <div key={categoryIndex}>
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          margin: "5px 0px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        <h6
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: 600,
                                            margin: "10px 0px 0px",
                                          }}
                                        >
                                          || {category} ||
                                        </h6>
                                      </p>

                                      {addOns.map((addOn, index) => (
                                        <div key={index}>
                                          {addOn?.ingredient_ids.map(
                                            (ingredient, ingredientIndex) => (
                                              <p
                                                key={ingredientIndex}
                                                style={{
                                                  fontSize: "14px",
                                                  margin: "0px",
                                                  fontWeight: 500,
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <p>
                                                  {ingredient?.qty ? (
                                                    <strong>
                                                      {ingredient?.qty}X
                                                    </strong>
                                                  ) : (
                                                    ""
                                                  )}{" "}
                                                  {ingredient?.id?.name &&
                                                    ` ${language !== "en" &&
                                                      language !== null
                                                      ? ingredient?.id?.[
                                                      `${language}_name`
                                                      ] ??
                                                      ingredient?.id?.name
                                                      : ingredient?.id?.name
                                                    } `}
                                                  {ingredient?.size?.name !==
                                                    "Regular"
                                                    ? `(${language !== "en" &&
                                                      language !== null
                                                      ? ingredient?.size?.[
                                                      `${language}_name`
                                                      ] ??
                                                      ingredient?.size
                                                        ?.name
                                                      : ingredient?.size
                                                        ?.name
                                                    })`
                                                    : ""}
                                                </p>

                                                {ingredient?.price && (
                                                  <span
                                                    className="order-price-cc"
                                                    style={{ color: "black" }}
                                                  >
                                                    <span>
                                                      {ingredient?.qty}
                                                    </span>{" "}
                                                    X{" "}
                                                    <Currency
                                                      price={ingredient?.price}
                                                    />{" "}
                                                    ={" "}
                                                    <Currency
                                                      price={
                                                        ingredient?.price *
                                                        ingredient?.qty
                                                      }
                                                    />
                                                  </span>
                                                )}
                                              </p>
                                            ),
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </>
                              )}

                            {item?.note ? <div className="mt-2">
                              <span style={{ fontSize: 14, fontWeight: 600 }}>**Note: </span>
                              <span>{item?.note}</span>
                            </div> : null}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="order-dtl-list">
                    {order?.schedule_time
                      ? `Schedule Time : ${dateString(
                        order.schedule_time,
                        "lll",
                      )}`
                      : ""}
                  </div>
                  <Row>
                    <Col span={24} sm={24} lg={12}>
                      {order?.customer_id && (
                        <div className="order-dtl-card m-0">
                          <div className="order-header">
                            <h3>{lang("Customer Details")}</h3>
                          </div>
                          <div className="customer-dtl">
                            <div className="customer-info">
                              <h6>{lang("Name")} :</h6>

                              <h5>
                                {language !== "en" && language !== null
                                  ? order?.customer_id?.[`${language}_name`] ??
                                  order?.customer_id?.name
                                  : order?.customer_id?.name}
                              </h5>
                            </div>
                            <div className="customer-info">
                              <h6>{lang("Phone")} :</h6>
                              <h5>
                                {"+ " +
                                  order?.customer_id?.country_code +
                                  " " +
                                  order?.customer_id?.mobile_number}
                              </h5>
                            </div>
                            <div className="customer-info">
                              <h6>{lang("Email")} :</h6>
                              <h5>{order?.customer_id?.email}</h5>
                            </div>
                          </div>
                        </div>
                      )}

                      {order?.address && (
                        <div className="order-dtl-card">
                          <div className="customer-dtl">

                            {order?.is_company_order &&
                              <div className="customer-info">
                                <h6>{lang("Company")} :</h6>
                                <h5>
                                  {
                                    language !== "en" &&
                                      language !== null
                                      ? order?.company_id?.[`${language}_name`]
                                      : order?.company_id?.[`name`]}
                                </h5>
                              </div>
                            }

                            <div className="customer-info">
                              <h6>{lang("Address")} :</h6>
                              <h5>{order?.address?.address}, {order?.address?.landmark}</h5>
                            </div>

                            {order?.address?.area_id && (
                              <div className="customer-info">
                                <h6>{lang("Area")} :</h6>
                                <h5>
                                  {order?.address &&
                                    language !== "en" &&
                                    language !== null
                                    ? order?.address?.area_id?.[`${language}_name`]
                                    : order?.address?.area_id?.[`name`]}
                                </h5>
                              </div>
                            )}

                            {order?.address?.city_id && (
                              <div className="customer-info">
                                <h6>{lang("City")} :</h6>
                                <h5>
                                  {order?.address &&
                                    language !== "en" &&
                                    language !== null
                                    ? order?.address?.city_id?.[`${language}_name`]
                                    : order?.address?.city_id?.[`name`]}
                                </h5>
                              </div>
                            )}

                            {order?.is_company_order &&
                              <div className="customer-info">
                                <h6>{lang("Break Slot")} :</h6>
                                <h5>
                                  {dateString(order.break_slot_time)}
                                </h5>
                              </div>
                            }
                            {order?.is_company_order &&
                              <div className="customer-info">
                                <h6>{lang("Box Number")} :</h6>
                                <h5>
                                  {(order.box_number)}
                                </h5>
                              </div>
                            }
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col span={24} sm={24} lg={12}>
                      {
                        order?.driver_id ? (
                          <div className="order-dtl-card">
                            <div className="order-header">
                              <h3>{lang("Delivery Agent Details")}</h3>
                            </div>
                            <div className="customer-dtl">
                              <div className="delivery-agent-dtl">
                                <div className="agent-img">
                                  <img src={order?.driver_id?.image} />
                                </div>
                                <div className="agent-info">
                                  <div className="customer-info">
                                    <h6>{lang("Delivery Status")}:</h6>
                                    <h5>{deliveryStatus}</h5>
                                  </div>
                                  <div className="customer-info">
                                    <h6>{lang("Name")} :</h6>
                                    <h5>{data?.driver_id?.name}</h5>
                                  </div>
                                  <div className="customer-info">
                                    <h6>{lang("Phone Number")} :</h6>
                                    <h5>
                                      <Link
                                        className="social-media-sec"
                                        to={`https://wa.me/${data?.driver_id?.country_code + data?.driver_id?.mobile_number}`}
                                        target="_blank"
                                      >
                                        {formatPhone(
                                          data?.driver_id?.country_code,
                                          data?.driver_id?.mobile_number,
                                        )}
                                      </Link>
                                    </h5>
                                  </div>
                                  {order?.driver_id?.vehicle?.rc_number ? (
                                    <div className="customer-info">
                                      <h6>{lang("Vehicle No. ")}:</h6>
                                      <h5>{order?.driver_id?.vehicle?.rc_number}</h5>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null
                      }
                    </Col>
                  </Row>

                  <div className="order-dtl-card">
                    <div className="order-header">
                      <h3>{lang("Price")}</h3>
                    </div>

                    <div className="total-price">
                      <div style={{ margin: "15px 0" }}>
                        <div className="bill-info order-bill-inn">
                          <h6 className="cap">{lang("Payment Method")}</h6>
                          <h5>{lang(order?.payment_mod?.toUpperCase())}</h5>
                        </div>
                        <div className="bill-info order-bill-inn">
                          <h6 className="cap">{lang("Order Type")}</h6>
                          <h5>{lang(order?.type)}</h5>
                        </div>
                        <div className="bill-info order-bill-inn">
                          <h6 className="cap">{lang("Order Total")}</h6>
                          <h5>
                            <Currency price={order?.total_amount} />
                          </h5>
                        </div>
                        {order?.discount > 0 && (
                          <div className="bill-info order-bill-inn">
                            <h6 className="cap">
                              {lang("Discount")}
                              ({`${order?.coupon?.code}`}):
                            </h6>
                            <h5>
                              - <Currency price={order?.discount} />
                            </h5>
                          </div>
                        )}
                        {/* {lang("Platform Commission")} ({`${userProfile?.restaurant_id?.commission_rate} %`}) */}
                        {
                          //order?.platform_commission?.restaurant ?

                          <div className="bill-info order-bill-inn">
                            <h6 className="cap text-danger ">
                              {lang("Platform Commission")}
                              {`(${order?.platform_commission_rates?.restaurant ??
                                userProfile?.restaurant_id?.commission_rate
                                }%)`}
                            </h6>
                            <h5 className="text-danger ">
                              <Currency
                                price={order?.platform_commission?.restaurant}
                              />
                            </h5>
                          </div>
                          //: null
                        }

                        {order?.tax ? (
                          <div className="bill-info order-bill-inn">
                            <h6>
                              {lang("Tax")} (
                              {`${userProfile?.restaurant_id?.tax} %`})
                            </h6>
                            <h5>
                              <Currency price={order?.tax} />
                            </h5>
                          </div>
                        ) : null}

                        {order?.is_vendor_pay_delivery && (
                          <>
                            <div className="bill-info order-bill-inn">
                              <h6 className="cap">{lang("Delivery Free")}</h6>
                              <h5>
                                <Currency price={order?.delivery_fee} />
                              </h5>
                            </div>
                            <div className="bill-info order-bill-inn">
                              <h6 className="cap">{lang("Free Delivery By Vendor ")}</h6>
                              <h5>
                                {lang('Vendor to be charged the delivery fee.')}
                              </h5>
                            </div>
                          </>

                        )}

                        <div className="bill-info order-bill-inn">
                          <h6 className="cap">
                            <b>{lang("total (for restaurant)")}</b>
                          </h6>
                          <h5>
                            <Currency
                              price={((order?.total_amount) - (order?.discount) - (order?.platform_commission?.restaurant ?? 0) + (order?.tax ?? 0) - (order?.is_vendor_pay_delivery ? order.delivery_discount : 0))}
                            />
                          </h5>
                        </div>

                        <div className="bill-info order-bill-inn">
                          {order?.payment_mod == "cod" ? (
                            <>
                              {(order?.type == "Delivery" || order?.type == "Driver Request") ? (
                                <>
                                  <h6 className="cap">
                                    <b>{lang("Receive from Driver")}</b>
                                  </h6>
                                  <h5>
                                    <Currency
                                      price={
                                        order?.total_payable + order?.delivery_discount -
                                        order?.delivery_fee -
                                        order?.tip
                                      }
                                    // price={order?.total_amount - order?.discount + order?.tax }
                                    />
                                  </h5>
                                </>
                              ) : (
                                <>
                                  <h6 className="cap">
                                    <b>{lang("Receive from Customer")}</b>
                                  </h6>
                                  <h5>
                                    <Currency price={order?.total_payable} />
                                  </h5>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col >

              </Row>
            </div>
          </Modal>

          <Button
            className="button-print-now primary_btn btnStyle"
            onClick={() => {
              handlePrint(null, () => contentToPrint.current);
            }}
          >
            <i class="fas fa-print"></i>
          </Button>
        </div>
      </Modal>

      {/* //pdf start-----> */}
      {
        <div>
          <div
            ref={contentToPrint}
            style={{
              fontFamily: "calibri",
              maxWidth: "450px",
              width: "100%",
              margin: "0 auto",
              backgroundColor: "#fff",
              border: "1px solid #ededed",
              padding: "25px",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ display: "grid", gridTemplateColumns: "auto auto", alignItems: "center", justifyContent: "center" }}>
                <img alt=""
                  src={PdfLogo}
                  style={{ maxWidth: "250px", width: "100%", margin: "0 auto" }}
                />

              </div>
              <div className="">
                <img alt=""
                  src={userProfile?.restaurant_id?.logo || "https://tawasi-s3.s3.amazonaws.com/tawasi/category/TAWASI_1710636424634.jpg"}
                  style={{ maxWidth: "50px", width: "50px", height: "50px", margin: "0 auto", borderRadius: "50%" }}
                />
                <p style={{ fontSize: "14px", fontWeight: 700, marginLeft: "10px" }}>

                  {arabic_restaurant_name}
                </p>
              </div>

            </div>

            <div className="rtl-pdf-header w-100" style={{ marginTop: "25px" }}>
              <p
                className="arbic-reverse"
                style={{
                  fontSize: "14px",
                  margin: "0px 0px 5px",
                  fontWeight: 500,
                }}
              >
                {language !== "en" && language !== null
                  ? " : تاريخ الطلب " ?? "Order Date:"
                  : "Order Date:"}
                <span>
                  {order?.created_at
                    ? moment(order.created_at).format("YYYY-MM-DD hh:mm a")
                    : ""}
                </span>
              </p>
              <p
                style={{
                  fontSize: "14px",
                  margin: "0px 0px 5px",
                  fontWeight: 500,
                }}
              >
                {language !== "en" && language !== null
                  ? "الطلب :" ?? "Order :"
                  : "Order :"}{" "}
                <span style={{ fontWeight: "strong" }}>
                  {order?.rest_order_id
                    ? ` #${moment(order.created_at).format("YY-MM")}-${order.rest_order_id
                    }`
                    : null}
                </span>
              </p>
              <p
                style={{
                  fontSize: "14px",
                  margin: "0px 0px 5px",
                  fontWeight: 500,
                }}
              >
                {language !== "en" && language !== null
                  ? "النوع :" ?? "Type :"
                  : "Type :"}{" "}
                <span style={{ fontWeight: "strong" }}>
                  {/* {order?.type == "Delivery" ? (
                    <>
                      {language !== "en" && language !== null
                        ? "توصيل"
                        : "Delivery"}
                    </>
                  ) : (
                    <>
                      {language !== "en" && language !== null
                        ? "يلتقط"
                        : "Pickup"}
                    </>
                  )} */}
                  {lang(order?.type)}
                </span>
              </p>
              {/* <p
                className="arbic-reverse"
                style={{ fontSize: "14px", fontWeight: 600 }}
              >
                {language !== "en" && language !== null
                  ? " : وقت التحضير " ?? "Preparation ETA :"
                  : "Preparation ETA :"}{" "}
                <span>{estimatedDeliveryTime?.format("hh:mm a")}</span>
              </p> */}
            </div>
            <div className="order-dtl-card">
              <div className="order-dtl-list">
                {order?.items?.map((item, index) => (
                  <div className="single-order-dtl mb-2" key={index}>
                    <div className="order-dtl-left">
                      <h6>{item.qty} x</h6>
                    </div>
                    <div className="order-middle w-100">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4>
                          {language !== "en" && language !== null
                            ? item?.food_id?.[`${language}_name`] ??
                            item?.food_id?.name
                            : item?.food_id?.name}
                        </h4>
                        {item?.food_id?.name ? (
                          <span
                            className="order-price-cc"
                            style={{ color: "black" }}
                          >
                            <span>{item?.qty}</span> X{" "}
                            <CurrencySymbol price={item?.price} /> ={" "}
                            <CurrencySymbol price={item?.item_price} />
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      ></div>

                      <p className="text-dark" style={{ color: "#000", fontWeight: 500 }}>
                        {language !== "en" && language !== null
                          ? item?.size_id?.[`${language}_name`] ??
                          item?.size_id?.name
                          : item?.size_id?.name}
                      </p>

                      {item?.choice &&
                        item.choice.some(
                          (choice) => choice?.options?.length > 0,
                        ) && (
                          <h6
                            style={{
                              fontSize: "14px",
                              fontWeight: 600,
                              margin: "10px 0px 0px",
                            }}
                          >
                            ||{" "}
                            {item?.choice
                              ? item?.choice
                                ?.map((choice, index) => {
                                  return language !== "en" &&
                                    language !== null
                                    ? choice.choice_id?.[
                                    `${language}_name`
                                    ] ?? choice.choice_id?.name
                                    : choice.choice_id?.name;
                                })
                                ?.join(", ")
                              : ""}{" "}
                            ||{" "}
                          </h6>
                        )}

                      <p
                        style={{
                          fontSize: "14px",
                          margin: "0px 0px 5px",
                          fontWeight: 500,
                        }}
                      >
                        {item?.choice &&
                          item.choice.some(
                            (choice) => choice?.options?.length > 0,
                          ) ? (
                          <ul>
                            {item.choice.map((choice, index) => (
                              <li className="order-option" key={index}>
                                {choice.options.map((option, optionIndex) => {

                                  if (typeof option == 'string') {
                                    return (
                                      <div
                                        className="product-item-inn arbic-rotet"
                                        key={optionIndex}
                                      >
                                        {option}
                                      </div>
                                    )
                                  } else {
                                    return (
                                      <div
                                        className="product-item-inn arbic-rotet"
                                        key={optionIndex}
                                      >
                                        {
                                          language !== "en" && language !== null
                                            ? option?.[`${language}_name`] ??
                                            option?.name
                                            : option?.name
                                        }
                                        {/* {option} */}
                                      </div>
                                    )
                                  }
                                })}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                      </p>

                      {item?.add_on_with_qty &&
                        item?.add_on_with_qty?.length > 0 && (
                          <>
                            {Object.entries(
                              item?.add_on_with_qty.reduce(
                                (accumulator, addOn) => {
                                  const category =
                                    language !== "en" && language !== null
                                      ? addOn?.add_cat_id?.[
                                      `${language}_name`
                                      ] ?? addOn?.add_cat_id?.name
                                      : addOn?.add_cat_id?.name;
                                  if (!accumulator[category]) {
                                    accumulator[category] = [];
                                  }
                                  accumulator[category].push(addOn);
                                  return accumulator;
                                },
                                {},
                              ),
                            ).map(([category, addOns], categoryIndex) => (
                              <div key={categoryIndex}>
                                <p
                                  style={{
                                    fontSize: "14px",
                                    margin: "5px 0px",
                                    fontWeight: 600,
                                  }}
                                >
                                  <h6
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                      margin: "10px 0px 0px",
                                    }}
                                  >
                                    || {category} ||
                                  </h6>
                                </p>

                                {addOns.map((addOn, index) => (
                                  <div className="order-dd" key={index}>
                                    {addOn?.ingredient_ids.map(
                                      (ingredient, ingredientIndex) => (
                                        <p
                                          key={ingredientIndex}
                                          style={{
                                            fontSize: "14px",
                                            margin: "0px",
                                            fontWeight: 500,
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <p style={{ color: "#000", fontWeight: 500 }}>
                                            {ingredient?.qty ? (
                                              <strong>
                                                {ingredient?.qty}X
                                              </strong>
                                            ) : (
                                              ""
                                            )}{" "}
                                            {ingredient?.id?.name &&
                                              ` ${language !== "en" &&
                                                language !== null
                                                ? ingredient?.id?.[
                                                `${language}_name`
                                                ] ?? ingredient?.id?.name
                                                : ingredient?.id?.name
                                              } `}
                                            {ingredient?.size?.name !==
                                              "Regular"
                                              ? `(${language !== "en" &&
                                                language !== null
                                                ? ingredient?.size?.[
                                                `${language}_name`
                                                ] ??
                                                ingredient?.size?.name
                                                : ingredient?.size?.name
                                              })`
                                              : ""}
                                          </p>

                                          {ingredient?.price && (
                                            <span
                                              className="order-price-cc"
                                              style={{ color: "black" }}
                                            >
                                              <span>{ingredient?.qty}</span> X{" "}
                                              <CurrencySymbol
                                                price={ingredient?.price}
                                              />{" "}
                                              ={" "}
                                              <CurrencySymbol
                                                price={
                                                  ingredient?.price *
                                                  ingredient?.qty
                                                }
                                              />
                                            </span>
                                          )}
                                        </p>
                                      ),
                                    )}
                                  </div>
                                ))}
                              </div>
                            ))}
                          </>
                        )}

                      {item?.note ? <div className="mt-2">
                        <span style={{ fontSize: 14, fontWeight: 600 }}>**Note: </span>
                        <span>{item?.note}</span>
                      </div> : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {order?.customer_id && (
              <div className="rtl-pdf-customer" style={{ margin: "20px 0px" }}>
                <h6
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    margin: "10px 0px 0px",
                  }}
                >
                  {language !== "en" && language !== null
                    ? " : الزبون " ?? "Customer :"
                    : "Customer :"}{" "}
                </h6>
                <p
                  style={{
                    fontSize: "14px",
                    margin: "0px 0px 5px",
                    fontWeight: 500,
                  }}
                >
                  <h5>
                    {language !== "en" && language !== null
                      ? order?.customer_id?.[`${language}_name`] ??
                      order?.customer_id?.name
                      : order?.customer_id?.name}
                  </h5>
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    margin: "0px 0px 5px",
                    fontWeight: 500,
                  }}
                >
                  {"+ " +
                    order?.customer_id?.country_code +
                    " " +
                    order?.customer_id?.mobile_number}
                </p>

                {order?.is_company_order ? <p
                  style={{
                    fontSize: "14px",
                    margin: "0px 0px 5px",
                    fontWeight: 500,
                  }}
                >
                  {language !== "en" && language !== null
                    ? order?.company_id?.[`${language}_name`] ??
                    order?.company_id?.name
                    : order?.company_id?.name}

                </p> : null}

                <p
                  style={{
                    fontSize: "14px",
                    margin: "0px 0px 5px",
                    fontWeight: 500,
                  }}
                >
                  {order?.address?.address} {"  ,  "} {order?.address?.landmark ?? ""} {"  ,  "}
                  {order?.address && language !== "en" && language !== null
                    ? order?.address?.area_id?.[`${language}_name`]
                    : order?.address?.area_id?.[`name`]}
                  {"  ,  "}
                  {order?.address && language !== "en" && language !== null
                    ? order?.address?.city_id?.[`${language}_name`]
                    : order?.address?.city_id?.[`name`]}
                  {"  ,  "}
                </p>

                {order?.is_company_order ?
                  <p
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 5px",
                      fontWeight: 500,
                    }}
                  >
                    {lang("Break Slot")} : {dateString(order.break_slot_time)}
                  </p> : null}
                {order?.is_company_order ?
                  <p
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 5px",
                      fontWeight: 500,
                    }}
                  >
                    {lang("Box Number")} : {(order.box_number)}
                  </p> : null}
              </div>
            )}

            <div className="order-dtl-card">
              <div className="total-price">
                <div style={{ margin: "15px 0" }}>
                  <h6
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      margin: "10px 0px 0px",
                    }}
                  >
                    {language !== "en" && language !== null
                      ? "  السعر :" ?? "Price :"
                      : "Price :"}{" "}
                  </h6>
                  {/*  */}
                  <div className="bill-info">
                    <h6 className="cap">
                      {language !== "en" && language !== null
                        ? " : إجمالي الطلب " ?? "Order Total :"
                        : "Order Total :"}{" "}
                    </h6>
                    <h5>
                      <CurrencySymbol price={order?.total_amount} />
                    </h5>
                  </div>
                  {/*  */}
                  {order?.discount > 0 && (
                    <div className="bill-info">
                      <h6 className="cap">
                        {language !== "en" && language !== null
                          ? "  الخصم:" ?? "Discount :"
                          : "Discount :"}{" "}
                      </h6>
                      <h5>
                        - <CurrencySymbol price={order?.discount} />
                      </h5>
                    </div>
                  )}
                  {/*  */}
                  {order?.tax ? (
                    <div className="bill-info">
                      <h6>
                        {language !== "en" && language !== null
                          ? "  الضريبة :" ?? "Tax :"
                          : "Tax :"}{" "}
                        {`${userProfile?.restaurant_id?.tax} %`}
                      </h6>
                      <h5>
                        <CurrencySymbol price={order?.tax} />
                      </h5>
                    </div>
                  ) : null}
                  {/*  */}
                  {order?.delivery_fee ? (
                    <div className="bill-info">
                      <h6>
                        {language !== "en" && language !== null
                          ? "رسوم التوصيل"
                          : "Delivery Fee :"}{" "}
                      </h6>
                      <h5>
                        {!order?.is_free_delivery ? (
                          <CurrencySymbol price={order?.delivery_fee} />
                        ) : (
                          order?.delivery_fee - order?.delivery_discount <= 0 ? (
                            language !== "en" && language !== null
                              ? " توصيل مجاني"
                              : "Free Delivery :"
                          ) : (
                            <CurrencySymbol price={order?.delivery_fee} />
                          )
                        )}
                      </h5>
                    </div>
                  ) : null}

                  {(order?.is_free_delivery && order?.delivery_coupon) && (order?.delivery_fee - order?.delivery_discount > 0) && (
                    <div className="bill-info">
                      <h6>
                        {lang("Delivery Discount")}
                      </h6>
                      <h5>
                        <CurrencySymbol price={-order?.delivery_discount} />
                      </h5>
                    </div>
                  )}

                  {(order?.is_free_delivery && order?.used_points?.transaction_id) && (
                    <div className="bill-info">
                      <h6>
                        {lang("Delivery Discount")} (
                        {`By Loyalty Points ${order?.used_points?.points}`}):
                      </h6>
                      <h5>
                        <CurrencySymbol price={-order?.delivery_discount} />
                      </h5>
                    </div>
                  )}


                  {order?.tip > 0 && (
                    <div className="bill-info">
                      <h6 className="cap">
                        {language !== "en" && language !== null
                          ? "  إكرامية الكابتن " ?? "Driver Tip :"
                          : "Driver Tip :"}{" "}
                      </h6>
                      <h5>
                        <CurrencySymbol price={order?.tip} />
                      </h5>
                    </div>
                  )}

                  <div className="bill-info">
                    <h6 className="cap">
                      <b>
                        {" "}
                        {language !== "en" && language !== null
                          ? "الإجمالي المستحق" ?? "Total payable:"
                          : "Total payable:"}{" "}
                      </b>
                    </h6>
                    <h5>
                      <CurrencySymbol price={order?.total_payable} />
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ textAlign: "center", margin: "20px 0px" }}>
              <span style={{ fontSize: "14px", fontWeight: 600 }}>
                {language !== "en" && language !== null
                  ? "   ** نهاية الطلب **" ?? "  ** END ORDER ** "
                  : "  ** END ORDER ** "}{" "}
              </span>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default ViewOrderModal;

// {/* Ar pdf---> */}
// {pdflang === "ar" && (
//   <div
//     ref={contentToPrint}
//     style={{
//       maxWidth: "380px",
//       width: "100%",
//       margin: "0 auto",
//       backgroundColor: "#fff",
//       border: "1px solid #ededed",
//       padding: "25px",
//     }}
//   >
//     <div
//       style={{
//         display: "grid",
//         textAlign: "center",
//         justifyContent: "center",
//       }}
//     >
//       <img
//         src={PdfLogo}
//         style={{ maxWidth: "120px", width: "100%", margin: "0 auto" }}
//       />
//       <span style={{ fontSize: "14px", fontWeight: 500 }}>
//         {order?.restaurant_id?.name}
//       </span>
//     </div>
//     <div style={{ marginTop: "25px", textAlign: "right" }}>
//       <p
//         style={{
//           fontSize: "14px",
//           margin: "0px 0px 5px",
//           fontWeight: 500,
//         }}
//       >
//         تاريخ الطلب:
//         <span>
//           {order?.created_at
//             ? moment(order.created_at).format("YYYY-MM-DD hh:mm A")
//             : ""}
//         </span>
//       </p>
//       <p
//         style={{
//           fontSize: "14px",
//           margin: "0px 0px 5px",
//           fontWeight: 500,
//         }}
//       >
//         الطلب:{" "}
//         <span style={{ fontWeight: "strong" }}>
//           {order?.rest_order_id
//             ? ` #${moment(order.created_at).format("YY-MM")}-${
//                 order.rest_order_id
//               }`
//             : null}
//         </span>
//       </p>
//       <p
//         style={{
//           fontSize: "14px",
//           margin: "0px 0px 5px",
//           fontWeight: 500,
//         }}
//       >
//         النوع:{" "}
//         <span style={{ fontWeight: "strong" }}>{order?.type}</span>
//       </p>
//     </div>
//     <p
//       style={{
//         fontSize: "14px",
//         fontWeight: 600,
//         textAlign: "right",
//       }}
//     >
//       وقت التحضير:{" "}
//       <span>{estimatedDeliveryTime?.format("hh:mm a")}</span>
//     </p>

//     <div className="order-dtl-list">
//       {order?.items?.map((item, index) => (
//         <div className="single-order-dtl mb-2" key={index}>
//           <div className="order-dtl-left">
//             <h6>{item.qty} x</h6>
//           </div>
//           <div className="order-middle w-100">
//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "space-between",
//               }}
//             >
//               <h4>{item?.food_id?.name}</h4>
//               {item?.food_id?.name ? (
//                 <span style={{ color: "black" }}>
//                   <CurrencySymbol price={item?.total_price} />
//                 </span>
//               ) : (
//                 ""
//               )}
//             </div>
//             <p>{item?.size_id?.name}</p>

//             {item?.choice &&
//               item.choice.some(
//                 (choice) => choice?.options?.length > 0,
//               ) && (
//                 <h6
//                   style={{
//                     fontSize: "14px",
//                     fontWeight: 600,
//                     margin: "10px 0px 0px",
//                   }}
//                 >
//                   ||
//                   {item?.choice
//                     ? item?.choice
//                         ?.map((choice, index) => {
//                           return choice.choice_id?.name; // Arabic translation
//                         })
//                         ?.join(", ")
//                     : ""}
//                   ||{" "}
//                 </h6>
//               )}

//             <p
//               style={{
//                 fontSize: "14px",
//                 margin: "0px 0px 5px",
//                 fontWeight: 500,
//               }}
//             >
//               {item?.choice &&
//               item.choice.some(
//                 (choice) => choice?.options?.length > 0,
//               ) ? (
//                 <ul>
//                   {item.choice.map((choice, index) => (
//                     <li className="order-option" key={index}>
//                       {choice.options.map((option, optionIndex) => (
//                         <div
//                           className="product-item-inn"
//                           key={optionIndex}
//                         >
//                           {option}
//                         </div>
//                       ))}
//                     </li>
//                   ))}
//                 </ul>
//               ) : (
//                 ""
//               )}
//             </p>

//             {item?.add_on_with_qty &&
//               item?.add_on_with_qty?.length > 0 && (
//                 <>
//                   {Object.entries(
//                     item?.add_on_with_qty.reduce(
//                       (accumulator, addOn) => {
//                         const category = addOn?.add_cat_id?.name;
//                         if (!accumulator[category]) {
//                           accumulator[category] = [];
//                         }
//                         accumulator[category].push(addOn);
//                         return accumulator;
//                       },
//                       {},
//                     ),
//                   ).map(([category, addOns], categoryIndex) => (
//                     <div key={categoryIndex}>
//                       <p
//                         style={{
//                           fontSize: "14px",
//                           margin: "5px 0px",
//                           fontWeight: 600,
//                         }}
//                       >
//                         <h6
//                           style={{
//                             fontSize: "14px",
//                             fontWeight: 600,
//                             margin: "10px 0px 0px",
//                           }}
//                         >
//                           || {category} ||
//                         </h6>
//                       </p>
//                       {addOns.map((addOn, index) => (
//                         <div key={index}>
//                           {addOn?.ingredient_ids.map(
//                             (ingredient, ingredientIndex) => (
//                               <p
//                                 key={ingredientIndex}
//                                 style={{
//                                   fontSize: "14px",
//                                   margin: "0px",
//                                   fontWeight: 500,
//                                   display: "flex",
//                                   justifyContent: "space-between",
//                                   alignItems: "center",
//                                 }}
//                               >
//                                 <p>
//                                   {ingredient?.qty ? (
//                                     <strong>
//                                       {ingredient?.qty}X
//                                     </strong>
//                                   ) : (
//                                     ""
//                                   )}{" "}
//                                   {ingredient?.id?.name &&
//                                     ` ${ingredient?.id?.name} `}
//                                   {ingredient?.size?.name !==
//                                   "Regular"
//                                     ? `(${ingredient?.size?.name})`
//                                     : ""}
//                                 </p>

//                                 {ingredient?.price && (
//                                   <span style={{ color: "black" }}>
//                                     {" "}
//                                     <CurrencySymbol
//                                       price={ingredient?.price}
//                                     />
//                                   </span>
//                                 )}
//                               </p>
//                             ),
//                           )}
//                         </div>
//                       ))}
//                     </div>
//                   ))}
//                 </>
//               )}
//           </div>
//         </div>
//       ))}
//     </div>

//     {order?.customer_id && (
//       <div style={{ margin: "20px 0px", textAlign: "right" }}>
//         <h6
//           style={{
//             fontSize: "14px",
//             fontWeight: 600,
//             margin: "10px 0px 0px",
//           }}
//         >
//           الزبون:
//         </h6>
//         <p
//           style={{
//             fontSize: "14px",
//             margin: "0px 0px 5px",
//             fontWeight: 500,
//           }}
//         >
//           {order?.customer_id?.name}
//         </p>
//         <p
//           style={{
//             fontSize: "14px",
//             margin: "0px 0px 5px",
//             fontWeight: 500,
//           }}
//         >
//           {"+ " +
//             order?.customer_id?.country_code +
//             " " +
//             order?.customer_id?.mobile_number}
//         </p>
//         <p
//           style={{
//             fontSize: "14px",
//             margin: "0px 0px 5px",
//             fontWeight: 500,
//           }}
//         >
//           {order?.address?.address}
//         </p>
//       </div>
//     )}

//     <div className="order-dtl-card">
//       <div className="total-price">
//         <div style={{ margin: "15px 0" }}>
//           <h6
//             style={{
//               fontSize: "14px",
//               fontWeight: 600,
//               margin: "10px 0px 0px",
//             }}
//           >
//             السعر:
//           </h6>

//           <div className="bill-info">
//             <h6 className="cap">{lang("إجمالي الطلب")}</h6>
//             <h5>
//               <Currency price={order?.total_amount} />
//             </h5>
//           </div>
//           <div className="bill-info">
//             <h6 className="cap">{lang("الخصم")}</h6>
//             <h5>
//               <Currency price={order?.discount} />
//             </h5>
//           </div>
//           <div className="bill-info">
//             <h6 className="cap">
//               {lang("عمولة المنصة")}{" "}
//               {`(${
//                 order?.platform_commission_rates?.restaurant ??
//                 userProfile?.restaurant_id?.commission_rate
//               }%)`}
//             </h6>
//             <h5>
//               <Currency
//                 price={order?.platform_commission?.restaurant}
//               />
//             </h5>
//           </div>
//         </div>

//         <div className="bill-info">
//           <h6>
//             {lang("الضريبة")} {`${userProfile?.restaurant_id?.tax} %`}
//             )
//           </h6>
//           <h5>
//             <Currency price={order?.tax} />
//           </h5>
//         </div>
//         <div className="bill-info">
//           <h6 className="cap">{lang("الإجمالي (للمطعم)")}</h6>
//           <h5>
//             <Currency
//               price={
//                 order?.total_amount -
//                   order?.discount -
//                   (order?.platform_commission?.restaurant ?? 0) +
//                   (order?.tax ?? 0) ?? 0
//               }
//             />
//           </h5>
//         </div>
//       </div>
//     </div>
//     <div style={{ textAlign: "center", margin: "20px 0px" }}>
//       <span style={{ fontSize: "14px", fontWeight: 600 }}>
//         ** نهاية الطلب **
//       </span>
//     </div>
//   </div>
// )}
