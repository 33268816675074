import {
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";

import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import notfound from "../../assets/images/not_found.png";
import SingleImageUpload from "../../components/SingleImageUpload";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";

const dateFormat = "YYYY/MM/DD";

const AddForm = ({ section, api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [idImage, setIdImage] = useState();
  const [carLicImage, setCarLicImage] = useState();
  const [rcImage, setRcImage] = useState();
  const [dlImage, setDlImage] = useState();
  const [loading, setLoading] = useState(false);

  const [mobileNumber, setMobileNumber] = useState({
    mobile_number: "",
    country_code: "",
  });

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const getCountry = () => {
    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (status) {
          setCountry(data);
        }
      },
    });
  };

  const getState = (id) => {
    request({
      url: `/state/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "state");
        if (status) {
          setState(data);
        }
      },
    });
  };

  const getCity = (id) => {
    request({
      // url: `/city/${id}`,
      url: `/country-city/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "city");
        if (status) {
          setCity(data);
        }
      },
    });
  };

  const handleImage = (data, type) => {
    switch (type) {
      case "id":
        return data.length > 0 ? setIdImage(data[0].url) : setIdImage([]);
      case "car":
        return data.length > 0
          ? setCarLicImage(data[0].url)
          : setCarLicImage([]);
      case "rc":
        return data.length > 0 ? setRcImage(data[0].url) : setRcImage([]);
      case "dl":
        return data.length > 0 ? setDlImage(data[0].url) : setDlImage([]);
      case "image":
        return data.length > 0 ? setImage(data[0].url) : setImage([]);
    }
  };

  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    if (!data) return;
    console.log(data);
    const { acc_number, bank_name, beneficiary_name, iban_number } =
      data.bank_details;
    form.setFieldsValue({
      ...data,
      dob: moment(data.dob, dateFormat),
      mobile: data.country_code + data.mobile_number,
      country_id: data.country_id._id,
      city_id: data.city_id._id,
      acc_number,
      bank_name,
      beneficiary_name,
      iban_number,
    });
    setMobileNumber({
      mobile_number: data.mobile_number,
      country_code: data.country_code,
    });
    getCity(data.country_id._id);
    setImage(data.image);
    setDlImage(data.lc_document);
    setCarLicImage(data.car_lc_document);
    setIdImage(data.id_document);
    setRcImage(data.rc_document);
  }, [data]);

  const onCreate = (values) => {
    const {
      name,
      ar_name,
      gender,
      dob,
      email,
      commission_percentage,
      acc_number,
      bank_name,
      beneficiary_name,
      iban_number,
      country_id,
      city_id,
      area,
    } = values;
    console.log(values, "values");
    const payload = {
      name,
      ar_name,
      gender,
      dob,
      email,
      commission_percentage,
      acc_number,
      bank_name,
      beneficiary_name,
      iban_number,
      country_id,
      city_id,
      area,
    };
    setLoading(true);

    payload.image = image;
    payload.id_document = idImage;
    payload.rc_document = rcImage;
    payload.lc_document = dlImage;
    payload.car_lc_document = carLicImage;

    payload.mobile_number = mobileNumber.mobile_number;
    payload.country_code = mobileNumber.country_code;

    console.log(payload, "hfdjhjkhgjkfhgjkfhg");
    request({
      url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleChange = (value, data, event, formattedValue) => {
    var country_code = data.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data.dialCode.length),
    });
  };

  return (
    <Modal
      open={show}
      width={800}
      title={`${data ? "Edit Delivery Agent " : "Add Delivery Agent"}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24} sm={24}>
            <Form.Item
              className="mb-0"
              label={`Driver Name`}
              name="name"
              rules={[
                {
                  required: true,
                  message: "Driver name is required!",
                },
                {
                  max: 20,
                  message: "Name should not contain more then 20 characters!",
                },
                {
                  min: 2,
                  message: "Name should contain at least 2 characters!",
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`Enter Driver Name`} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              className="mb-0"
              rules={[
                {
                  validator: (_, value) => {
                    if (value !== undefined && value?.length > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Id card is required"));
                  },
                },
              ]}
              label={"Upload Id Card"}
              name="image"
            >
              <SingleImageUpload
                value={idImage}
                fileType={FileType}
                imageType={"category"}
                onChange={(data) => handleImage(data, "id")}
              />
            </Form.Item>
            {idImage && idImage.length > 0 && (
              <div className="mt-2">
                {" "}
                <div style={{ cursor: "pointer" }} onClick={() => setIdImage()}>
                  Remove
                </div>
                <Image
                  width={120}
                  src={idImage !== "" ? idImage : notfound}
                ></Image>{" "}
              </div>
            )}
          </Col>

          <Col span={24}>
            <Form.Item
              className="mb-0"
              rules={[
                {
                  validator: (_, value) => {
                    if (value !== undefined && value?.length > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Registration card is required"),
                    );
                  },
                },
              ]}
              label={"Upload Registration Card"}
              name="image"
            >
              <SingleImageUpload
                value={rcImage}
                fileType={FileType}
                imageType={"category"}
                onChange={(data) => handleImage(data, "rc")}
              />
            </Form.Item>
            {rcImage && rcImage.length > 0 && (
              <div className="mt-2">
                {" "}
                <div style={{ cursor: "pointer" }} onClick={() => setRcImage()}>
                  Remove
                </div>
                <Image
                  width={120}
                  src={rcImage !== "" ? rcImage : notfound}
                ></Image>{" "}
              </div>
            )}
          </Col>
          <Col span={24}>
            <Form.Item
              className="mb-0"
              rules={[
                {
                  validator: (_, value) => {
                    if (value !== undefined && value?.length > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Car license is required"));
                  },
                },
              ]}
              label={"Upload Car License"}
              name="image"
            >
              <SingleImageUpload
                value={carLicImage}
                fileType={FileType}
                imageType={"category"}
                onChange={(data) => handleImage(data, "car")}
              />
            </Form.Item>
            {carLicImage && carLicImage.length > 0 && (
              <div className="mt-2">
                {" "}
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setCarLicImage()}
                >
                  Remove
                </div>
                <Image
                  width={120}
                  src={carLicImage !== "" ? carLicImage : notfound}
                ></Image>{" "}
              </div>
            )}
          </Col>
          <Col span={24}>
            <Form.Item
              className="mb-0"
              rules={[
                {
                  validator: (_, value) => {
                    if (value !== undefined && value?.length > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Driving license is required"),
                    );
                  },
                },
              ]}
              label={"Upload Driving License"}
              name="image"
            >
              <SingleImageUpload
                value={dlImage}
                fileType={FileType}
                imageType={"category"}
                onChange={(data) => handleImage(data, "dl")}
              />
            </Form.Item>
            {dlImage && dlImage.length > 0 && (
              <div className="mt-2">
                {" "}
                <div style={{ cursor: "pointer" }} onClick={() => setDlImage()}>
                  Remove
                </div>
                <Image
                  width={120}
                  src={dlImage !== "" ? dlImage : notfound}
                ></Image>{" "}
              </div>
            )}
          </Col>

          <Col span={24} sm={24}>
            <Form.Item
              className="mb-0"
              label={`Tawasi Commission Percentage`}
              name="commission_percentage"
              rules={[
                {
                  required: true,
                  message: "Commission percentage required",
                },
              ]}
              //normalize={(value) => value.trimStart()}
            >
              <InputNumber
                maxLength={2}
                autoComplete="off"
                placeholder={`10%`}
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12} className="flagMobileNumber">
            <Form.Item
              label="Mobile Number"
              name="mobile"
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    if (!value) {
                      return Promise.reject("Please enter phone number");
                    }
                    if (!/^\d{8,15}$/.test(value)) {
                      return Promise.reject(
                        "Phone number must be between 8 and 12 digits",
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <PhoneInput
                inputProps={{
                  name: "mobile",
                  required: true,
                  autoFocus: false,
                  autoFormat: false,
                  autoComplete: "off",
                }}
                isValid={(value, country) => {
                  const countryCode = country.iso2;
                  const number = value.slice(country?.dialCode?.length)
                    if (countryCode === 'il') {
                      if (number && !/^5/.test(number))  return lang("Number must start with number 5.");
                      if (number && !/^972\d{9}$/.test(value))  return lang("Number must be 9 digits.");
                    }
                    if (countryCode === 'ps') {
                      if (number && !/^5/.test(number))  return lang("Number must start with number 5.");
                      if (number && !/^970\d{9}$/.test(value)) return lang("Number must be 9 digits");
                    }
              
                    if (!/^\d+$/.test(value)) {
                      return "Invalid value: " + value;
                    }
                    return true;
                }}
                country={"il"}
                preferredCountries={["ps","il"]}
                // value={
                //   mobileNumber
                //     ? (mobileNumber.country_code
                //         ? mobileNumber.country_code
                //         : "+27") +
                //       (mobileNumber.mobile_number ? mobileNumber.mobile_number : null)
                //     : "+27"
                // }
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label="Email Address"
              name="email"
              rules={[
                { type: "email", message: "The email is not a valid email!" },
                { required: true, message: "Please enter the email!" },
                {
                  pattern: new RegExp(
                    /^([a-zA-Z0-9._%-]*[a-zA-Z]+[a-zA-Z0-9._%-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                  ),
                  message: "Enter valid email!",
                },
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Email Address" />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              //className="mb-0"
              label="Date of Birth"
              name="dob"
              rules={[
                { required: true, message: "Please select date of birth!" },
              ]}
            >
              <DatePicker
                defaultValue={moment("2015/01/01", dateFormat)}
                format={dateFormat}
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label="Gender"
              name="gender"
              rules={[{ required: true, message: "Please select the gender!" }]}
            >
              <Select placeholder="Select">
                <Select.Option value="M">Male </Select.Option>
                <Select.Option value="F">Female </Select.Option>
                <Select.Option value="O">Other </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label="Country"
              name="country_id"
              rules={[
                { required: true, message: "Please select the country!" },
              ]}
            >
              <Select
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                autoComplete="off"
                placeholder="Select Country"
                showSearch
                onChange={(value) => getCity(value)}
              >
                {country.map((item) => (
                  <Select.Option
                    key={item._id}
                    label={item.name}
                    value={item._id}
                  >
                    {item.name}{" "}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label="City"
              name="city_id"
              rules={[{ required: true, message: "Please select the city!" }]}
            >
              <Select
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Select City"
                showSearch
                // onChange={(value) => getCity(value)}
              >
                {city.map((item) => (
                  <Select.Option
                    key={item._id}
                    label={item.name}
                    value={item._id}
                  >
                    {item.name}{" "}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* <Col span={24} sm={12}>
          <Form.Item
            label="Area"
            name="area"
            rules={[
              { required: true, message: "Please select the area!" },
            ]}
          >
            <Select placeholder="Select Area" showSearch >
            {
                city.map(item=><Select.Option value={item._id}>{item.name} </Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col> */}
          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`Area`}
              name="area"
              rules={[
                {
                  max: 20,
                  message: "Area should not contain more then 20 characters!",
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`Enter area`} />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`Bank Account Number`}
              name="acc_number"
              rules={[
                {
                  required: true,
                  message: "Bank account number is required!",
                },
                {
                  max: 16,
                  message: "Name should not contain more then 16 characters!",
                },
                {
                  min: 10,
                  message: "Name should contain at least 10 characters!",
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`7653-XXXX-XXXX-XXXX`} />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`Beneficiary Name`}
              name="beneficiary_name"
              rules={[
                {
                  required: true,
                  message: "Beneficiary name is required!",
                },
                {
                  max: 20,
                  message: "Name should not contain more then 20 characters!",
                },
                {
                  min: 2,
                  message: "Name should contain at least 2 characters!",
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`C. Chris`} />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`Bank Name`}
              name="bank_name"
              rules={[
                {
                  required: true,
                  message: "Bank name is required!",
                },
                {
                  max: 20,
                  message: "Name should not contain more then 20 characters!",
                },
                {
                  min: 2,
                  message: "Name should contain at least 2 characters!",
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`Enter Bank Name`} />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`IBAN Number`}
              name="iban_number"
              rules={[
                {
                  required: true,
                  message: "IBAN number is required!",
                },
              ]}
              //normalize={(value) => value.trimStart()}
            >
              <InputNumber autoComplete="off" placeholder={`............`} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              className="mb-0"
              rules={[
                {
                  validator: (_, value) => {
                    if (value !== undefined && value?.length > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Image is required"));
                  },
                },
              ]}
              label={"Upload Image"}
              name="image"
            >
              <SingleImageUpload
                value={image}
                fileType={FileType}
                imageType={"category"}
                onChange={(data) => handleImage(data, "image")}
              />
            </Form.Item>
            {image && image.length > 0 && (
              <div className="mt-2">
                {" "}
                <div style={{ cursor: "pointer" }} onClick={() => setImage()}>
                  Remove
                </div>
                <Image
                  width={120}
                  src={image !== "" ? image : notfound}
                ></Image>{" "}
              </div>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;
