import axios from "axios";
import React, { createContext, useContext, useEffect, useState ,useRef } from "react";

import { Navigate } from "react-router-dom";
import apiPath from "../constants/apiPath";
import { Severty, ShowToast } from "../helper/toast";
import socket from "../socket";
import AcceptOrderModal from "../components/AcceptOrderModal";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [session, setSession] = useState({ token: null });
  const [currency, setCurrency] = useState("ILS");

  const [userProfile, setUserProfile] = useState(); 
 

  const fetchUser = async () => {
    try {

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(apiPath.baseURL + `${apiPath.profile}`, {
        headers,
      });

      console.log(response.data.data, "--- get user profile");
      setUserProfile(response.data.data);
      localStorage.setItem("userProfile", JSON.stringify(response.data.data));

      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) return;

    let user = JSON.parse(localStorage.getItem("userProfile"));
    if (user) {
      fetchUser();
      // setUserProfile(user);
    }

    setIsLoggedIn(true);
    setSession({ token: token });
  }, []);

  useEffect(() => {
    if (!isLoggedIn) return;
  }, [isLoggedIn]);


  const login = () => {
    setIsLoggedIn(true);
    if (!!userProfile) {
      return <Navigate to="/dashboard" />;
    }
  };

  useEffect(() => {
    if (!userProfile) return;
    if (!localStorage.getItem("userProfile")) {
      localStorage.setItem("userProfile", JSON.stringify(userProfile));
    }
    if (userProfile?.country_id?.currency) {
      setCurrency(userProfile?.country_id?.currency);
    }
  }, [userProfile]);


  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userProfile");
    setIsLoggedIn(false);
    setSession({ token: null });
    setUserProfile();
    ShowToast("Logout Successfully", Severty.SUCCESS);
    return <Navigate to="/login" />;
  }; 



  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        session,
        setSession,
        userProfile,
        setUserProfile,
        login,
        logout,
        fetchUser,
        currency,
      }}
    >
      {children} 
   
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
