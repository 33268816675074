import { Card, Col, Row } from "antd";
import ArrowRight from "../assets/images/arrow_left.svg";

const SectionWrapper = ({ cardHeading, cardSubheading, extra, children }) => {
  return (
    <div className="tabled categoryService" style={{ height: "100%" }}>
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Card
            bordered={false}
            className="cap criclebox tablespace mb-24"
            title={
              <>
                <div className="arrow-ic">
                  <img src={ArrowRight} />
                </div>
                <div className="title-left">
                  <h4>{cardHeading}</h4>
                  {!!cardSubheading && <p>{cardSubheading}</p>}
                </div>
              </>
            }
            extra={extra}
          >
            {children}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SectionWrapper;
