const en = {
  restaurantApprovalMessage: "Congratulations Your Restaurant is Approved",
  restaurantApprovalDescription:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, se do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",

  restaurantWaitingMessage: "",
  restaurantWaitingDescription:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, se do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",

  "Today's Pickup Orders": "Today's Pickup Orders",
  // sidenav links
  dashboard: "Dashboard",
  orderManager: "Order Management",
  orderHistory: "Order History",
  driverManager: "Driver Management",
  menuManager: "Menu Management",
  discountManager: "Discount Management",
  reviewManager: "Review Management",
  revenueManager: "Revenue Management",
  invoicesManager: "Invoice Payment",
  notifications: "Notifications",
  settings: "Settings",
  cod_: "Cod",
  // header
  online: "Online",
  online_: "Online",
  offline: "Offline",
  profile: "Profile",

  editProfile: "Edit Profile",
  changePassword: "Change Password",
  logout: "Logout",
  "Logout":"Logout",
  viewAll: "View All",
  "Are you sure you want to Logout the Application?" : "Are you sure you want to Logout the Application?",

  // master
  customer: "Customer",
  date: "Date",
  time: "Time",
  and: "And",
  list: "List",
  search: "Search",
  year: "Year",
  month: "Month",
  reset: "Reset",
  "Export To Excel": "Export to Excel",
  add: "Add",
  create: "Create",
  Edit: "Edit",
  delete: "Delete",
  Status: "Status",
  Image: "Image",
  Action: "Action",
  okay: "Okay",
  leave: "Leave",
  cancel: "Cancel",
  quantity: "Quantity",
  amount: "Amount",
  withExcel: "With Excel",
  manually: "Manually",
  "Phone Number": "Phone Number",
  "S. NO": "S. No",
  "Created On": "Created On",
  Select: "Select",
  "Start Date": "Start Date",
  "End Date": "End Date",
  Active: "Active",
  "De-Active": "De-Active",
  Submit: "Submit",
  "non-vegetarian": "non-vegetarian",
  vegetarian: "vegetarian",
  halal: "halal",

  monthly: "Monthly",
  weekly: "Weekly",
  quarterly: "Quarterly",

  // order
  orders: "Orders",
  order: "Order",
  "Order ID": "Order ID",
  "Order Time": "Order Time",
  "Platform Commission": "Platform Commission",
  "Payment Mod": "Payment Mod",
  "Orders In Progress": "Orders In Progress",
  "Today's Orders": "Today's Orders",
  "Order List": "Order List",
  "Out For Delivery": "Out For Delivery",
  "Cancel Order": "Cancel Order",
  "Orders Out For Delivery": "Orders Out For Delivery",

  //order status
  Accepted: "Accepted",
  Processing: "Processing",
  "Ready To Pick Up": "Ready To Pick Up",
  Cancelled: "Cancelled",

  inProgress: "In Progress",
  outForDelivery: "Out For Delivery",
  cancelOrderConfirmation: "Are you sure you want to cancel the order?",

  deliveryAgent: "Delivery Agent",

  Sales: "Sales",
  numberOfUsers: "Number Of Users",

  revenue: "Revenue",

  // menu manager
  "e.g Italian, Mexican, Thai, Chinese, Punjabi":
    "e.g Italian, Mexican, Thai, Chinese, Punjabi",
  uploadMenuDescription:
    "Do you want to upload your menu with an excel sheet or enter it manually item wise ?",
  "Upload Menu": "Upload Menu",
  "Are you sure you want to Delete this category?":
    "Are you sure you want to Delete this category?",
  "Food Category": "Food Category",
  "Category Image": "Category Image",
  Choices: "Choices",
  "Add-ons": "Add-ons",
  Items: "Items",

  "Add New Category": "Add New Category",
  "Category Name": "Category Name",
  "Category Name Arabic": "Category Name Arabic",
  "All Category": "All Category",
  "Enter Category Name": "Enter Category Name",
  "Edit Category": "Edit Category",
  "Delete Category": "Delete Category",

  "Item Sizes": "Item Sizes",
  "Item Sizes Arabic": "Item Sizes Arabic",
  "Add New Item Size": "Add New Item Size",
  "Are you sure you want to Delete this item size?":
    "Are you sure you want to Delete this item size?",
  "Delete Item Size": "Delete Item Size",

  "Choice Name": "Choice Name",
  "Choice Name Arabic": "Choice Name Arabic",
  "Add New Choice": "Add New Choice",
  "Are you sure you want to Delete this choice?":
    "Are you sure you want to Delete this choice?",
  "Delete Choice": "Delete Choice",

  "Item Name": "Item Name",
  "Delete Food Item": "Delete Food Item",
  "Are you sure you want to Delete this food item?":
    "Are you sure you want to Delete this food item?",

  menu: "Menu",
  food: "Food",
  items: "Items",

  Price: "Price",
  paymentMod: "Payment Mod",
  "Payment Method": "Payment Method",
  platformCommission: "Platform Commission",
  platformType: "Platform Type",

  //discount management
  "Discount Management": "Discount Management",
  discount: "Discount",
  "Discount Name": "Discount Name",
  "Discount Name Arabic": "Discount Name Arabic",
  Discounts: "Discounts",
  "discount revenue": "Discount Revenue",
  "Create Discount": "Create Discount",
  "Delete Discount": "Delete Discount",
  "Are you sure you want to Delete this discount?":
    "Are you sure you want to Delete this discount?",
  "Banner Image": "Banner Image",

  //driver
  driver: "Driver",
  "Driver ID": "Driver ID",
  "Driver Name": "Driver Name",
  "Driver Profile": "Driver Profile",
  assignedDrivers: "Assigned Drivers List",
  id: "ID",
  waitForApproval: "Wait for Approval",

  // category management
  "Category Management": "Category Management",

  "Bank Account / Wallet": "Bank Account / Wallet",
  General: "General",
  "Select Language": "Select Language",
  "Please select the language!": "Please select the language!",
  "Select Country": "Select Country",
  English: "English",
  Arabic: "Arabic",
  "**Images should be 600x400 for best view in gallery image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 1 MB file size..!!!":
    "**Images should be 600x400 for best view in gallery image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 1 MB file size..!!!",
  "Image is required": "Image is required",
  "Name should contain at least 2 characters!":
    "Name should contain at least 2 characters!",
  "Name should not contain more then 20 characters!":
    "Name should not contain more then 20 characters!",
  "Arabic Name is required": "Arabic Name is required",
  "Name is required": "Name is required",
  "Ingredients Category": "Ingredients Category",
  "Item Ingredients": "Item Ingredients",
  "Please Enter Price": "Please Enter Price",
  "Select Ingredients Size": "Select Ingredients Size",
  "Ingredients Size": "Ingredients Size",
  "Add Price": "Add Price",
  "Please Select Size": "Please Select Size",
  "Select Size": "Select Size",
  "Contain Size": "Contain Size",
  "Item Description Arabic": "Item Description Arabic",
  "Item Description is required": "Item Description is required",
  "Description should not contain more then 200 characters!":
    "Description should not contain more then 200 characters!",
  "Description should contain at least 2 characters!":
    "Description should contain at least 2 characters!",
  "Item Description": "Item Description",
  "Upload Image": "Upload Image",
  "Item Name Arabic": "Item Name Arabic",
  "Item Name is required": "Item Name is required",
  "Please Select Category": "Please Select Category",
  "Select Category": "Select Category",
  "Edit Item": "Edit Item",
  "Add New Item": "Add New Item",

  //added by shivraj--------------------
  "Schedule Order List": "Schedule Order List",
  Review: "Review",
  Featured: "Featured",
  "Order List": "Order List",
  "Pending Order List": "Pending Order List",
  "Export To Excel": "Export To Excel",
  "Order ID": "Order ID",
  "Restaurant Order id": "Restaurant Order id",
  customer: "customer",
  items: "items",
  "Payment Method": "Payment Method",
  date: "date",
  Price: "Price",
  "Platform Commission": "Platform Commission",
  "Delivery status": "Delivery status",
  View: "View",
  Status: "Status",
  "Order Details": "Order Details",
  "Order ID": "Order ID",
  "Customer Details": "Customer Details",
  Name: "Name",
  Phone: "Phone",
  Email: "Email",
  "Address Details": "Address Details",
  Address: "Address",
  Area: "Area",
  City: "City",
  "Order Total": "Order Total",
  Tax: "Tax",
  "Platform Commission": "Platform Commission",
  "Food Items Total": "Food Items Total",
  Tip: "Tip",
  "total (for restaurant)": "total (for restaurant)",
  Okay: "Okay",
  "Schedule time": "Schedule time",
  View: "View",
  "Restaurant Order id": "Restaurant Order id",
  "Upload Excel": "Upload Excel",
  "Import Excel": "Import Excel",
  "Download Sample File": "Download Sample File",
  "Download Sample": "Download Sample",
  File: "File",
  OR: "OR",
  "Click or Drag File to This Area to Upload":
    "Click or Drag File to This Area to Upload",
  "Please Select an Excel File.": "Please Select an Excel File.",
  Import: "Import",
  "Category Sort Order": "Category Sort Order",
  "S. No": "S. No",
  "Category Sort Order": "Category Sort Order",
  "Enter Category Sort Order": "Enter Category Sort Order",
  "Category Sort Order is required": "Category Sort Order is required",
  "**Images should be 720x480 for best view in gallery image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 1 MB file size..!!!":
    "**Images should be 720x480 for best view in gallery image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 1 MB file size..!!!",
  Add: "Add",
  Update: "Update",
  "This category contains food items, please delete food item before deleting category":
    "This category contains food items, please delete food item before deleting category",
  "Are you sure you want to Delete this category?":
    "Are you sure you want to Delete this category?",
  "This category contains food items, please inactive food item before deactivating category":
    "This category contains food items, please inactive food item before deactivating category",
  "(Larger, Medium, Small, Half, Full)": "(Larger, Medium, Small, Half, Full)",
  "Delete Item Size": "Delete Item Size",
  "Are you sure you want to Delete this item size?":
    "Are you sure you want to Delete this item size?",
  "This item size contains food items, please delete food item before deleting item size":
    "This item size contains food items, please delete food item before deleting item size",
  Submit: "Submit",
  "Add New": "Add New",
  " Item Size": " Item Size",
  "Size Name": "Size Name",
  "Name is required": "Name is required",
  "Name should not contain more then 20 characters!":
    "Name should not contain more then 20 characters!",
  "Name should contain at least 2 characters!":
    "Name should contain at least 2 characters!",
  "Enter Size Name": "Enter Size Name",
  "Size Name Arabic": "Size Name Arabic",
  "Arabic Name is required": "Arabic Name is required",
  "Name should not contain more then 20 characters!":
    "Name should not contain more then 20 characters!",
  "Name should contain at least 2 characters!":
    "Name should contain at least 2 characters!",
  "(Mild, Medium, Hot) [This will not have a price.]":
    "(Mild, Medium, Hot) [This will not have a price.]",
  "This choice contains food items, please delete food item before deleting choice":
    "This choice contains food items, please delete food item before deleting choice",
  "This choice contains food items, please inactive food item before inactive choice":
    "This choice contains food items, please inactive food item before inactive choice",
  "Inactive Choice": "Inactive Choice",
  "Choice Name": "Choice Name",
  " Choice": " Choice",
  "Name should contain at least 2 characters!":
    "Name should contain at least 2 characters!",
  "Enter Choice Name": "Enter Choice Name",
  "Choice Name Arabic`": "Choice Name Arabic`",
  "Add Choice Option": "Add Choice Option",
  "Please enter choice option": "Please enter choice option",
  "Add Choice Option Arabic": "Add Choice Option Arabic",
  "Please enter choice option": "Please enter choice option",
  Save: "Save",
  "(Toppings, Extras etc.)": "(Toppings, Extras etc.)",
  "Ingredients Category": "Ingredients Category",
  "Add New Ingredients Category": "Add New Ingredients Category",
  "This category contains food items, please inactive food item before deactivating category":
    "This category contains food items, please inactive food item before deactivating category",
  "Inactive Category": "Inactive Category",
  "This category contains food items, please delete food item before deleting category":
    "This category contains food items, please delete food item before deleting category",
  "Delete Category": "Delete Category",
  "Are you sure you want to Delete this category?":
    "Are you sure you want to Delete this category?",
  "Name Arabic": "Name Arabic",
  "Enter Name": "Enter Name",
  Name: "Name",
  "Ingredients Category arabic name": "Ingredients Category arabic name",
  " Add Ingredients": " Add Ingredients",
  "Are you sure you want to Delete this Ingredient?":
    "Are you sure you want to Delete this Ingredient?",
  "Delete Ingredient": "Delete Ingredient",
  "This ingredient contains food items, please delete food item before deleting ingredient":
    "This ingredient contains food items, please delete food item before deleting ingredient",
  "Inactive Ingredient": "Inactive Ingredient",
  "This ingredient contains food items, please inactive food item before inactive ingredient":
    "This ingredient contains food items, please inactive food item before inactive ingredient",
  " Ingredient": " Ingredient",
  "Ingredients Category": "Ingredients Category",
  "Select Ingredients Category": "Select Ingredients Category",
  "Toppings Name": "Toppings Name",
  "Toppings Name Arabic": "Toppings Name Arabic",
  "Contain Size": "Contain Size",
  "Ingredients Size": "Ingredients Size",
  "Select Ingredients Size": "Select Ingredients Size",
  "Please Enter Price": "Please Enter Price",
  "Please Enter Item Price": "Please Enter Item Price",
  "Item Arabic Name": "Item Arabic Name",
  "This food contains discounts, please delete discounts before deleting food item":
    "This food contains discounts, please delete discounts before deleting food item",
  Category: "Category",
  "**Images should be 720x480 for best view in gallery image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 1 MB file size..!!!":
    "**Images should be 720x480 for best view in gallery image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 1 MB file size..!!!",
  "Price is required": "Price is required",
  Type: "Type",
  "please select a type": "please select a type",
  Vegetarian: "Vegetarian",

  "Non-Vegetarian": "Non-Vegetarian",
  "Non Veg types": "Non Veg types",
  "NON-HALAL": "NON-HALAL",
  HALAL: "HALAL",
  "Add More Customization": "Add More Customization",
  "Do This Item Contains Choice?": "Do This Item Contains Choice?",
  "Multiple Select Option": "Multiple Select Option",
  "Is Mandatory": "Is Mandatory",
  "Do This Item Contains Add Ons?": "Do This Item Contains Add Ons?",
  "Include this Category": "Include this Category",
  "Type Of Selection": "Type Of Selection",
  Single: "Single",
  Multi: "Multi",
  "Min Size": "Min Size",
  "Enter Max Size": "Enter Max Size",
  Amount: "Amount",
  "Used Count": "Used Count",
  "Upload Banner Image": "Upload Banner Image",
  "Banner image is required": "Banner image is required",
  "**Images should be 720x480 for best view in gallery image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 1 MB file size..!!!":
    "**Images should be 720x480 for best view in gallery image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 1MB file size..!!!",
  "Discount Name": "Discount Name",
  "Enter Discount Name": "Enter Discount Name",
  "Name should contain more then 250 characters!":
    "Name should contain more then 250 characters!",
  "Please Enter Restaurant Name": "Please Enter Restaurant Name",
  "Discount Name Arabic": "Discount Name Arabic",
  Type: "Type",
  Percentage: "Percentage",
  Flat: "Flat",
  "Discount Percentage": "Discount Percentage",

  "Please Enter Discount Percentage": "Please Enter Discount Percentage",
  "Fix Discount Amount": "Fix Discount Amount",
  "Please Enter Discount Amount": "Please Enter Discount Amount",
  "Apply on All Items": "Apply on All Items",
  "Select the Category": "Select the Category",
  "Missing Category Selection": "Missing Category Selection",
  "Select Food Category": "Select Food Category",
  "Select the Product": "Select the Product",
  "Select Food Category": "Select Food Category",
  "Coupon Code": "Coupon Code",
  "Name should contain more then 7 characters!":
    "Name should contain more then 7 characters!",
  "Please Enter Coupon Code": "Please Enter Coupon Code",
  "Enter Coupon Code": "Enter Coupon Code",
  "Discount Time": "Discount Time",
  "Please select the time": "Please select the time",
  "Minimum Subtotal Amount": "Minimum Subtotal Amount",
  "Please Enter Min Subtotal Amount": "Please Enter Min Subtotal Amount",
  "Enter Minimum Subtotal Amount": "Enter Minimum Subtotal Amount",
  "If the Subtotal amount not reached, then the message will appear to the customer":
    "If the Subtotal amount not reached, then the message will appear to the customer",
  "Please Enter the message": "Please Enter the message",
  "Enter the message": "Enter the message",
  "Max Discount": "Max Discount",
  "Please Enter Max Amount": "Please Enter Max Amount",
  "Enter Max Discount Amount": "Enter Max Discount Amount",
  "Select User Type": "Select User Type",
  "Please select user type": "Please select user type",
  "Uses Per User": "Uses Per User",
  "Please Enter Uses Per User": "Please Enter Uses Per User",
  "Enter Uses Per User": "Enter Uses Per User",
  "Max Number Of Uses In Total": "Max Number Of Uses In Total",
  "Please Enter Max Number Of Uses In Total":
    "Please Enter Max Number Of Uses In Total",
  "Enter Max Number Of Uses In Total": "Enter Max Number Of Uses In Total",
  "Custom Message": "Custom Message",
  "Please Enter the message": "Please Enter the message",
  "Enter the message": "Enter the message",
  "Custom Message Arabic": "Custom Message Arabic",
  "Final Step": "Final Step",
  "A Final Step!! You are about to Add Discount":
    "A Final Step!! You are about to Add Discount",
  Reviews: "Reviews",
  View: "View",
  "Total Sales": "Total Sales",
  "Today's Sales": "Today's Sales",
  "total Online Sales": "total Online Sales",
  "total COD Sales": "total COD Sales",
  "total Revenue": "total Revenue",
  Online: "Online",
  COD: "COD",

  "Pay to Admin": "Pay to Admin",
  "Restaurant Revenue": "Restaurant Revenue",
  "Total Sales": "Total Sales",
  "Admin Commision": "Admin Commision",
  "Received Amount": "Received Amount",
  "Total Orders": "Total Orders",
  "COD Revenue": "COD Revenue",
  "Online Revenue": "Online Revenue",
  "Today's Notifications": "Today's Notifications",
  "Yesterday's Notifications": "Yesterday's Notifications",
  Wallet: "Wallet",
  "My Wallet": "My Wallet",
  Balance: "Balance",
  " Add money": " Add money",
  "Add Bank Account": "Add Bank Account",
  "select month to view payments": "select month to view payments",
  Month: "Month",
  "Online Payments": "Online Payments",
  "Online Payment": "Online",
  "Last 30 days": "Last 30 days",
  Name: "Name",
  Bank: "Bank",
  Iban: "Iban",
  Account: "Account",
  Beneficiary: "Beneficiary",
  "My Wallet": "My Wallet",
  Balance: "Balance",
  " Change Language": " Change Language",
  "Choose Language": "Choose Language",
  "Please select the language!": "Please select the language!",
  "Select Language": "Select Language",
  "Contact Us": "Contact Us",
  "Email US": "Email US",
  "Version 2.8.1": "Version 2.8.1",
  "Edit Bank Account": "Edit Bank Account",
  "Add Bank Account": "Add Bank Account",
  "Bank Account Number": "Bank Account Number",
  "Bank account number is required!": "Bank account number is required!",
  "Bank account should not contain more then 20 characters!":
    "Bank account should not contain more then 20 characters!",
  "Bank account should contain at least 3 characters!":
    "Bank account should contain at least 3 characters!",
  "Beneficiary Name": "Beneficiary Name",
  "Beneficiary name is required!": "Beneficiary name is required!",
  "Name should not contain more then 20 characters!":
    "Name should not contain more then 20 characters!",
  "Name should contain at least 2 characters!":
    "Name should contain at least 2 characters!",
  "C. Chris": "C. Chris",
  "Bank Name": "Bank Name",
  "Bank name is required!": "Bank name is required!",
  "Name should not contain more then 20 characters!":
    "Name should not contain more then 20 characters!",
  "Name should contain at least 2 characters!":
    "Name should contain at least 2 characters!",
  "Enter Bank Name": "Enter Bank Name",
  "IBAN Number": "IBAN Number",
  "IBAN number is required!": "IBAN number is required!",
  "IBAN should not contain more then 34 characters!":
    "IBAN should not contain more then 34 characters!",
  "IBAN should contain at least 20 characters!":
    "IBAN should contain at least 20 characters!",
  "Enter IBAN Number": "Enter IBAN Number",
  Deposit: "Deposit",

  "Deposit Amount": "Deposit Amount",
  "Deposit Account is required!": "Deposit Account is required!",
  "Receipt number": "Receipt number",
  "Receipt number is required!": "Receipt number is required!",
  "Receipt is required": "Receipt is required",
  "Upload Receipt": "Upload Receipt",
  "Recent Invoice": "Recent Invoice",
  "Pending Invoices": "Pending Invoices",
  "Pending Approval Invoices": "Pending Approval Invoices",
  "Payment History": "Payment History",

  "Order id": "Order id",
  customer: "customer",
  "Order Items": "Order Items",
  "Payment Type": "Payment Type",
  Date: "Date",
  Amount: "Amount",
  " View Details": " View Details",
  Invoice: "Invoice",
  "total Orders": "total Orders",
  "total Sales (COD + Online Sales)": "total Sales (COD + Online Sales)",
  Pay: "Pay",
  "Download Invoice": "Download Invoice",
  "Order Without Discount": "Order Without Discount",
  "Order With Discount": "Order With Discount",
  "Invoice No.": "Invoice No.",
  "Invoice Date": "Invoice Date",
  "Total Amount": "Total Amount",
  Invoice: "Invoice",
  Download: "Download",
  Payment: "Payment",
  Status: "Status",
  Year: "Year",
  Month: "Month",
  Reset: "Reset",
  "List of All Invoices": "List of All Invoices",
  "Platform Commission": "Platform Commission",
  Action: "Action",
  "View Details": "View Details",
  "Order ID": "Order ID",
  "Order Details": "Order Details",
  "Phone Number": "Phone Number",
  "Basket Total": "Basket Total",
  "Delivery Fee": "Delivery Fee",
  "10% Discount": "10% Discount",
  Tip: "Tip",
  TOTAL: "TOTAL",
  Delivery: "Delivery",
  Status: "Status",

  "Order Type": "Order Type",
  "Payment Mode": "Payment Mode",
  "Delivery Distance": "Delivery Distance",
  "Vehicle No.": "Vehicle No.",
  "Rest. ID": "Rest. ID",
  "Collection Date": "Collection Date",
  Area: "Area",
  "Total Amount": "Total Amount",
  "Payment Mode": "Payment Mode",
  "receipt No.": "receipt No.",
  "receipt Image": "receipt Image",
  "PENDING APPROVAL INVOICES": "PENDING APPROVAL INVOICES",
  "Grow your business online with Tawasi":
    "Grow your business online with Tawasi",
  "Partner with us to reach more customers, earn more money and grow your business online - your success story begins here.":
    "Partner with us to reach more customers, earn more money and grow your business online - your success story begins here.",
  "Select country": "Select country",
  "Missing Type Selection": "Missing Type Selection",
  City: "City",
  "Select City": "Select City",
  Area: "Area",
  "Enter Area": "Enter Area",
  "Enter Address": "Enter Address",
  "Name should contain more then 600 characters!":
    "Name should contain more then 600 characters!",
  "Please Enter Restaurant Name": "Please Enter Restaurant Name",
  "Address Arabic": "Address Arabic",
  "Enter Address": "Enter Address",
  "Address should contain more then 600 characters!":
    "Address should contain more then 600 characters!",
  "Please Enter Restaurant Name": "Please Enter Restaurant Name",
  "Restaurant Category": "Restaurant Category",
  "Select Category": "Select Category",
  "Logo is required": "Logo is required",
  "Upload Logo": "Upload Logo",
  "Upload Image": "Upload Image",
  "Restaurant’s Name": "Restaurant’s Name",
  "Name should contain more then 250 characters!":
    "Name should contain more then 250 characters!",
  "Please Enter Restaurant Name": "Please Enter Restaurant Name",
  "Restaurant’s Name Arabic": "Restaurant’s Name Arabic",
  "Enter Restaurant Name": "Enter Restaurant Name",
  "Restaurant Description": "Restaurant Description",
  "Enter Restaurant Description": "Enter Restaurant Description",
  "Description should contain more then 500 characters!":
    "Description should contain more then 500 characters!",
  "Please Enter Restaurant Description": "Please Enter Restaurant Description",
  "Restaurant Description Arabic": "Restaurant Description Arabic",
  "Enter Restaurant Description": "Enter Restaurant Description",
  "Description should contain more then 500 characters!":
    "Description should contain more then 500 characters!",
  "Please Enter Restaurant Description": "Please Enter Restaurant Description",
  "Working Days": "Working Days",
  "Working Time": "Working Time",
  "Open Time": "Open Time",
  "Close Time": "Close Time",
  "Contact Person Name": "Contact Person Name",
  "Name should contain more then 250 characters!":
    "Name should contain more then 250 characters!",
  "Email ID": "Email ID",
  "Contact Person Phone Number": "Contact Person Phone Number",
  "Enter Phone Number": "Enter Phone Number",
  "This number have WhatsApp to receive messages?":
    "This number have WhatsApp to receive messages?",
  "Enter Phone Number": "Enter Phone Number",
  "This number have WhatsApp to receive messages?":
    "This number have WhatsApp to receive messages?",
  "Create Password": "Create Password",
  "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character":
    "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
  "Please enter your password!": "Please enter your password!",
  "Create Password": "Create Password",
  "Confirm Password": "Confirm Password",
  "Enter the confirm password!": "Enter the confirm password!",
  "Password that you entered doesn't match!":
    "Password that you entered doesn't match!",
  "Enter Confirm Password": "Enter Confirm Password",
  "Delivery Type": "Delivery Type",
  "Select Delivery Type": "Select Delivery Type",
  "Min. Order Price": "Min. Order Price",
  "Enter Min. Order Price": "Enter Min. Order Price",
  "Missing Min. Order Price": "Missing Min. Order Price",

  
  "Enter Min. Order Price": "Enter Min. Order Price",
  "Approximate preparation time(HH:MM)": "Approximate preparation time(HH:MM)",
  "Missing Approximate preparation time":
    "Missing Approximate preparation time",
  "Enter preparation time in HH:MM format":
    "Enter preparation time in HH:MM format",
  "Store Tax %": "Store Tax %",
  "Please Enter Store Tax Percentage": "Please Enter Store Tax Percentage",
  "Enter Tax %": "Enter Tax %",
  "Commission Rate %": "Commission Rate %",
  "Please Enter Commission Rate": "Please Enter Commission Rate",
  "Auto Accept Orders": "Auto Accept Orders",
  "Location (Drag Marker for Selecting Location)":
    "Location (Drag Marker for Selecting Location)",
  "Please select the location!": "Please select the location!",
  "Business Identification Number": "Business Identification Number",
  "Enter Business Identification Number":
    "Enter Business Identification Number",
  "Please Enter 16 digit BIN!": "Please Enter 16 digit BIN!",
  "Enter Business Identification Number":
    "Enter Business Identification Number",
  "Upload legal document": "Upload legal document",
  "Please accept the terms and conditions":
    "Please accept the terms and conditions",
  "I accept the": "I accept the",
  "Contract Terms and Conditions": "Contract Terms and Conditions",
  SUBMIT: "SUBMIT",
  "Sign In": "Sign In",
  " Login to your Account Vendor": " Login to your Account Vendor",
  "See what is going on with your business":
    "See what is going on with your business",
  "Email Address": "Email Address",
  "Please enter a valid email address!": "Please enter a valid email address!",
  "Email address not more then 255 characters!":
    "Email address not more then 255 characters!",
  "Please enter your email!": "Please enter your email!",
  "Enter Email Address": "Enter Email Address",
  Password: "Password",
  "Please enter the old password!":"Please enter the old password!",
  "Please enter the new password!":"Please enter the new password!",
  "Old password & new password must be different":"Old password & new password must be different",
  "Password should contain more then 255 characters!":
    "Password should contain more then 255 characters!",
  "Please enter your password!": "Please enter your password!",
  "Enter Password": "Enter Password",
  "Remember me": "Remember me",
  "Forgot Password?": "Forgot Password?",
  "SIGN IN": "SIGN IN",
  "Not Registered Yet?": "Not Registered Yet?",
  "CREATE AN ACCOUNT": "CREATE AN ACCOUNT",
  "Send OTP": "Send OTP",
  "Forgot Password": "Forgot Password",
  "Email Address": "Email Address",
  "Please enter a valid email address!": "Please enter a valid email address!",
  "Email address not more then 255 characters!":
    "Email address not more then 255 characters!",
  "Please enter your email!": "Please enter your email!",
  "Enter Email Address": "Enter Email Address",
  "Verify OTP": "Verify OTP",
  Verify: "Verify",
  OTP: "OTP",
  "Please enter the OTP": "Please enter the OTP",
  "OTP must be 4 digits": "OTP must be 4 digits",
  "Enter OTP": "Enter OTP",
  "Reset Password": "Reset Password",
  "Update Password": "Update Password",
  "New Password": "New Password",
  "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character":
    "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
  "Enter New Password": "Enter New Password",
  "Confirm New Password": "Confirm New Password",
  "Please enter the confirm password!": "Please enter the confirm password!",
  "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character":
    "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
  "Password that you entered doesn't match!":
    "Password that you entered doesn't match!",
  "Enter Confirm Password": "Enter Confirm Password",

  //
  "NEW ORDERS":"NEW ORDERS",
  "PROGRESSING ORDERS":"PROGRESSING ORDERS" ,
  //new 
  "Receive From Driver":"Receive From Driver",
  "Receive from Customer":"Receive from Customer",
  "Customer Address Details":"Customer Address Details",
  "QYT":"QYT",
  "Please enter your comment":"Please enter your comment",
  "Driver Tip":"Driver Tip",
  "Free Delivery":"Free Delivery",
  "Please wait for delivery agent. They will come to pick the order.":"Please wait for delivery agent. They will come to pick the order.",
  "Delayed":"Delayed",
  "Slightly Delayed":"Slightly Delayed",
  "Wallet Transactions":"Wallet Transactions",
  "List of All Transactions":"List of All Transactions",
  "Transaction Date":"Transaction Date",
  "Withdraw money":"Withdraw money",
  "Withdraw":"Withdraw",
  "Withdraw Amount":"Withdraw Amount",
  "Withdraw Amount is required!":"Withdraw Amount is required!",
  "Reason":"Reason",
  "Reason is required!":"Reason is required!",
  "Credit":"Credit",
  "Debit":"Debit",
  "Add Money":"Add Money",
  "Total Platform Commision":"Total Platform Commision",
  "Search by Item Name":"Search by Item Name",
  "Search Customer":"Search Customer",
  "Enter Customer Name and Mobile Number":"Enter Customer Name and Mobile Number"
};

export default en;
