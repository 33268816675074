import { Button, Modal, Row, Radio, Col, Form } from "antd";
import React, { useEffect, useState } from "react";

import apiPath from "../constants/apiPath";
import { Severty, ShowToast } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import lang from "../helper/langHelper";
import Currency from "./Currency";
import { useAuthContext } from "../context/AuthContext";
import AudioPlayer from "./AudioPlayer";
import { useAppContext } from "../context/AppContext";
import { CancelOrder } from "../constants/var";
import { OrderStatus } from "../constants/order-status.constants";
import moment from "moment";
import { dateString } from "../helper/functions";
const RestOrderStatus = {
  CANCEL: "cancelled",
  PROCESSING: "processing",
};

const AcceptOrderModal = ({
  section,
  show,
  hide,
  order,
  refresh,
  playAudio,
  showApprove = true,
  children
}) => {
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const { userProfile } = useAuthContext();
  const { language } = useAppContext();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [time, setTime] = useState((order.time - 600));
  const [newTime, setNewTime] = useState();

  const takeAction = (status) => {
    request({
      url: apiPath.order + "/" + order._id + "/" + status,
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        hide();
        refresh();
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const changeTime = ({ target }) => {
    const time = target.value
    console.log(time, "time");
    setTime(time)
    request({
      url: apiPath.order + "/" + order._id + "/change-time",
      method: "PUT",
      data: { time },
      onSuccess: (data) => {
        setLoading(false);
        // hide();
        refresh();
        ShowToast(lang(data.message), Severty.SUCCESS)
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChangeStatus = ({ id, reason, status }) => {
    console.log("record--", id, reason, status);
    request({
      url: apiPath.order + "/" + id + "/" + status,
      method: "PUT",
      data: { cancel_reason: reason },
      onSuccess: (data) => {
        setLoading(false);
        if (refresh) refresh();
        hide();
        setCancelModalVisible(false);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const estimatedDeliveryTime = moment(order?.created_at).add(
    (time ?? 0) - 600,
    "seconds",
  );

  return (
    <>
      <Modal
        open={show}
        width={750}
        okText="Add"
        onCancel={hide}
        footer={[
          <Button
            key="reject"
            onClick={() => {
              setCancelModalVisible(true);
              //takeAction(RestOrderStatus.CANCEL);
            }}
          >
            {lang("Reject")}
          </Button>,
          showApprove &&
          <Button
            key="approve"
            type="primary"
            onClick={() => {
              takeAction(RestOrderStatus.PROCESSING);
            }}
            loading={loading}
          >
            {lang("Approve")}
          </Button>,
        ]}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="tab_modal closable-modal"
      >
        <div className="order-dtl-card">
          <h4 className="modal_title_cls mb-2">{lang("New Order")}</h4>
          <div className="order-head">
            <p style={{ fontWeight: "bold" }}>
              {lang("Order ID")} : {order?.uid}
            </p>
          </div>
          <div className="order-header">
            <h3>{lang("Order Details")}</h3>
            <p className="order-date">
              {order?.created_at
                ? dateString(order.created_at, "lll")
                : ""}
            </p>
          </div>
          <div className="order-dtl-list">
            {order?.items?.map((item, index) => (
              <div className="single-order-dtl mb-2 " key={index}>
                <div className="order-dtl-left">
                  <h6>{item.qty} x</h6>
                </div>
                <div className="order-middle w-100">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4>
                      {language !== "en" && language !== null
                        ? item?.food_id?.[`${language}_name`] ??
                        item?.food_id?.name
                        : item?.food_id?.name}
                    </h4>
                    {item?.food_id?.name ? (
                      <span
                        className="order-price-cc"
                        style={{ color: "black" }}
                      >
                        <span className="">{item?.qty}</span> X{" "}
                        <Currency price={item?.price} /> ={" "}
                        <Currency price={item?.item_price} />
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  ></div>

                  <p>
                    {language !== "en" && language !== null
                      ? item?.size_id?.[`${language}_name`] ??
                      item?.size_id?.name
                      : item?.size_id?.name}
                  </p>

                  {item?.choice && item.choice.length ? (
                    <>
                      {item.choice.map((ch) => (
                        <div key={ch.choice_id._id}>
                          <p
                            style={{
                              fontSize: "14px",
                              margin: "5px 0px",
                              fontWeight: 600,
                            }}
                          >
                            <h6
                              style={{
                                fontSize: "14px",
                                fontWeight: 600,
                                margin: "10px 0px 0px",
                              }}
                            >
                              ||
                              {/* {ch.choice_id.name}  */}
                              {ch.choice_id.name &&
                                ` ${language !== "en" && language !== null
                                  ? ch.choice_id?.[`${language}_name`] ??
                                  ch.choice_id?.name
                                  : ch.choice_id?.name
                                } `}
                              ||
                            </h6>
                          </p>
                          <ul>
                            {ch.options.map((op, index) => {
                              if (typeof op == 'string') {
                                return (
                                  <li className="order-option" key={index}>
                                    <div className="product-item-inn">{op}</div>
                                  </li>
                                )
                              } else {
                                return (
                                  <li className="order-option" key={index}>
                                    <div className="product-item-inn">
                                      {
                                        language !== "en" && language !== null
                                          ? op?.[`${language}_name`] ??
                                          op?.name
                                          : op?.name
                                      }
                                    </div>
                                  </li>)
                              }
                            })}
                          </ul>
                        </div>
                      ))}
                    </>
                  ) : null}

                  {item?.add_on_with_qty &&
                    item?.add_on_with_qty?.length > 0 && (
                      <>
                        {Object.entries(
                          item?.add_on_with_qty.reduce((accumulator, addOn) => {
                            const category =
                              language !== "en" && language !== null
                                ? addOn?.add_cat_id?.[`${language}_name`] ??
                                addOn?.add_cat_id?.name
                                : addOn?.add_cat_id?.name;
                            if (!accumulator[category]) {
                              accumulator[category] = [];
                            }
                            accumulator[category].push(addOn);
                            return accumulator;
                          }, {}),
                        ).map(([category, addOns], categoryIndex) => (
                          <div key={categoryIndex}>
                            {/* Render Add-On Category */}
                            <p
                              style={{
                                fontSize: "14px",
                                margin: "5px 0px",
                                fontWeight: 600,
                              }}
                            >
                              <h6
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  margin: "10px 0px 0px",
                                }}
                              >
                                || {category} ||
                              </h6>
                            </p>
                            {/* Render Ingredients under each category */}
                            {addOns.map((addOn, index) => (
                              <div key={index}>
                                {addOn?.ingredient_ids.map(
                                  (ingredient, ingredientIndex) => (
                                    <p
                                      key={ingredientIndex}
                                      style={{
                                        fontSize: "14px",
                                        margin: "0px",
                                        fontWeight: 500,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <p>
                                        {ingredient?.qty ? (
                                          <strong>{ingredient?.qty}X</strong>
                                        ) : (
                                          ""
                                        )}{" "}
                                        {ingredient?.id?.name &&
                                          ` ${language !== "en" &&
                                            language !== null
                                            ? ingredient?.id?.[
                                            `${language}_name`
                                            ] ?? ingredient?.id?.name
                                            : ingredient?.id?.name
                                          } `}
                                        {ingredient?.size?.name !== "Regular"
                                          ? `(${language !== "en" &&
                                            language !== null
                                            ? ingredient?.size?.[
                                            `${language}_name`
                                            ] ?? ingredient?.size?.name
                                            : ingredient?.size?.name
                                          })`
                                          : ""}
                                      </p>

                                      {ingredient?.price && (
                                        <span
                                          className="order-price-cc"
                                          style={{ color: "black" }}
                                        >
                                          <span>{ingredient?.qty}</span> X{" "}
                                          <Currency price={ingredient?.price} />{" "}
                                          ={" "}
                                          <Currency
                                            price={
                                              ingredient?.price *
                                              ingredient?.qty
                                            }
                                          />
                                        </span>
                                      )}
                                    </p>
                                  ),
                                )}
                              </div>
                            ))}
                          </div>
                        ))}
                      </>
                    )}

                  {item?.note ? <div className="mt-2">
                    <span style={{ fontSize: 14, fontWeight: 600 }}>**Note: </span>
                    <span>{item?.note}</span>
                  </div> : null}
                </div>
              </div>
            ))}
          </div>

          <div className="order-dtl-card">
            <div className="order-header">
              <h3>{lang("Preparation time")} ({(time ?? 600) / 60} {lang("mins")})</h3>

            </div>
            <div className="order-header">

              <p
                className="arbic-reverse"
                style={{ fontSize: "14px", fontWeight: 600 }}
              >
                {language !== "en" && language !== null
                  ? " : وقت التحضير " ?? "Preparation ETA :"
                  : "Preparation ETA :"}{" "}
                <span>{estimatedDeliveryTime?.format("hh:mm a")}</span>
              </p>
            </div>
            <div className="total-price" style={{ textAlign: "center", marginTop: "12px" }}>
              <span style={{ fontWeight: 600, marginBottom: "12px", display: "block" }}>
                {lang("Change Preparation Time")} {"     "}
              </span>

              <Radio.Group onChange={changeTime} value={time}>
                <Radio value={600} >10 {lang('mins')} </Radio>
                <Radio value={900} >15 {lang('mins')}</Radio>
                <Radio value={1200} >20 {lang('mins')}</Radio>
                <Radio value={1500} >25 {lang('mins')}</Radio>
                <Radio value={1800} >30 {lang('mins')}</Radio>
                <Radio value={2100} >35 {lang('mins')}</Radio>
                <Radio value={2400} >40 {lang('mins')}</Radio>
              </Radio.Group>
            </div>

          </div>

          {order?.customer_id && (
            <div className="order-dtl-card m-0">
              <div className="order-header">
                <h3>{lang("Customer Details")}</h3>
              </div>
              <div className="customer-dtl">
                <div className="customer-info">
                  <h6>{lang("Name")} :</h6>

                  <h5>
                    {language !== "en" && language !== null
                      ? order?.customer_id?.[`${language}_name`] ??
                      order?.customer_id?.name
                      : order?.customer_id?.name}
                  </h5>
                </div>
                <div className="customer-info">
                  <h6>{lang("Phone")} :</h6>
                  <h5>
                    {"+ " +
                      order?.customer_id?.country_code +
                      " " +
                      order?.customer_id?.mobile_number}
                  </h5>
                </div>
                <div className="customer-info">
                  <h6>{lang("Email")} :</h6>
                  <h5>{order?.customer_id?.email}</h5>
                </div>
              </div>
            </div>
          )}

          {order?.address && (
            <div className="order-dtl-card">
              <div className="customer-dtl">

                {order?.is_company_order &&
                  <div className="customer-info">
                    <h6>{lang("Company")} :</h6>
                    <h5>
                      {
                        language !== "en" &&
                          language !== null
                          ? order?.company_id?.[`${language}_name`]
                          : order?.company_id?.[`name`]}
                    </h5>
                  </div>
                }

                <div className="customer-info">
                  <h6>{lang("Address")} :</h6>
                  <h5>{order?.address?.address}, {order?.address?.landmark}</h5>
                </div>

                {order?.address?.area_id && (
                  <div className="customer-info">
                    <h6>{lang("Area")} :</h6>
                    <h5>
                      {order?.address &&
                        language !== "en" &&
                        language !== null
                        ? order?.address?.area_id?.[`${language}_name`]
                        : order?.address?.area_id?.[`name`]}
                    </h5>
                  </div>
                )}

                {order?.address?.city_id && (
                  <div className="customer-info">
                    <h6>{lang("City")} :</h6>
                    <h5>
                      {order?.address &&
                        language !== "en" &&
                        language !== null
                        ? order?.address?.city_id?.[`${language}_name`]
                        : order?.address?.city_id?.[`name`]}
                    </h5>
                  </div>
                )}

                {order?.is_company_order &&
                  <div className="customer-info">
                    <h6>{lang("Break Slot")} :</h6>
                    <h5>
                      {dateString(order.break_slot_time)}
                    </h5>
                  </div>
                }
                {order?.is_company_order &&
                  <div className="customer-info">
                    <h6>{lang("Box Number")} :</h6>
                    <h5>
                      {(order.box_number)}
                    </h5>
                  </div>
                }
              </div>
            </div>
          )}

          <div className="order-dtl-card">
            <div className="order-header">
              <h3>{lang("Price")}</h3>
            </div>

            <div className="total-price">
              <div style={{ margin: "15px 0" }}>
                <div className="bill-info order-bill-inn">
                  <h6 className="cap">{lang("Payment Method")}</h6>
                  <h5>{lang(order?.payment_mod?.toUpperCase())}</h5>
                </div>
                <div className="bill-info">
                  <h6 className="cap">{lang("Order Type")}</h6>
                  <h5>{lang(order?.type)}</h5>
                </div>
                <div className="bill-info">
                  <h6 className="cap">{lang("Order Total")}</h6>
                  <h5>
                    <Currency price={order?.total_amount} />
                  </h5>
                </div>
                {order?.discount > 0 && (
                  <div className="bill-info">
                    <h6 className="cap">
                      {lang("Discount")}
                      ({`${order?.coupon?.code}`}):
                    </h6>
                    <h5>
                      - <Currency price={order?.discount} />
                    </h5>
                  </div>
                )}

                <div className="bill-info">
                  <h6 className="cap  text-danger  ">
                    {lang("Platform Commission")}{" "}
                    {`(${order?.platform_commission_rates?.restaurant ??
                      userProfile?.restaurant_id?.commission_rate
                      }%)`}
                  </h6>
                  <h5 className=" text-danger  ">
                    <Currency price={order?.platform_commission?.restaurant} />
                  </h5>
                </div>
                {order?.tax ? (
                  <div className="bill-info">
                    <h6>
                      {lang("Tax")} ({`${userProfile?.restaurant_id?.tax} %`})
                    </h6>
                    <h5>
                      <Currency price={order?.tax} />
                    </h5>
                  </div>
                ) : null}

                <div className="bill-info">
                  <h6 className="cap">
                    <b>{lang("total (for restaurant)")}</b>
                  </h6>
                  <h5>
                    <Currency
                      price={
                        order?.total_amount -
                        order?.discount -
                        (order?.platform_commission?.restaurant ?? 0) +
                        (order?.tax ?? 0) ?? 0
                      }
                    />
                  </h5>
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* {playAudio && <AudioPlayer play={playAudio} />} */}
        {playAudio && <AudioPlayer play={playAudio} />} 
        {/* <AudioPlayer play={playAudio} /> */}
        {/* { playAudio && children} */}
      </Modal>

      {cancelModalVisible && (
        <ConfirmationModal
          section={lang("Order")}
          title={lang("Cancel Order")}
          subtitle={lang(`Are you sure you want to cancel this order?`)}
          show={cancelModalVisible}
          api={apiPath}
          hide={() => {
            // setSelected();
            setCancelModalVisible(false);
            hide();
          }}
          data={order}
          reasons={CancelOrder}
          refresh={() => hide()}
          onOk={(cancelationReason) => {
            handleChangeStatus({
              id: order?._id,
              reason: cancelationReason,
              status: OrderStatus.CANCEL,
            });
          }}
        />
      )}
    </>
  );
};

export default AcceptOrderModal;

const ConfirmationModal = ({ show, hide, onOk, title, subtitle, reasons }) => {
  const [value, setValue] = useState(reasons?.length > 0 ? reasons[1] : "");

  return (
    <Modal
      width={700}
      open={show}
      onOk={() => {
        if (onOk) onOk(value);
        hide();
      }}
      okText={lang("Ok")}
      cancelText={lang("Cancel")}
      onCancel={hide}
      centered
      className="tab_modal deleteWarningModal"
    >
      <Form layout="vertical" className="p-2">
        <h4 className="modal_title_cls mb-2">{title}</h4>
        <h4 className="modal_sub_title_cls mb-2">{subtitle}</h4>
        <Row gutter={[16, 16]} className="justify-content-center">
          <Col md={16}>
            <Radio.Group
              onChange={({ target }) => setValue(target?.value)}
              value={value}
            >
              {reasons?.map((item, idx) => (
                <Radio key={idx} className="d-block" value={item}>
                  {lang(item)}
                </Radio>
              ))}
            </Radio.Group>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
