import { Badge, Button, Card, Col, Input, Row, Select, Table, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { UndoOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import apiPath from "../../constants/apiPath";
import { OrderStatus } from "../../constants/order-status.constants";
import { Last20Years, Months } from "../../constants/var";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";
import Currency from "../../components/Currency";
import { dateString } from "../../helper/functions";
import ViewOrderModal from "../../components/ViewOrderModal";
import { useAppContext } from "../../context/AppContext";
import AcceptOrderModal from "../../components/AcceptOrderModal";
import { useNavigate, useParams } from "react-router";

function CompanyPending() {
    const api = {
        list: apiPath.order + "/company",
    };

    const { id ,break_slot_time} = useParams()
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false);
    const [searchText, setSearchText] = useState("");
    const { request } = useRequest();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const { language } = useAppContext()
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const debouncedSearchText = useDebounce(searchText, 300);

    const [selected, setSelected] = useState();
    const [slot, setSlot] = useState();
    const [company, setCompany] = useState(false);

    const [filter, setFilter] = useState({
        country_id: undefined,
        city_id: undefined,
        year: undefined,
        month: undefined,
    });

    const [selectedOrder, setSelectedOrder] = useState();
    const [isOrderAcceptModalVisible, setIsOrderAcceptModalVisible] = useState(false);
    const [isOrderViewModalVisible, setIsOrderViewModalVisible] = useState(false);

    const columns = [
        {
            title: lang("Order ID"),
            dataIndex: "index",
            key: "index",
            render: (_, { uid, created_at, status }) => {
                let statusColor = "";
                let text = "";

                const currentTime = new Date();
                const orderTime = new Date(created_at);
                const minutesElapsed = (currentTime - orderTime) / 1000 / 60;

                if (status === OrderStatus.PROCESSING) {
                    statusColor = "cyan";
                    if (minutesElapsed > 3) {
                        statusColor = "red";
                        text = lang("Delayed");
                    }
                }

                return (
                    <span className="cap" style={{ color: statusColor }}>
                        {uid ? `#${uid}` : "-"} - {text}
                    </span>
                );
            },
        },

        {
            title: lang("Status"),
            key: "action",
            render: (_, record) => {
                return (
                    <Tag
                        color="warning"
                        onClick={() => {
                            setSelectedOrder(record);
                            setIsOrderAcceptModalVisible(true);
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        {record?.restaurant_status === "pending"
                            ? lang("New Order")
                            : lang(record?.restaurant_status)}
                    </Tag>
                );
            },
        },
        {
            title: lang("View"),
            key: "info",
            render: (_, record) => {
                return (
                    <Tag
                        title={lang("View")}
                        // className="btnStyle btnOutlineDelete"
                        onClick={() => {
                            setSelectedOrder(record);
                            setIsOrderViewModalVisible(true);
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        {lang("View")}
                    </Tag>
                );
            },
        },

        {
            title: lang("items"),
            key: "items",
            render: (_, { items }) =>
                items ? (
                    <>
                        {items.map((item, index) => {
                            return (
                                <span className="cap" key={index}>
                                    {language !== "en" && language !== null
                                        ? item?.food_id?.[`${language}_name`] ?? item?.food_id?.name
                                        : item?.food_id?.name}
                                    {" X " + item?.qty}
                                </span>
                            );
                        })}
                    </>
                ) : (
                    "-"
                ),
        },
        {
            title: lang("date"),
            key: "created_at",
            render: (_, { created_at }) =>
                created_at ? (
                    <span className="cap">{dateString(created_at, "lll")}</span>
                ) : (
                    "-"
                ),
        },
        {
            title: lang("Payment Mod"),
            dataIndex: "payment_mod",
            key: "payment_mod",
            render: (_, { payment_mod }) =>
                payment_mod ? (
                    <span style={{ textTransform: "uppercase" }}>
                      {payment_mod === "cod" ? lang(payment_mod) : lang('Online Payment')}
                    </span>
                  ) : (  "-" ),
        },
        {
            title: lang("Price"),
            key: "total_payable",
            render: (_, { total_payable, total_amount }) =>
                total_payable ? (
                    <span className="cap">
                        {" "}
                        <Currency price={total_amount} />
                    </span>
                ) : ("-"),
        },
        {
            title: lang("Platform Commission"), //TODO: pending
            key: "admin_commission",
            render: (_, { platform_commission }) =>
                platform_commission ? (
                    <span className="cap">
                        <Currency price={platform_commission?.restaurant ?? 0} />
                    </span>
                ) : (
                    "-"
                ),
        },
    ];

    const takeAction = (status) => {

        setConfirm(true)

        request({
            url: `${apiPath.order}/${id}` + "/company/" + status,
            method: "PUT",
            data:{
                company:id,
                order_ids:list.map((_id)=>_id),
                status
            },
            onSuccess: (data) => {
                setConfirm(false);
                navigate(-1)
            },
            onError: (error) => {
                console.log(error);
                setConfirm(false);
                ShowToast(error, Severty.ERROR);
            },
        });
    };

    useEffect(() => {
        setLoading(true);
        fetchData({ ...pagination, current: 1 });
    }, [refresh, debouncedSearchText, filter]);

    const fetchData = (pagination, filters) => {

        request({
            url: api.list + `/${id}/${break_slot_time}`,
            method: "GET",
            onSuccess: ({ data, status, total, message }) => {
                setLoading(false);
                if (status) {
                    if (!data.length) {
                        ShowToast("No Company Order Found")
                       // navigate(-1)
                        return
                    }
                    setCompany(data[0]?.company_id)
                    setSlot({
                        break_slot: data[0]?.break_slot,
                        break_slot_time: data[0]?.break_slot_time,
                    })
                    setList(data);
                    setPagination((prev) => ({
                        ...prev,
                        current: pagination.current,
                        total: total,
                    }));
                }
            },
            onError: (error) => {
                console.log(error);
                setLoading(false);

                ShowToast(error, Severty.ERROR);
            },
        });
    };



    const handleChange = (pagination, filters) => {
        fetchData(pagination, filters);
    };



    return (
        <>
            <div className="tabled quoteManagement">
                <Row gutter={[24, 0]}>
                    <Col xs={24} xl={24}>
                        <Card bordered={false} className="criclebox tablespace mb-24">
                            <div className="tab_inner_tit">
                                <div className="tab-upload-wrap d-flex align-items-center justify-content-between flex-wrap">
                                    <h3>
                                        {
                                            language !== "en" && language !== null
                                                ? company?.[`${language}_name`] ?? company?.name
                                                : company?.name} - {" "}
                                        {lang("Company Order")} - {" "}


                                        {slot ?
                                            <span> Slot -{" "} {dateString(slot.break_slot_time, "hh:mm a")}</span> : null}
                                    </h3>
                                    <Button
                                        onClick={() =>
                                            takeAction("processing")
                                        }
                                        type="primary"
                                    // icon={<UndoOutlined />}
                                    >
                                        {lang("Approve All")}
                                    </Button>
                                </div>
                            </div>
                            <div className="table-responsive customPagination withOutSearilNo">
                                <Table
                                    loading={loading}
                                    columns={columns}
                                    dataSource={list}
                                    pagination={{
                                        defaultPageSize: 10,
                                        responsive: true,
                                        total: pagination.total,
                                        showSizeChanger: true,
                                        pageSizeOptions: ["10", "20", "30", "50"],
                                    }}
                                    onChange={handleChange}
                                    className="ant-border-space"
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>

            {isOrderViewModalVisible && selectedOrder && (
                <ViewOrderModal
                    show={isOrderViewModalVisible}
                    hide={() => {
                        setIsOrderViewModalVisible(false);
                        setRefresh(true);
                    }}
                    data={selectedOrder}
                />
            )}

            {isOrderAcceptModalVisible && selectedOrder && (
                <AcceptOrderModal
                    section={"Home"}
                    show={isOrderAcceptModalVisible}
                    hide={() => {
                        setIsOrderAcceptModalVisible(false);
                        // setRefresh(true);
                    }}
                    order={selectedOrder}
                    playAudio={false}
                    showApprove={false}
                    refresh={() => {
                        setRefresh(prev => !prev);
                    }}
                />
            )}
        </>
    );
}

export default CompanyPending;
