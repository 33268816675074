import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Steps,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";

import notfound from "../../assets/images/not_found.png";
import { TextInputBox } from "../../components/InputField";
import SingleImageUpload from "../../components/SingleImageUpload";
import apiPath from "../../constants/apiPath";
import { AuthContext } from "../../context/AuthContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";
import { useAppContext } from "../../context/AppContext";

const { Step } = Steps;

export const DiscountTypes = {
  DISH: "Dish",
  VENDOR: "Vendor",
  ALLVENDOR: "AllVendor",
};

export const DISCOUNT_AMOUNT_TYPES = [
  {
    name: "percentage",
    _id: "percentage",
  },
  {
    name: "flat",
    _id: "flat",
  },
];

const discountUserType = [
  "Unlimited times for all users",
  "Once for new user for first order",
  "Once per user",
  "Define custom limit per user",
];

const AddDiscountForm = ({ section, show, hide, data, refresh }) => {
  const api = {
    fc: apiPath.common.foodCategories,
    selectedFC: apiPath.common.selectedFoodCategories,
    items: apiPath.common.items,
    discount: apiPath.discount,
    checkCode: apiPath.checkCode,
  };

  const { userProfile } = useContext(AuthContext);

  const [currentStep, setCurrentStep] = useState(0);

  const [form] = Form.useForm();
  const { request } = useRequest();
  const { language } = useAppContext();

  const [file, setFile] = useState([]);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [foodCategories, setFoodCategories] = useState([]);
  const [foodItems, setFoodItems] = useState([]);
  const [formData, setFormData] = useState();
  const [discountFor, setDiscountFor] = useState(DiscountTypes.DISH);
  const [discountAmountType, setDiscountAmountType] = useState();
  const [startDate, setStartDate] = useState();

  const [customLimitEnabled, setCustomLimitEnabled] = useState(false);
  const [isGeneric, setIsGeneric] = useState(false);

  const handleSelectDiscountUserType = (value) => {
    if (value === discountUserType[3]) {
      setCustomLimitEnabled(true);
    } else {
      setCustomLimitEnabled(false);
    }
  };

  const handleCheckboxTypeChange = (e) => {
    setIsGeneric(e.target.checked);
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleDiscountAmountTypeChange = (value) => {
    setDiscountAmountType(value.target.value);
  };

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const handleImage = (data) => {
    if (data && data.length > 0) {
      setImage(data[0].url);
      console.log(data);
    }
  };

  const checkCode = (values) => {
    const payload = { ...values };
    request({
      url: api.checkCode + `?id=${data?._id ? data?._id : ""}`,
      method: "POST",
      data: payload,
      onSuccess: ({ data, status, message }) => {
        if (status) {
          nextStep();
        } else {
          ShowToast(message, Severty.ERROR);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getFoodCategory = () => {
    request({
      url: api.selectedFC,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setFoodCategories(data);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getFoodItems = (filters) => {
    request({
      url:
        api.items +
        `?category_id=${filters?.category_id ? filters.category_id : ""}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setFoodItems(data);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handleSelectFoodCategory = (id) => {
    // get food items by category
    getFoodItems({ category_id: id });
  };

  const onStepSubmit = (values) => {
    console.log(values);

    setFormData({ ...formData, ...values });
    if (currentStep === steps.length - 1) {
      addNewDiscount(values);
    } else {
      checkCode(values);
    }
  };

  const addNewDiscount = (values) => {
    // return console.log("formData", formData);

    const payload = {
      ...formData,
      ...values,
      start_date: formData?.disount_time?.[0],
      end_date: formData?.disount_time?.[1],
      is_generic: !!isGeneric,
      discount_for: discountFor,
      image: image,
    };
    request({
      url: data ? api.discount + `/${data._id}` : api.discount,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    if (!data) return;
    console.log(data);
    let fields = {
      amount: data.amount,
      amount_mismatch_message: data.amount_mismatch_message,
      ar_amount_mismatch_message: data.ar_amount_mismatch_message,
      description: data.description,
      ar_description: data.ar_description,
      name: data.name,
      ar_name: data.ar_name,
      code: data.code,
      discount_type: data.discount_type,
      is_generic: data.is_generic,
      is_active: data.is_active,
      image: data.image,
      max_discount: data.max_discount,
      max_uses: data.max_uses,
      min_order_price: data.min_order_price,
      user_type: data.user_type,
      // start_date: moment(data.start_date),
      // end_date: moment(data.end_date),
      disount_time: [moment(data.start_date), moment(data.end_date)],
    };

    if (data.category_id) {
      fields.category_id = data.category_id;
    }
    if (data.food_id) {
      fields.food_id = data.food_id;
    }
    if (data.uses_per_user && data.user_type =="Define custom limit per user") {
      fields.uses_per_user = data.uses_per_user; 
      setCustomLimitEnabled(true);
    }
    form.setFieldsValue(fields);
    setDiscountAmountType(data.discount_type);
    setIsGeneric(data?.is_generic);

    setImage(data.image);
    setDiscountFor(data.discount_for);
  }, [data]);

  useEffect(() => {
    if (currentStep === 0) {
      getFoodCategory();
      getFoodItems();
    }
  }, [currentStep]);

  const steps = [
    {
      content: (
        <Form id="create" form={form} onFinish={onStepSubmit} layout="vertical">
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <Form.Item
                label={lang("Upload Banner Image")}
                rules={[
                  {
                    validator: (_, value) => {
                      if (image) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(lang("Banner image is required")),
                      );
                    },
                  },
                ]}
                name="thumbnail"
              >
                <SingleImageUpload
                  value={image}
                  fileType={FileType}
                  imageType={"blog"}
                  onChange={(data) => handleImage(data)}
                  isDimension={true}
                />
                <p className="img-size-details">
                  {lang(`**Images should be 720x480 for best view in gallery image. You
                  can select only (.gif, .png, .jpeg, .jpg) format files upto 1
                  MB file size..!!!`)}
                </p>
              </Form.Item>

              {
                <div className="mt-2">
                  <Image width={120} src={image ? image : notfound}></Image>
                </div>
              }
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <TextInputBox
              label={lang("Discount Name")}
              name="name"
              placeholder={lang("Enter Discount Name")}
              rules={[
                {
                  max: 250,
                  message: lang(
                    "Name should contain more then 250 characters!",
                  ),
                },
                {
                  required: true,
                  message: lang("Please Enter Restaurant Name"),
                },
              ]}
            />
            <TextInputBox
              label={lang("Discount Name Arabic")}
              name="ar_name"
              placeholder={lang("Enter Discount Name")}
              rules={[
                {
                  max: 250,
                  message: lang(
                    "Name should contain more then 250 characters!",
                  ),
                },
                {
                  required: true,
                  message: lang("Please Enter Restaurant Name"),
                },
              ]}
            />
          </Row>
          <Row gutter={24}>
            <Col span={24} sm={24}>
              <div className="status_wrap">
                <Form.Item
                  label={lang("Status")}
                  name="is_active"
                  className="mb-0"
                >
                  <Radio.Group>
                    <Radio value={true}>{lang("Active")}</Radio>
                    <Radio value={false}>{lang("De-Active")}</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      ),
    },
    {
      content: (
        <Form id="create" form={form} onFinish={onStepSubmit} layout="vertical">
          <Row gutter={24}>
            <Col span={24}>
              <Row gutter={24}>
                <Col span={24} sm={24}>
                  <div className="status_wrap">
                    <Form.Item
                      label={lang("Type")}
                      name="discount_type"
                      value={discountAmountType}
                    >
                      <Radio.Group onChange={handleDiscountAmountTypeChange}>
                        <Radio
                          value="percentage"
                          defaultChecked={discountAmountType === "percentage"}
                        >
                          {lang("Percentage")}
                        </Radio>
                        <Radio
                          value="flat"
                          defaultChecked={discountAmountType === "flat"}
                        >
                          {lang("Flat")}
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} md={12}>
              <Form.Item
                name="amount"
                label={lang("Discount Percentage")}
                rules={[
                  {
                    required: true,
                    message: lang("Please Enter Discount Percentage"),
                  },
                ]}
              >
                <InputNumber
                  maxLength={2}
                  max={100}
                  placeholder="10 %"
                  disabled={discountAmountType === "flat"}
                />
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item
                name="amount"
                label={`${lang("Fix Discount Amount")}(${
                  userProfile?.country_id?.currency
                    ? userProfile?.country_id?.currency
                    : "ILS"
                })`}
                rules={[
                  {
                    required: true,
                    message: lang("Please Enter Discount Amount"),
                  },
                ]}
              >
                <InputNumber
                  maxLength={7}
                  placeholder={lang("Please Enter Discount Amount")}
                  disabled={discountAmountType === "percentage"}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} md={24}>
              <Form.Item
                className="cc-check-box"
                name="is_generic"
                label={lang("Apply on All Items")}
                valuePropName="checked"
              >
                <Checkbox onChange={handleCheckboxTypeChange} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} md={12}>
              <Form.Item
                name="category_id"
                label={lang("Select the Category")}
                rules={[
                  {
                    required: !isGeneric,
                    message: lang("Missing Category Selection"),
                  },
                ]}
              >
                <Select
                  disabled={isGeneric}
                  placeholder={lang("Select Food Category")}
                  onChange={handleSelectFoodCategory}
                >
                  {foodCategories && foodCategories && foodCategories.length > 0
                    ? foodCategories.map((item, index) => (
                        <option key={item._id} value={item._id}>
                          <span className="cap">
                            {" "}
                            {item?._id && language !== "en" && language !== null
                              ? item?.[`${language}_name`]
                              : item?.[`name`]}
                          </span>
                        </option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item
                name="food_id"
                label={lang("Select the Product")}
                rules={[
                  {
                    required: !isGeneric,
                    message: lang("Missing Product Selection"),
                  },
                ]}
              >
                <Select
                  className="restaurant-selected"
                  disabled={isGeneric}
                  placeholder={lang("Select Food Category")}
                  mode="multiple"
                  // onChange={handleChange}
                >
                  {foodItems && foodItems && foodItems.length > 0
                    ? foodItems.map((item, index) => (
                        <option key={item._id} value={item._id}>
                          <span className="cap">
                            {" "}
                            {item?._id && language !== "en" && language !== null
                              ? item?.[`${language}_name`]
                              : item?.[`name`]}
                          </span>
                        </option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} md={24}>
              <Form.Item
                label={lang("Coupon Code")}
                name="code"
                rules={[
                  {
                    max: 7,
                    message: lang(
                      "Name should contain more then 7 characters!",
                    ),
                  },
                  {
                    required: true,
                    message: lang("Please Enter Coupon Code"),
                  },
                ]}
                normalize={(value) => value.trimStart()}
              >
                <Input placeholder={lang("Enter Coupon Code")} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label={lang("Discount Time")}
                name="disount_time"
                rules={[
                  {
                    required: true,
                    message: lang("Please select the time"),
                  },
                ]}
              >
                <DatePicker.RangePicker
                  showTime={{ format: "hh:mm a" }}
                  format="YYYY-MM-DD hh:mm a"
                  placeholder={[lang("Start Time"), lang("End Time")]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} md={24}>
              <Form.Item
                name="min_order_price"
                label={lang("Minimum Subtotal Amount (of selected items)")}
                rules={[
                  {
                    required: true,
                    message: lang("Please Enter Min Subtotal Amount"),
                  },
                ]}
              >
                <InputNumber
                  maxLength={10}
                  placeholder={lang("Enter Minimum Subtotal Amount")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} md={24}>
              <Form.Item
                name="amount_mismatch_message"
                label={lang(
                  "If the Subtotal amount not reached, then the message will appear to the customer",
                )}
                rules={[
                  {
                    required: true,
                    message: lang("Please Enter the message"),
                  },
                ]}
              >
                <Input placeholder={lang("Enter the message")} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} md={24}>
              <Form.Item
                name="ar_amount_mismatch_message"
                label="إذا لم يتم الوصول إلى المبلغ الإجمالي الفرعي، فستظهر الرسالة للعميل"
                rules={[
                  {
                    required: true,
                    message: "الرجاء إدخال الرسالة",
                  },
                ]}
              >
                <Input placeholder={lang("Enter the message")} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} md={12}>
              <Form.Item
                name="max_discount"
                label={lang("Max Discount")}
                rules={[
                  {
                    required: true,
                    message: lang("Please Enter Max Amount"),
                  },
                ]}
              >
                <InputNumber
                  maxLength={10}
                  placeholder={lang("Enter Max Discount Amount")}
                />
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item
                name="user_type"
                label={lang("Select User Type")}
                rules={[
                  {
                    required: true,
                    message: lang("Please select user type"),
                  },
                ]}
              >
                <Select
                  placeholder={lang("Select User Type")}
                  onChange={handleSelectDiscountUserType}
                >
                  {discountUserType &&
                  discountUserType &&
                  discountUserType.length > 0
                    ? discountUserType.map((item) => (
                        <option key={item} value={item}>
                          {lang(item)}
                        </option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>

            {customLimitEnabled && (
              <Col span={24} md={12}>
                <Form.Item
                  name="uses_per_user"
                  label={lang("Uses Per User")}
                  rules={[
                    {
                      required: true,
                      message: lang("Please Enter Uses Per User"),
                    },
                  ]}
                >
                  <InputNumber
                    maxLength={10}
                    placeholder={lang("Enter Uses Per User")}
                  />
                </Form.Item>
              </Col>
            )}

            <Col span={24} md={12}>
              <Form.Item
                name="max_uses"
                label={lang("Max Number Of Uses In Total")}
                rules={[
                  {
                    required: true,
                    message: lang("Please Enter Max Number Of Uses In Total"),
                  },
                ]}
              >
                <InputNumber
                  maxLength={10}
                  placeholder={lang("Enter Max Number Of Uses In Total")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} md={12}>
              <Form.Item
                name="description"
                label={lang("Custom Message")}
                rules={[
                  {
                    required: true,
                    message: lang("Please Enter the message"),
                  },
                ]}
              >
                <Input placeholder={lang("Enter the message")} />
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item
                name="ar_description"
                label={lang("Custom Message Arabic")}
                rules={[
                  {
                    required: true,
                    message: "الرجاء إدخال الرسالة",
                  },
                ]}
              >
                <Input placeholder={lang("Enter the message")} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ),
    },
    {
      content: (
        <Form id="create" form={form} onFinish={onStepSubmit} layout="vertical">
          <Row gutter={24}>
            <div className="disc-last-modal">
              <h6>{lang("Final Step")}</h6>
              <p>{lang("A Final Step!! You are about to Add Discount")}</p>
            </div>
          </Row>
        </Form>
      ),
    },
  ];

  return (
    <Modal
      open={show}
      width={840}
      okText={
        currentStep === steps.length - 1
          ? lang("Add") + " " + section
          : lang("Next")
      }
      cancelText={lang("Cancel")}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        loading: loading,
        htmlType: "submit",
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <h4 className="modal_title_cls">
        {!!data ? lang("Edit") : lang("Add New")} {section}
      </h4>

      <Steps current={currentStep}>
        {steps.map((step) => (
          <Step key={step.title} title={step.title} />
        ))}
      </Steps>

      <div style={{ marginTop: "20px" }} className="modal-border-box">
        <div>{steps[currentStep]?.content}</div>
      </div>
    </Modal>
  );
};

export default AddDiscountForm;
