import { Col, Form, Input, Layout, Row, Typography, Button, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";

import signinLogo from "../../assets/images/Logo.png";
import editlogo from "../../assets/images/icon/edit-icon.png";
import signinbg from "../../assets/images/otp-frame.png";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import { Severty, ShowToast } from "../../helper/toast";
import { PhoneNumberField } from "../../components/InputField";

const { Title } = Typography;
const { Content } = Layout;

const Otp = ({
  common,
  verifyOTP,
  loading,
  handleChangeMobileNumber,
  handleSendOTP,
  showCode = false,
}) => {
  const { request } = useRequest();
  const [form] = Form.useForm();

  const { mobile_number: mobile_number, country_code, email } = common;
  const [resendTimer, setResendTimer] = useState(0);
  const [visible, setVisible] = useState(false);
  const [mobileNumber, setMobileNumber] = useState({});

  const [otp, setOtp] = useState("");

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);

  const handleKeyUp = (event, inputRef) => {
    const input = event.target;
    const inputValue = input.value;

    if (inputValue.length === 1 && !isNaN(inputValue)) {
      otp.length < 4 && setOtp((prevOTP) => prevOTP + inputValue);
      inputRef && inputRef.current.focus();
    }
  };

  const handleKeyDown = (event, inputRef) => {
    const input = event.target;
    const inputValue = input.value;

    if (event.key === "Backspace" && input.value.length === 0) {
      setOtp((prevOTP) => prevOTP.slice(0, -1));
      inputRef && inputRef.current.focus();
    } else if (event.key === "Backspace") {
      setOtp((prevOTP) => prevOTP.slice(0, -1));
    } else {
      if (inputValue.length === 1 && !isNaN(inputValue)) {
        otp.length < 4 && setOtp((prevOTP) => prevOTP + inputValue);
        inputRef && inputRef.current.focus();
      }
    }
  };

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setTimeout(() => {
        setResendTimer(resendTimer - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [resendTimer]);

  const handleResend = () => {
    if (resendTimer > 0) return;
    setResendTimer(60); // Reset the timer to 60 seconds

    const payload = {
      type: "Vendor",
      use_for: "SignUp",
      email: email,
    };

    if (mobile_number) {
      payload.mobile_number = mobile_number;
      payload.country_code = country_code;
    }

    request({
      url: apiPath.sendOtp,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
        }
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const NumberUpdate = (value, data, type) => {
    console.log("value", value, data);
    let country_code = data?.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data?.dialCode?.length),
    });
    handleChangeMobileNumber(value, data);
  };

  useEffect(() => {
    if (!!otp && otp.length === 4) {
      console.log(otp);
      verifyOTP({
        otp: otp,
        mobile_number: mobile_number,
        country_code: country_code,
        email: email,
      });
    }
  }, [otp]);

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row>
            <Col span={24} md={12} className="signin-bg">
              <div className="signin_img">
                <img src={signinbg} />
              </div>
            </Col>
            <Col span={24} md={12}>
              <div className="signin-box">
                <div className="signup-logo">
                  <img src={signinLogo} alt="" />
                </div>
                <Row justify="space-around">
                  <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
                    <div className="signup-form">
                      <Title className="mb-15">Verification code</Title>
                      <Title className="font-regular text-muted" level={5}>
                        We have sent the code verification to mobile number
                      </Title>
                      <Title className="number-otp-vr">
                        {"+" + country_code + " " + mobile_number}
                        <Button
                          className="otp-btn-img"
                          onClick={() => setVisible(true)}
                        >
                          <img src={editlogo}></img>
                        </Button>
                      </Title>
                      <Form form={form} className="row-col">
                        <div className="otp-box-check">
                          <Input
                            className="otp-input"
                            placeholder="5"
                            max={1}
                            min={1}
                            value={otp[0]}
                            maxLength={1}
                            ref={inputRef1}
                            onKeyUp={(event) => handleKeyUp(event, inputRef2)}
                            onKeyDown={(event) => handleKeyDown(event, null)}
                          />
                          <Input
                            className="otp-input"
                            placeholder="2"
                            maxLength={1}
                            min={1}
                            value={otp[1]}
                            ref={inputRef2}
                            onKeyUp={(event) => handleKeyUp(event, inputRef3)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, inputRef1)
                            }
                          />
                          <Input
                            className="otp-input"
                            placeholder="4"
                            maxLength={1}
                            min={1}
                            value={otp[2]}
                            ref={inputRef3}
                            onKeyUp={(event) => handleKeyUp(event, inputRef4)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, inputRef2)
                            }
                          />
                          <Input
                            className="otp-input"
                            placeholder="1"
                            value={otp[3]}
                            max={1}
                            min={1}
                            maxLength={1}
                            ref={inputRef4}
                            onKeyUp={(event) => handleKeyUp(event, null)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, inputRef3)
                            }
                          />
                        </div>
                        <div className="forgot-pass">
                          {resendTimer > 0 ? (
                            <Form.Item
                              name="remember"
                              className="aligin-center"
                            >
                              <span onClick={handleResend}>
                                Resend SMS in {resendTimer}
                              </span>
                            </Form.Item>
                          ) : (
                            <Form.Item
                              name="remember"
                              className="aligin-center"
                            >
                              <Button
                                className="primary_btn btnStyle w-100"
                                onClick={handleResend}
                              >
                                Resend OTP
                              </Button>
                            </Form.Item>
                          )}
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>

      {visible && (
        <Modal
          width={700}
          open={visible}
          onOk={() => {
            handleSendOTP({
              mobile_number: mobileNumber?.mobile_number,
              country_code: mobileNumber?.country_code,
              email: email,
            });
            console.log("mobileNumberrr", mobileNumber);
            setVisible(false);
          }}
          onCancel={() => setVisible(false)}
          centered
          className="tab_modal deleteWarningModal"
        >
          <Form layout="vertical" className="p-2">
            <h4 className="modal_title_cls mb-2">Edit Phone Number</h4>
            {console.log("visible", visible)}
            {/* <h4 className="modal_sub_title_cls mb-2">{subtitle}</h4> */}

            <Row>
              <PhoneNumberField
                label="Contact Person Phone Number"
                name="mobile"
                placeholder="Enter Phone Number"
                cover={{ md: 24 }}
                className=""
                onChange={NumberUpdate}
              />
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default Otp;
