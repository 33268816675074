import { Image, Menu, Modal } from "antd";
import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import Logo from "../../assets/images/Logo.png";
import Blog from "../../assets/images/side_nav/blog.svg";
import Dash from "../../assets/images/side_nav/dash.svg";
import Disk from "../../assets/images/side_nav/discount.svg";
import Invoice from "../../assets/images/side_nav/invoice.svg";
import Menumen from "../../assets/images/side_nav/menu-manage.svg";
import Bell from "../../assets/images/side_nav/notification.svg";
import Order from "../../assets/images/side_nav/order.svg";
import Bio from "../../assets/images/side_nav/revenue.svg";
import Sec from "../../assets/images/side_nav/settings.svg";
import Star from "../../assets/images/side_nav/star.svg";
import User from "../../assets/images/side_nav/user.svg";

import { UserTypes } from "../../constants/userTypes.constants.js";
import { AuthContext } from "../../context/AuthContext";
import lang from "../../helper/langHelper.js";
import DeleteModal from "../DeleteModal.js";
import moment, { isMoment } from "moment";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const { userProfile, logout, setUserProfile } = useContext(AuthContext);
  const { confirm } = Modal;
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menuLinks, setMenuLinks] = useState([]);
  const [menuMode, setMenuMode] = useState("vertical");
  const [currentDateTime, setCurrentDateTime] = useState(moment());

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

  const menuItems = [
    {
      label: lang("Driver Request"),
      key: "Driver Request",
      path: "/driver-request",
      icon: Disk,
    },
    {
      label: lang("dashboard"),
      key: "Dashboard",
      path: "/dashboard",
      icon: Dash,
    },
    {
      label: lang("orderManager"),
      key: "OrderManagement",
      icon: Order,
      path: "/order",
    },
    {
      label: lang("orderHistory"),
      key: "History",
      icon: Blog,
      path: "/history",
    },
    {
      label: lang("driverManager"),
      key: "Driver Management",
      icon: User,
      path: "/drivers",
    },
    {
      label: lang("menuManager"),
      key: "Menu Management",
      icon: Menumen,
      path: "/menu",
    },
    {
      label: lang("discountManager"),
      key: "Discount Management",
      path: "/discount",
      icon: Disk,
    },
    {
      label: lang("reviewManager"),
      key: "RatingReview",
      path: "/review",
      icon: Star,
    },
    {
      label: lang("revenueManager"),
      key: "Revenue Management",
      path: "/Revenue",
      icon: Bio,
    },
    // {
    //   label: lang("invoicesManager"),
    //   key: "Invoice Payment",
    //   path: "/invoice",
    //   icon: Invoice,
    // },
    {
      label: lang("Financial Transactions"),
      key: "Financial Transactions",
      path: "/transactions",
      icon: Invoice,
    },
    {
      label: lang("notifications"),
      key: "Notifications",
      path: "/notifications",
      icon: Bell,
    },
    {
      label: lang("settings"),
      key: "Settings",
      path: "/settings",
      icon: Sec,
    },
  ];

  const renderTitle = (item) => {
    return (
      <>
        <Image src={item.icon} preview={false} />
        <span className="label">{item.label}</span>
      </>
    );
  };

  useEffect(() => {
    setLoading(true);
    if (!userProfile) return;
    if (userProfile.type === UserTypes.VENDOR) {
      setMenuLinks(menuItems);
      setLoading(false);
      return;
    }
    const newArray = menuItems.filter((item) => {
      if (item.children) {
        item.children = item.children.filter((child) =>
          userProfile.permission?.hasOwnProperty(child.label)
        );
      } else {
        return userProfile.permission?.hasOwnProperty(item.label);
      }
      return item;
    });

    const links = newArray.filter((item) => {
      if (item?.children?.length) {
        return true;
      } else if (!item?.children) {
        return true;
      } else {
        return false;
      }
    });

    setMenuLinks(links);
  }, [userProfile]);

  useEffect(() => {
    setMenuLinks(menuItems);
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMenuMode("inline");
      } else {
        setMenuMode("vertical");
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const updateDateTime = () => {
      setCurrentDateTime(moment());
    };

    const intervalId = setInterval(updateDateTime, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <div className="brand-logo">
        <NavLink to="" className="imgOuter">
          <img className="" src={Logo} alt="" />
        </NavLink>
        <p style={{ color: "white" }}>
          {currentDateTime.format("YYYY-MM-DD hh:mm a")}
        </p>
      </div>
      <Menu inlineCollapsed={false} mode={menuMode} className="sideNavMain">
        {menuLinks.map((item) => {
          if (item.children) {
            return (
              <>
                <Menu.SubMenu
                  key={item.key}
                  title={
                    <>
                      <span className="icon">
                        <Image src={item.icon} />
                      </span>
                      <span className="label">{item.label}</span>
                    </>
                  }
                >
                  {item.children.map((child) => (
                    <Menu.Item key={child.key}>
                      <NavLink to={child.path}>{child.label}</NavLink>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              </>
            );
          }

          return (
            <Menu.Item key={item.key}>
              <NavLink to={item.path}>{renderTitle(item)}</NavLink>
            </Menu.Item>
          );
        })}
      </Menu>

      {isLogoutModalVisible && (
        <DeleteModal
          title={"Logout"}
          subtitle={`Are you sure you want to Logout the Application?`}
          show={isLogoutModalVisible}
          hide={() => {
            setIsLogoutModalVisible(false);
          }}
          onOk={() => logout()}
        />
      )}
    </>
  );
}

export default Sidenav;
