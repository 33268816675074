import { DownOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Dropdown,
  Input,
  Menu,
  Modal,
  Table,
  Tag,
  Form,
  Row,
  Col,
  Radio,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";

import ConfirmationBox from "../../components/ConfirmationBox";
import Currency from "../../components/Currency";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import {
  DriverOrderStatus,
  OrderStatus,
} from "../../constants/order-status.constants";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import AddForm from "./AddForm";
import ViewOrderModal from "../../components/ViewOrderModal";
import { convertTime } from "../../helper/getTime";
import { dateString } from "../../helper/functions";
import { CancelOrder } from "../../constants/var";
import { useAppContext } from "../../context/AppContext";
import ExcelDownload from "../Finance/_Excel";

const Order = ({ section, data }) => {
  const sectionName = "Order";
  const params = useParams();

  const api = {
    status: apiPath.order,
    addEdit: apiPath.order,
    list: apiPath.order,
    importFile: apiPath.order + "/" + params.type,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);

  const [statusModalVisible, setStatusModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });

  const { language } = useAppContext();

  useEffect(() => {
    getCountry();
  }, []);

  const getCountry = () => {
    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (data) {
          setCountries(data);
        }
      },
    });
  };

  const getCities = (id) => {
    request({
      url: `/country-city/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          setCities(data);
        }
      },
    });
  };

  const handleChangeStatus = ({ id, reason, status }) => {
    console.log("record--", id, reason, status);
    request({
      url: api.status + "/" + id + "/" + status,
      method: "PUT",
      data: { cancel_reason: reason },
      onSuccess: (data) => {
        setLoading(false);
        fetchData({ ...pagination, current: 1 });
        if (data?._doc?.status === OrderStatus.READY) {
          setStatusModalVisible(true);
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const statusItems = [
    {
      label: lang("Ready To Pickup"),
      key: OrderStatus.READY,
    },
    {
      label: lang("Order Picked Up"),
      key: OrderStatus.PICKUP,
    },
    {
      label: lang("Cancel Order"),
      key: OrderStatus.CANCEL,
    },
  ];

  const deliveryStatus = ({ driver_status, cancelled_by, type }) => {
    let color;
    let status = lang("Driver not assigned");
    if (type == "Pickup") {
      status = "-";
    } else if (
      driver_status === DriverOrderStatus.ACCEPT ||
      driver_status == "accepted"
    ) {
      color = "lime";
      status = lang("Driver assigned");
    } else if (driver_status === DriverOrderStatus.ARRIVED) {
      color = "processing";
      status = lang("Arrived at restaurant");
    } else if (driver_status === DriverOrderStatus.DROP) {
      color = "cyan";
      status = lang("Arrived at customer");
    } else if (driver_status === DriverOrderStatus.PICKUP) {
      color = "blue";
      status = lang("Order Picked Up");
    } else if (driver_status === DriverOrderStatus.DELIVERED) {
      color = "green";
      status = lang("Delivered");
    } else if (driver_status === DriverOrderStatus.OUT) {
      color = "blue";
      status = lang(driver_status);
    } else if (driver_status === DriverOrderStatus.CANCEL) {
      color = "error";
      if (cancelled_by == "Admin") status = "Cancel by Admin";
      else status = lang("Cancelled");
    }

    return status;
  };

  const columns = [
    {
      title: lang("Order ID"),
      dataIndex: "index",
      key: "index",
      render: (_, { uid, created_at, status }) => {
        let statusColor = "";
        let text = "";

        const currentTime = new Date();
        const orderTime = new Date(created_at);
        const minutesElapsed = (currentTime - orderTime) / 1000 / 60;

        if (status === OrderStatus.PROCESSING) {
          statusColor = "cyan";
          if (minutesElapsed > 12) {
            statusColor = "red";
            text = lang("Delayed");
          }
        }

        return (
          <span className="cap" style={{ color: statusColor }}>
            {uid ? `#${uid}` : "-"} - {text}
          </span>
        );
      },
    },
    {
      title: lang("Restaurant Order id"),
      dataIndex: "rest_order_id",
      key: "rest_order_id",
      render: (_, { created_at, rest_order_id }) =>
        rest_order_id ? (
          <span className="cap">
            #{`${moment(created_at).format("YY-MM")}-${rest_order_id}`}
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("customer"),
      dataIndex: "name",
      key: "name",
      render: (_, { customer_id, company_id }) =>
        customer_id ? (
          <div style={{ display: "flex", gap: 2, flexDirection: "column" }}>
            {customer_id?.name && (
              <span className="cap">{customer_id.name}</span>
            )}
            {customer_id?.country_code && customer_id?.mobile_number && (
              <span className="cap" style={{ fontSize: "14px", color: "gray" }}>
                ({customer_id.country_code}) {customer_id.mobile_number}
              </span>
            )}
            {customer_id?.email && (
              <span style={{ fontSize: "14px", color: "gray" }}>
                {customer_id.email}
              </span>
            )}
            {company_id?.name && (
              <span style={{ fontSize: "14px", color: "gray" }}>
                <img width={35} height={35} src={company_id.logo} /> {company_id.name}  {lang('Company Order')}
              </span>
            )}
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: lang("items"),
      key: "items",
      render: (_, { items }) =>
        items ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {items.map((item, index) => {
              return (
                <span
                  className="cap"
                  key={index}
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  {/* {item?.food_id?.name + " X " + item?.qty} */}

                  {language !== "en" && language !== null
                    ? item?.food_id?.[`${language}_name`] ?? item?.food_id?.name
                    : item?.food_id?.name}
                  {" X " + item?.qty}
                </span>
              );
            })}
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Payment Method"),
      dataIndex: "payment_mod",
      key: "payment_mod",
      render: (_, { payment_mod }) =>
        payment_mod ? (
          <span style={{ textTransform: "uppercase" }}>
            {payment_mod === "cod" ? lang(payment_mod) : lang('Online Payment')}

          </span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("date"),
      key: "created_at",
      render: (_, { created_at }) => {
        return created_at ? (
          <span className="cap">{dateString(created_at, "lll")}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: lang("Price"),
      key: "total_payable",
      render: (_, { total_payable, total_amount }) =>
        total_amount ? (
          <span className="cap">
            <Currency price={total_amount} />
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Platform Commission"), //TODO: pending
      key: "admin_commission",
      render: (_, { platform_commission }) =>
        platform_commission ? (
          <span className="cap">
            <Currency price={platform_commission.restaurant} />
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Order Type"),
      dataIndex: "type",
      key: "type",
      render: (_, { type }) => (type ? <span>{lang(type)}</span> : "-"),
    },
    {
      title: lang("Order status"),
      key: "action",
      render: (_, { driver_status, status, restaurant_status, created_at }) => {
        let statusColor = "yellow";
        let text = lang("Pending");

        if (status === OrderStatus.PROCESSING) {
          statusColor = "cyan";
          text = lang("Preparing");

        } else if (status === OrderStatus.READY) {
          statusColor = "cyan";
          text = lang("Ready To Pickup");
        } else if (driver_status === DriverOrderStatus.DROP) {
          statusColor = "lime";
          text = lang("Driver arrived at customer");
        } else if (driver_status === DriverOrderStatus.OUT) {
          statusColor = "lime";
          text = lang("Out For Delivery");
        } else if (driver_status === DriverOrderStatus.PICKUP) {
          statusColor = "lime";
          text = lang("Picked up by driver");
        }
        return (
          <Badge
            className="cap"
            status={statusColor ? statusColor : "default"}
            text={text}
          />
        );
      },
    },
    {
      title: lang("Delivery Agent Name"),
      key: "created_at",
      render: (
        _,
        { driver_id, driver_status, cancelled_by, status: OrderStatus, type },
      ) =>
        driver_id ? (
          <>
            <span className="cap">{driver_id.name}</span>
            <span>
              ({deliveryStatus({ driver_status, cancelled_by, type })})
            </span>
          </>
        ) : (
          <Badge status="warning" text={lang("Not Assigned")} />
        ),
    },
    {
      title: lang("View"),
      key: "info",
      render: (_, record) => {
        return (
          <Tag
            title="View"
            className="btnOutlineDelete"
            onClick={() => {
              setSelected(record);
              setIsViewOpen(true);
            }}
            style={{
              cursor: "pointer",
              padding: "5px 10px",
              borderRadius: "50px",
            }}
          >
            {lang("View")}
          </Tag>
        );
      },
    },

    {
      title: lang("Status"),
      key: "action",
      render: (_, record) => {
        let status = lang(record.restaurant_status);

        let bgcolor = "#F3E008";
        let color;
        if (status === OrderStatus.READY) {
          if (record.driver_id) {
            status = lang("Ready to Pick Up");
            bgcolor = "#28DA28";
            color = "#414454";
          }
        } else if (status === OrderStatus.CANCEL) {
          bgcolor = "#ED1313";
          color = "#414454";
        } else if (status === OrderStatus.PROCESSING) {
          bgcolor = "#2AA0F5";
          color = "#fff";
        } else if (status === OrderStatus.PICKUP) {
          bgcolor = "green";
        }
        const menu = (
          <Menu
            onClick={(e) => {
              const key = e?.key;
              setSelected(record);
              if (key === OrderStatus.CANCEL) {
                setCancelModalVisible(true);
              } else {
                handleChangeStatus({
                  id: record?._id,
                  reason: null,
                  status: key,
                });
              }
            }}
          >
            {statusItems
              .filter((item) =>
                record.type == "Delivery" && item.key == OrderStatus.PICKUP
                  ? false
                  : true,
              )
              .map((item) => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
          </Menu>
        );
        return (
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            className="data-dropdawn"
          >
            <div style={{ backgroundColor: bgcolor }}>
              {lang(status)}
              <DownOutlined />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const handleExport = (exportData) => {
    if (!exportData.length) return;
/*
    const data = exportData.map((row) => ({
      OrderId: row.uid,
      CustomerName: row.customer_id.name,
      OrderItems: row.items
        .map((item, index) => item?.food?.name + " X " + item?.qty)
        .join(","),
      Restaurant: row.restaurant_id.name,
      PaymentMode: row.payment_mod,
      Phone: `${row.customer_id?.country_code}-${row.customer_id?.mobile_number}`,
      Amount: row.total_payable + "(ILS)",
      Status: row.status,
    }));
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);

    
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");
    XLSX.writeFile(workbook, `${moment().format("DD-MM-YYYY h:m:s")}.xlsx`); 
*/ 

const data= {
  order:{
    data:exportData.filter(item=>!item.coupon && item.type !== "Driver Request").map((row) => ({
      Category: row.type + (row.is_vendor_pay_delivery ? ("-VENDOR FREE DELIVERY") : (row.coupon ? "-VENDOR DISCOUNT" : (row.delivery_coupon ? "-ADMIN DISCOUNT" : (row.type == "Driver Request" ? "" : "-NO DISCOUNT")))),
      "Order ID": row.uid || "-",
      "Order Date": row.created_at ? moment(row.created_at).format("ll") : "-",
      "Order Time": row.created_at ? moment(row.created_at).format("HH:mm a") : "-",
      "Order Type": row.type || "-",
      "Order Status": row.status || "-",
      "Payment Mode": row.payment_mod || "-",
      "Order Total": row.total_payable || 0,
      "Coupon Code": row.coupon?.code || "NO DISCOUNT",
      "Discount Amount": row.discount || 0,
      "Delivery Coupon Code": row.delivery_coupon?.code || "NO DISCOUNT",
      "Delivery Discount Amount": row.delivery_discount || 0,
      "Platform Commission - Restaurant (Percentage)": row.platform_commission_rates?.restaurant || 0,
      "Platform Commission - Restaurant (Amount)": row.platform_commission?.restaurant || 0,
      "Tax (Percentage)": row.platform_commission_rates?.tax || 0,
      "Tax (Amount)": row.tax > 0 ? row.tax : 0,
      "Total for Restaurant": ((row?.total_amount) - (row?.discount) - (row?.platform_commission?.restaurant ?? 0) + (row?.tax ?? 0) - (row?.is_vendor_pay_delivery ? row.delivery_discount : 0)) || 0,
    })),
    filename:'Orders Without Discount'
  },
  discount:{
    data:exportData.filter(item=>item.coupon && item.type !== "Driver Request").map((row) => ({
      Category: row.type + (row.is_vendor_pay_delivery ? ("-VENDOR FREE DELIVERY") : (row.coupon ? "-VENDOR DISCOUNT" : (row.delivery_coupon ? "-ADMIN DISCOUNT" : (row.type == "Driver Request" ? "" : "-NO DISCOUNT")))),
      "Order ID": row.uid || "-",
      "Order Date": row.created_at ? moment(row.created_at).format("ll") : "-",
      "Order Time": row.created_at ? moment(row.created_at).format("HH:mm a") : "-",
      "Order Type": row.type || "-",
      "Order Status": row.status || "-",
      "Payment Mode": row.payment_mod || "-",
      "Order Total": row.total_payable || 0,
      "Coupon Code": row.coupon?.code || "NO DISCOUNT",
      "Discount Amount": row.discount || 0,
      "Delivery Coupon Code": row.delivery_coupon?.code || "NO DISCOUNT",
      "Delivery Discount Amount": row.delivery_discount || 0,
      "Platform Commission - Restaurant (Percentage)": row.platform_commission_rates?.restaurant || 0,
      "Platform Commission - Restaurant (Amount)":  row.platform_commission?.restaurant || 0,
      "Tax (Percentage)": row.platform_commission_rates?.tax || 0,
      "Tax (Amount)": row.tax > 0 ? row.tax : 0,
    "Total for Restaurant": ((row?.total_amount) - (row?.discount) - (row?.platform_commission?.restaurant ?? 0) + (row?.tax ?? 0) - (row?.is_vendor_pay_delivery ? row.delivery_discount : 0)) || 0,
    })),
    filename:'Orders With Discount'
  },
  request:{
    data:exportData.filter(item=>item.type === "Driver Request").map((row) => ({
      Category: row.type + (row.is_vendor_pay_delivery ? ("-VENDOR FREE DELIVERY") : (row.coupon ? "-VENDOR DISCOUNT" : (row.delivery_coupon ? "-ADMIN DISCOUNT" : (row.type == "Driver Request" ? "" : "-NO DISCOUNT")))),
      "Order ID": row.uid || "-",
      "Order Date": row.created_at ? moment(row.created_at).format("ll") : "-",
      "Order Time": row.created_at ? moment(row.created_at).format("HH:mm a") : "-",
      "Order Type": row.type || "-",
      "Order Status": row.status || "-",
      "Payment Mode": row.payment_mod || "-",
      "Order Total": row.total_payable || 0,
      "Coupon Code": row.coupon?.code || "NO DISCOUNT",
      "Discount Amount": row.discount || 0,
      "Delivery Coupon Code": row.delivery_coupon?.code || "NO DISCOUNT",
      "Delivery Discount Amount": row.delivery_discount || 0,
      "Platform Commission - Restaurant (Percentage)": row.platform_commission_rates?.restaurant || 0,
      "Platform Commission - Restaurant (Amount)":  row.platform_commission?.restaurant || 0,
      "Tax (Percentage)": row.platform_commission_rates?.tax || 0,
      "Tax (Amount)": row.tax > 0 ? row.tax : 0,
    "Total for Restaurant": ((row?.total_amount) - (row?.discount) - (row?.platform_commission?.restaurant ?? 0) + (row?.tax ?? 0) - (row?.is_vendor_pay_delivery ? row.delivery_discount : 0)) || 0,
    })),
    filename:'Driver Request Orders'
  }
}
ExcelDownload({data})


  };

  const getExportData = async () => {
    try {
      setExportLoading(true);
      request({
        url:
          api.list +
          `?page=1&pageSize=${pagination ? pagination.total : 100000}`,
        method: "GET",
        onSuccess: ({ data, status, total, message }) => {
          setExportLoading(false);
          if (status) {
            handleExport(data ?? []);
          }
        },
        onError: (error) => {
          console.log(error);
          setExportLoading(false);
          ShowToast(error, Severty.ERROR);
        },
      });
    } catch (err) {
      console.log(err);
      setExportLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 });
  }, [refresh, debouncedSearchText, filter]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url:
        api.list +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${pagination ? pagination.current : 1
        }&pageSize=${pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          setList(data);

          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);

        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang("Order List")}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
              <div className="pageHeadingSearch">
                <Input.Search
                  className="searchInput"
                  placeholder={lang("search")}
                  onChange={onSearch}
                  allowClear
                />
              </div>
              <div className="btn_grp">
                <Button
                  onClick={() => list.length && getExportData()}
                  loading={exportLoading}
                  className="primary_btn btnStyle"
                >
                  {lang("Export To Excel")}
                </Button>
              </div>
            </div>
          </>
        }
      >
        <div div className="table-responsive customPagination withOutSearilNo">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {visible && (
        <AddForm
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {statusModalVisible && (
        <WaitForDriverModal
          section={sectionName}
          api={api}
          show={statusModalVisible}
          hide={() => {
            setSelected();
            setStatusModalVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {cancelModalVisible && (
        <ConfirmationModal
          section={sectionName}
          title={lang("Cancel Order")}
          subtitle={lang(`Are you sure you want to cancel this order?`)}
          show={cancelModalVisible}
          api={api}
          hide={() => {
            setSelected();
            setCancelModalVisible(false);
          }}
          data={selected}
          reasons={CancelOrder}
          refresh={() => setRefresh((prev) => !prev)}
          onOk={(cancelationReason) => {
            handleChangeStatus({
              id: selected?._id,
              reason: cancelationReason,
              status: OrderStatus.CANCEL,
            });
          }}
        />
      )}

      {isViewOpen && selected && (
        <ViewOrderModal
          show={isViewOpen}
          hide={() => {
            setIsViewOpen(false);
            setRefresh(true);
          }}
          data={selected}
        />
      )}
    </>
  );
};

const WaitForDriverModal = ({
  status,
  hide,
  show,
  data,
  refresh,
  cancelOrder,
}) => {
  let text =
    "Please wait for delivery agent. They will come to pick the order.";

  return (
    <Modal
      visible={show}
      width={950}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
      footer={[
        <Button key="okay" type="primary" onClick={hide}>
          {lang("okay")}
        </Button>,
      ]}
    >
      <h4 className="modal_sub_title_cls mb-2">{lang(text)}</h4>
    </Modal>
  );
};

const ConfirmationModal = ({ show, hide, onOk, title, subtitle, reasons }) => {
  const [value, setValue] = useState(reasons?.length > 0 ? reasons[1] : "");

  return (
    <Modal
      width={700}
      open={show}
      onOk={() => {
        if (onOk) onOk(value);
        hide();
      }}
      okText={lang("ok")}
      cancelText={lang("Cancel")}
      onCancel={hide}
      centered
      className="tab_modal deleteWarningModal"
    >
      <Form layout="vertical" className="p-2">
        <h4 className="modal_title_cls mb-2">{title}</h4>
        <h4 className="modal_sub_title_cls mb-2">{subtitle}</h4>
        <Row gutter={[16, 16]} className="justify-content-center">
          <Col md={16}>
            <Radio.Group
              onChange={({ target }) => setValue(target?.value)}
              value={value}
            >
              {reasons?.map((item, idx) => (
                <Radio key={idx} className="d-block" value={item}>
                  {lang(item)}
                </Radio>
              ))}
            </Radio.Group>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Order;
