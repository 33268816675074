import {
    AutoComplete,
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    InputNumber,
    Radio,
    Row,
    Switch,
    Select,
    Input
} from "antd";
import React, { useEffect, useState } from "react";

import {
    PhoneNumberField,
    SelectInput,
    TextInputBox,
} from "../../components/InputField";
import { Palestine } from "../../constants/conts";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import LocationMap from "../../pages/User/LocationMap";

import Currency from "../../components/Currency";
import apiPath from "../../constants/apiPath";
import { useAppContext } from "../../context/AppContext";
import { useAuthContext } from "../../context/AuthContext";
import lang from "../../helper/langHelper";
import { useNavigate } from "react-router";
import checkNumber from "../../helper/apis";

const weekdays = [
    { name: "sunday", label: "S" },
    { name: "monday", label: "M" },
    { name: "tuesday", label: "T" },
    { name: "wednesday", label: "W" },
    { name: "thursday", label: "Th" },
    { name: "friday", label: "F" },
    { name: "saturday", label: "ST" },
];

const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
];

const DriverRequest = ({
    hide,
}) => {

    const { language } = useAppContext()
    const { userProfile, currency } = useAuthContext()

    const [form] = Form.useForm();
    const { request } = useRequest();
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState();
    const [cities, setCities] = useState([]);
    const [areas, setAreas] = useState([]);
    const [mobileNumber, setMobileNumber] = useState(null);
    const [delivery_fee, setDeliveryFee] = useState();
    const [location, setLocation] = useState(null);
    const [tax, setTax] = useState(0);
    const [users, setUsers] = useState([]);
    const [address, setAddress] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [selected, setSelected] = useState();
    const [is_free_delivery, setFreeDelivery] = useState(false)
    const debouncedSearchText = useDebounce(searchText, 300);

    const navigate = useNavigate()
    //for edit

    const area_id = Form.useWatch("area_id", form);

    const getAreas = (id) => {
        request({
            url: `/city-area/${id}`,
            method: "GET",
            onSuccess: ({ data, status }) => {
                console.log(data, "setCities");
                if (data) {
                    setAreas(data);
                }
            },
        });
    };

    const getDeliveryFee = (id) => {
        request({
            url: `${apiPath.driverRequest}/delivery-fee?area_id=${id}`,
            method: "GET",
            onSuccess: ({ data, status, message }) => {
                console.log(data, "DeliveryFee");
                if (status) {
                    setDeliveryFee(data);
                } else {
                    ShowToast(message, Severty.ERROR)
                    setDeliveryFee()
                    form.setFieldsValue({
                        area_id: undefined,
                    })
                }
            },
        });
    };

    const getCities = (id) => {
        request({
            url: `/country-city/${id}`,
            method: "GET",
            onSuccess: ({ data, status }) => {
                console.log(data, "setCities");
                if (data) {
                    setCities(data);
                    const find = data.find(item => item._id == userProfile.restaurant_id?.city_id)
                    if (find) {
                        setLocation({
                            latitude: parseFloat(find.latitude),
                            longitude: parseFloat(find.longitude),
                            map_address: find.name
                        })
                        form.setFieldsValue({ location_on_map: find.name })
                    }
                }
            },
        });
    };

    const getUsers = (text) => {
        request({
            url: `${apiPath.driverRequest}/search/name${text ? `?text=${text}` : ``}`,
            method: "GET",
            onSuccess: ({ data, status, message }) => {
                console.log(data, "search name");
                if (status) {
                    // data.unshift({
                    //     _id: text,
                    //     name: text
                    // })
                    setUsers(data);
                } else {
                    ShowToast(message, Severty.ERROR)
                }
            },
        });
    };

    const getAddress = (userId) => {
        request({
            url: `${apiPath.driverRequest}/${userId}/address/${area_id}`,//${userProfile.restaurant_id?.city_id}`,
            method: "GET",
            onSuccess: ({ address, status, message }) => {
                console.log(address, "address");
                if (status) {
                    setAddress(address);
                } else {
                    ShowToast(message, Severty.ERROR)
                }
            },
        });
    };

    useEffect(() => {
        if (!userProfile) return
        setCountry(userProfile.restaurant_id?.country_id)
        getAreas(userProfile.restaurant_id?.city_id)
        getCities(userProfile.restaurant_id?.country_id)

    }, [userProfile]);



    const onCreate = async (values) => {
        setLoading(true); 
        const valid = await checkNumber({ number: mobileNumber.country_code + mobileNumber.mobile_number })
        if (!valid) return setLoading(false);

        const { location_on_map, time } = values;

        let payload = {
            ...values,
            ...mobileNumber,
            ...location,
            delivery_fee,
            tax,
            ...location_on_map
        };

        if (selected) {
            payload.name = selected._id
        }

        setLoading(true);

        console.log(payload, "payload");

        request({
            url: apiPath.driverRequest,
            method: "POST",
            data: payload,
            onSuccess: (data) => {
                setLoading(false);
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS);
                    form.resetFields()
                    setSelected()
                    setDeliveryFee()
                    setTax(0)

                    const find = cities.find(item => item._id == userProfile.restaurant_id?.city_id)
                    if (find) {
                        setLocation({
                            latitude: parseFloat(find.latitude),
                            longitude: parseFloat(find.longitude),
                            map_address: find.name
                        })
                        form.setFieldsValue({ location_on_map: find.name })
                    }

                    setTimeout(() => navigate('/order'), 200)
                } else {
                    ShowToast(data.message, Severty.ERROR);
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR);
                setLoading(false);
            },
        });
    };

    const handleMobileNumberChange = (value, data, type) => {
        let country_code = data?.dialCode;
        setMobileNumber({
            country_code: country_code,
            mobile_number: value.slice(data?.dialCode?.length)//?.str.replace(/^0+/, ''),
        });
        form.setFieldsValue({ mobile: country_code + value.slice(data?.dialCode?.length) })
    };

    const address_id = Form.useWatch('address_id', form);

    useEffect(() => {
        if (!address_id) {
            form.setFieldsValue({
                address_name: undefined,
                address: undefined,
                landmark: undefined
            })
            return
        }
        const find = address.find(item => item._id == address_id)

        if (!find) {

            return
        }

        form.setFieldsValue({
            address_name: find.name,
            address: find.address,
            landmark: find.landmark
        })

    }, [address_id])

    useEffect(() => {
        if (selected) {
            if (selected?.country_code) {
                setMobileNumber({
                    country_code: selected?.country_code,
                    mobile_number: selected?.mobile_number,
                });
                console.log({ mobile: selected?.country_code + selected?.mobile_number }, "Mobile", selected);
                form.setFieldsValue({ mobile: selected?.country_code + selected?.mobile_number, name: selected?.name })
                getAddress(selected._id)
            } else {
                setMobileNumber()
                form.setFieldsValue({
                    mobile: undefined, address_name: undefined,
                    address: undefined,
                    landmark: undefined,
                    name: undefined,
                })
            }
        } else {
            setMobileNumber()
            form.setFieldsValue({
                mobile: undefined, address_name: undefined,
                address: undefined,
                landmark: undefined,
                name: undefined,
            })
        }
    }, [selected])

    const dummyDeliveryTypes = [
        { name: "Any", ar_name: "أي نوع", _id: "Any" },
        { name: "Car", ar_name: "سيارة", _id: "Car" },
        { name: "Motorcycle", ar_name: "دراجة نارية", _id: "Motorcycle" },
        // { name: "Electric Bicycle", ar_name: "دراجة كهربائية", _id: "Electric Bicycle" },
        // { name: "Other", ar_name: "آخر", _id: "Other" },
    ];


    const handleLocationChange = (value) => {
        console.log(value, "value");
        // setLocation({
        //     map_address: value.map_address,
        //     latitude: value.lat,
        //     longitude: value.lng,
        // });
    };

    const calculateTax = (total_amount) => {
        if (!userProfile) return 0
        const tax = ((userProfile.restaurant_id.tax / 100) * total_amount)
        setTax(tax)
        return tax
    }


    useEffect(() => {
        if (!debouncedSearchText) {
            return
            // return setUsers([])
        }
        getUsers(debouncedSearchText)
    }, [debouncedSearchText])

    useEffect(() => {
        form.setFieldsValue({
            address_name: 'طلب كابتن',
            address: 'طلب كابتن',
            landmark: 'طلب كابتن',
        })
    }, [])

    return (
        <Form
            initialValues={{
                have_whatsapp_sec: false,
                have_whatsapp: false,
                is_free_delivery: false,
                vehicle_type: 'Any',
                address_name: 'طلب كابتن',
                address: 'طلب كابتن',
                landmark: 'طلب كابتن',
            }}
            id="create"
            form={form}
            onFinish={onCreate}
            layout="vertical"
            className="row-col"
        >
            <Row>
                <Col span={24}>
                    <div className="d-flex align-items-center gap-3  mt-2 mb-2">
                        <Button loading={loading} className="primary_btn btnStyle " htmlType="submit">
                            {lang("SUBMIT")}
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>

                <Col span={24} lg={12} xs={24} className="d-flex flex-column gap-3">
                    <Card>
                        <Row gutter={24} >

                            {Palestine.id == country && (
                                <SelectInput
                                    name="area_id"
                                    label={lang("Area")}
                                    placeholder={lang("Select Area")}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                                        0
                                    }
                                    options={areas}
                                    cover={{ md: 12 }}
                                    sm={24}
                                    rules={[
                                        {
                                            required: true,
                                            message: lang("Missing Type Selection"),
                                        },
                                    ]}
                                    handleChange={(value) => {
                                        getDeliveryFee(value)
                                    }}
                                />
                            )}

                            <Col span={24} md={12}>
                                <Form.Item
                                    className="m-0"
                                    name="total_amount"
                                    label={`${lang("Order Total")} (${currency})`}
                                    placeholder={lang("Enter Order Total")}
                                    rules={[
                                        {
                                            required: true,
                                            message: lang("Missing Order Total"),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        placeholder={lang("Enter Order Total")}
                                        onChange={calculateTax}
                                    />

                                </Form.Item>
                                {
                                    tax > 0 ?
                                        <div className="text-right me-3">
                                            {lang(`Tax`)} :  <Currency price={tax} />
                                        </div> : null
                                }

                            </Col>

                            {delivery_fee && <>
                                <Col span={24} md={12}>
                                    <Form.Item
                                        name="delivery_fee"
                                        label={lang("Delivery Fee")}
                                    >
                                        <Currency value={delivery_fee} price={delivery_fee} />
                                    </Form.Item>
                                </Col>

                                <Col span={24} md={10}>
                                    <Form.Item
                                        name="is_free_delivery"
                                        label={lang("Is Free Delivery?")}
                                        valuePropName="checked"
                                        initialValue={false}
                                    >
                                        <Checkbox onChange={(e) => setFreeDelivery(e.target.checked)} >
                                            {is_free_delivery ? <div>**{lang('Vendor to be charged the delivery fee.')}</div> : null}
                                        </Checkbox>
                                    </Form.Item>

                                </Col>

                            </>}
                        </Row>
                    </Card>

                    <Card>
                        <Row gutter={[16, 16]}>
                            <SelectInput
                                className="restaurant-selected"
                                // mode="multiple"
                                name="vehicle_type"
                                label={lang("Driver Vehicle Type")}
                                placeholder={lang("Select Vehicle Type")}
                                options={dummyDeliveryTypes}
                                cover={{ md: 10 }}
                                rules={[

                                    { required: true, message: lang("Missing Type Selection") },
                                ]}
                                span={24}
                            />


                        </Row>
                        <Row>

                            <Col span={24}>
                                <Form.Item
                                    label={lang("Est. Prep. Time")}
                                    name="est_time"
                                    className="auto_accept_order oreder-bar-check"
                                // valuePropName="checked"
                                >
                                    <Radio.Group >
                                        <Radio value={600} >10 {lang('mins')} </Radio>
                                        <Radio value={900} >15 {lang('mins')}</Radio>
                                        <Radio value={1200} >20 {lang('mins')}</Radio>
                                        <Radio value={1500} >25 {lang('mins')}</Radio>
                                        <Radio value={1800} >30 {lang('mins')}</Radio>
                                        <Radio value={2100} >35 {lang('mins')}</Radio>
                                        <Radio value={2400} >40 {lang('mins')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            {/* <Col span={24}>
                                <Form.Item
                                    label={lang("Payment paid by customer")}
                                    name="auto_accept_order"
                                    className="auto_accept_order oreder-bar-check"
                                    valuePropName="checked"
                                >
                                    {lang("COD")}     <Switch />  {lang("Online")}
                                </Form.Item>
                            </Col> */}
                            <Col span={24}>
                                <Form.Item
                                    label={lang("Payment Method")}
                                    name="payment_mod"
                                    className="auto_accept_order oreder-bar-check"
                                    valuePropName="checked"
                                    initialValue={'cod'}
                                >
                                    {lang("COD")}   <Switch disabled={true} />  {lang("Online")}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>

                </Col>
                <Col span={24} lg={12} xs={24} sm={24}>
                    <Card className="mb-3">
                        <Row gutter={[16, 16]} className="g-4">
                            <Col md={24} span={24}>
                                <Form.Item
                                    label={lang("Search Customer")}
                                    name="name_1"
                                    valuePropName="checked"
                                    className="input-font"
                                >
                                    <AutoComplete
                                        value={selected?.name}
                                        options={users.map(item => ({
                                            value: item._id,
                                            label: `${item.name} ${item?.mobile_number ? `${" "}  (${item.country_code}) - ${item?.mobile_number}` : ''}`,
                                        }))}
                                        placeholder={lang('Enter Customer Name and Mobile Number')}
                                        onSearch={(value) => setSearchText(value)}
                                        onSelect={(value) => {
                                            console.log(value, "Selected");
                                            const find = users.find(item => item._id == value)
                                            if (find) {
                                                console.log(find, "find");
                                                setSelected(find)
                                            } else {
                                                setSelected()
                                            }
                                        }}
                                        onChange={(value) => {
                                            if (!value) {
                                                setSelected()
                                                setSearchText()
                                            }
                                        }}
                                        allowClear
                                    >
                                        {/* <Input /> */}
                                    </AutoComplete>

                                </Form.Item>
                            </Col>


                        </Row>
                        <Row gutter={[16, 16]} className="g-4">
                            <Col md={12} span={24}>
                                <Form.Item
                                    label={lang("Customer Name")}
                                    name="name"
                                    rules={[
                                        { required: true, message: lang("Please Enter Customer Name") },
                                    ]}
                                    // valuePropName="checked"
                                    className="input-font"
                                >
                                    <Input disabled={selected} placeholder={lang('Enter Customer Name')} />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={12}>
                                <PhoneNumberField
                                    inputProps={{
                                        disabled: selected?.country_code ? true : false,
                                    }}
                                    label={lang("Customer Phone Number")}
                                    name="mobile"
                                    placeholder={lang("Enter Phone Number")}
                                    cover={{ md: 24 }}
                                    className="input-font"
                                    onChange={handleMobileNumberChange}
                                    rules={[{
                                        required: true,
                                        message: lang("Please Enter Phone Number"),
                                    }]}
                                />

                                <Form.Item name="have_whatsapp" valuePropName="checked">
                                    <Checkbox className="">
                                        {lang("This number have WhatsApp to receive messages?")}
                                    </Checkbox>
                                </Form.Item>
                            </Col>

                        </Row>
                    </Card>


                    <Card>

                        <Row gutter={20}>
                            {
                                selected?.country_code ?
                                    <Col span={24} md={24} sm={24} >
                                        <Form.Item
                                            label={lang("Select Address")}
                                            name="address_id"
                                            className="input-font"
                                            allowClear
                                        >
                                            <Select
                                                allowClear
                                                maxCount={1}
                                                placeholder={lang("Search Select Address")}
                                                onSelect={(value) => {
                                                    console.log(value, "Selected");
                                                }}
                                                onChange={(value) => {
                                                    console.log(value, "Customer");
                                                }}
                                            >
                                                {address.map((item) => (
                                                    <Select.Option
                                                        key={item._id}
                                                        label={item.name}
                                                        value={item._id}
                                                    >
                                                        {language !== "en" && language !== null ? item.area_id[`${language}_name`] ?? item.area_id.name : item.area_id.name} -
                                                        {item.name} -  {item.address} - {item.landmark}
                                                    </Select.Option>
                                                ))}
                                            </Select>


                                        </Form.Item>
                                    </Col> : null
                            }
                            <TextInputBox
                                name="address_name"
                                label={lang("Building Name")}
                                placeholder={lang("Enter Building Name")}
                                isDisable={address_id != undefined ? true : false}
                                rules={[
                                    {
                                        max: 600,
                                        message: lang(
                                            "Building Name should contain more then 600 characters!",
                                        ),
                                    },
                                    {
                                        required: true,
                                        message: lang("Please Enter Building Name"),
                                    },
                                ]}
                            />
                            <TextInputBox
                                name="address"
                                label={lang("Enter Floor / Flat")}
                                isDisable={address_id != undefined ? true : false}
                                placeholder={lang("Enter Floor / Flat")}
                                rules={[
                                    {
                                        max: 600,
                                        message: lang(
                                            "Floor / Flat should contain more then 600 characters!",
                                        ),
                                    },
                                    {
                                        required: true,
                                        message: lang("Please Enter Floor/Flat"),
                                    },
                                ]}
                            />
                            <TextInputBox
                                name="landmark"
                                label={lang("Landmark")}
                                isDisable={address_id != undefined ? true : false}
                                placeholder={lang("Landmark")}
                                rules={[
                                    {
                                        max: 600,
                                        message: lang(
                                            "Landmark should contain more then 600 characters!",
                                        ),
                                    },
                                    {
                                        required: true,
                                        message: lang("Please Enter Landmark"),
                                    },
                                ]}
                            />
                        </Row>
                    </Card>

                </Col>

            </Row>
            <Row>
                <Col span={24} lg={10} xs={24} sm={24}>
                    <Row className="" gutter={[16, 16]}>
                        <Col md={24} sm={24} span={24} className="mt-2">
                            <Form.Item
                                label={lang("Location (Drag Marker for Selecting Location)")}
                                name="location_on_map"
                                rules={[
                                    {
                                        required: true,
                                        message: lang("Please select the location!"),
                                    },
                                ]}
                            >
                                <LocationMap
                                    className="mt-3"
                                    onChange={handleLocationChange}
                                    userData={location}
                                    is_current={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Form>

    );
};

export default DriverRequest;


