import axios from 'axios'
import { Severty, ShowToast } from './toast';
import useRequest from '../hooks/useRequest';
import apiPath from '../constants/apiPath';
import lang from './langHelper';

const checkNumber = ({ number }) => {

    console.log("checkNumber", {
        to: `whatsapp:`,
        number
    })


    return new Promise(async (resolve, reject) => {
        try { 
            if(!number)   resolve(true)
            let token = localStorage.getItem("token")  ? localStorage.getItem("token") : "";
            const whatsappApiUrl = `${apiPath.baseURL}check-number/+${number}`; 
            let lang = localStorage.getItem("languageSet");
            lang = lang ? lang : "en";
            const response = await axios.get(
                whatsappApiUrl,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        language: lang
                    },
                }
            );

            const contact = response.data;
            console.log(contact, "contact");

            if (contact.status) {
                resolve(true)
                return true
            } else {
                ShowToast(contact.message, Severty.ERROR)
                resolve(false)
                return false
            }

        } catch (error) {
            ShowToast(lang('Opps.. Please check your WhatsApp number. In Palestine, please check your country code registered on WhatsApp 970 or 972 and try again'), Severty.ERROR)
            console.log(`Failed to check WhatsApp number: ${error}`);
            resolve(false)
            return false
        }
    })


}

export default checkNumber