import React, { useState, useEffect } from "react";
import { Input } from "antd";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import lang from "../../helper/langHelper";
import axios from "axios";
import Gps from "../../assets/images/gps.png"
const libraries = ["drawing", "places"];

const LocationMap = ({ onChange, userData, is_current = true }) => {
  const [userAddress, setUserAddress] = useState(null);
  const [map, setMap] = useState(null);
  const [coordinates, setCoordinates] = useState({
    lat: 30.5595,
    lng: 22.9375,
  });

  const handleLocationSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const { lat, lng } = await getLatLng(results[0]);
      setCoordinates({ lat, lng });
      setUserAddress(selectedAddress);
      if (onChange) {
        onChange({ map_address: selectedAddress, latitude: lat, longitude: lng });
      }
    } catch (error) {
      console.error("Error while selecting location:", error);
    }
  };

  const geocodeByLatLng = async (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          resolve(results[0].formatted_address);
        } else {
          reject(new Error("Geocoder request failed."));
        }
      });
    });
  };

  const handleMarkerDrag = async (event) => {
    try {
      const { latLng } = event;
     
      const lat = latLng.lat();
      const lng = latLng.lng(); 
      console.log(latLng,"latLng",lat,lng);
      setCoordinates({ lat, lng });
      const results = await geocodeByLatLng(lat, lng);
      if (results.length > 0) {
        const selectedAddress = results;
        setUserAddress(selectedAddress);
        if (onChange) {
          onChange({ map_address: selectedAddress, latitude: lat, longitude: lng });
        }
      }
    } catch (error) {
      console.error("Error while dragging marker:", error);
    }
  };

  useEffect(() => {
    console.log(userData, "userData");
    if (userData) {
      setUserAddress(userData.map_address);
      setCoordinates({
        ...coordinates,
        lat: parseFloat(userData.latitude),
        lng: parseFloat(userData.longitude),
      });
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          setCoordinates({
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude),
          });
        });
      }
    }
  }, [userData]);

  const { isLoaded } = useJsApiLoader({
    // googleMapsApiKey: "AIzaSyDQ2DCe8qS4qVCkMtRZRnZZ_TF2qq1HSvs",
    googleMapsApiKey: "AIzaSyAz3PprwaKTQW55OcKOgoW8v_8QqO3w6S8", // new keyfor tawasi
    libraries,
  });

  if (!isLoaded) {
    return <div className="text-center">Loading Map</div>;
  }

  return (
    <>
      <PlacesAutocomplete
        value={userAddress}
        onChange={setUserAddress}
        onSelect={handleLocationSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <Input {...getInputProps({ placeholder: lang("Search Location") })} />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Please Wait...</div>}

              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    {" "}
                    <span>{suggestion.description}</span>{" "}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </PlacesAutocomplete>

      {is_current ?
        <LocationButton map={map} className="mt-3" onDetect={(data) => {
          setUserAddress(data.address);
          setCoordinates(prev => ({
            ...prev,
            lat: (data.lat),
            lng: (data.lng),
          }));
        }} /> : <div className="mt-3"></div>}

      <GoogleMap
        center={coordinates}
        zoom={14}
        mapContainerClassName="googleMap mt-1"
        onLoad={(map) => setMap(map)}
      >
        <Marker
          position={coordinates}
          draggable={true}
          onDragEnd={handleMarkerDrag}
        />

      </GoogleMap>

    </>
  );
};

export default LocationMap;

const LocationButton = ({ map, onDetect }) => {
  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: 'YOUR_GOOGLE_MAPS_API_KEY',
  // });
  const getDetail = async ({ lat, lng }) => {
    try {

      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAz3PprwaKTQW55OcKOgoW8v_8QqO3w6S8`;

      let response = await axios.get(apiUrl);

      const postalCodeObj = response?.data?.results[0]?.address_components.find(component => component.types.includes('postal_code'));
      const postalCode = postalCodeObj ? postalCodeObj.long_name : null;

      const countryObj = response?.data?.results[0]?.address_components.find(component => component.types.includes('country'));
      const country = countryObj ? countryObj.short_name : null;

      console.log(response?.data?.results[0]?.formatted_address, "response?.data?.results[0]?.address_components");
      return {
        address: response?.data?.results[0]?.formatted_address ?? 'Nablus',
        country,
        postalCode
      };
    } catch (err) {
      console.log(err);
      return {};
    }
  }

  const handleLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const newCenter = { lat: latitude, lng: longitude };

          const response = await getDetail(newCenter)
          console.log(newCenter, "newCenter");
          map.panTo(newCenter);
          if (onDetect) {
            onDetect({ address: response.address, ...newCenter })
          }
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  return (
    <div onClick={handleLocation}
      className="ant-btn ant-btn-default primary_btn btnStyle location-btn mt-4"
    >
      <img className="me-1" width={20} src={Gps} />
      {lang('Get Current Location')}</div>
  )
};
