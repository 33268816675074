import {
  Col,
  DatePicker,
  Form,
  Image,
  InputNumber,
  Modal,
  Radio,
  Row,
  Steps,
} from "antd";
import React, { useEffect, useState } from "react";

import notfound from "../../assets/images/not_found.png";
import {
  MultiSelect,
  SelectInput,
  TextInputBox,
} from "../../components/InputField";
import SingleImageUpload from "../../components/SingleImageUpload";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";

const { Step } = Steps;

export const DiscountTypes = {
  DISH: "Dish",
  VENDOR: "Vendor",
  ALLVENDOR: "AllVendor",
};

export const DISCOUNT_AMOUNT_TYPES = [
  {
    name: "Percentage",
    _id: "percentage",
  },
  {
    name: "Flat",
    _id: "flat",
  },
];

const foodCategoryList = [
  { name: "Pizza", _id: "Pizza" },
  { name: "burger", _id: "burger" },
  { name: "italian", _id: "italian" },
];

const AddDiscountForm = ({ section, api, show, hide, data, refresh }) => {
  const thisApi = {
    fc: apiPath.common.foodCategories,
    items: apiPath.common.foodItems,
  };

  const [currentStep, setCurrentStep] = useState(0);

  const [form] = Form.useForm();
  const { request } = useRequest();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);

  const [foodCategories, setFoodCategories] = useState([]);
  const [foodItems, setFoodItems] = useState([]);

  const [formData, setFormData] = useState();

  const [discountFor, setDiscountFor] = useState(DiscountTypes.DISH);

  const [discountAmountType, setDiscountAmountType] = useState(
    DISCOUNT_AMOUNT_TYPES[0]._id,
  );

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleDiscountForTypeChange = (e) => {
    console.log(e);
    setDiscountFor(e?.target?.value);
  };

  const handleDiscountAmountTypeChange = (value) => {
    setDiscountAmountType(value);
  };

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const handleImage = (data) => {
    if (data && data.length > 0) {
      setImage(data[0].url);
      console.log(data);
      data.length > 0 ? setFile(data[0].url) : setFile([]);
    }
  };

  const getFoodCategory = () => {
    request({
      url: thisApi.fc,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setFoodCategories(foodCategoryList); // TODO: change dummy
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getFoodItems = () => {
    const categoryId = form.getFieldValue("category_id");
    // TODO: add condition to if selected category_id, then get the list accordingly
    request({
      url: thisApi.items,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setFoodItems(foodCategoryList); // TODO: change dummy
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const onStepSubmit = (values) => {
    setFormData({ ...formData, ...values });
    nextStep();
    if (currentStep === currentStep.length - 1) {
      // const { name, ar_name, status } = values;
      // console.log(values, "values");
      // const payload = {};
      // setLoading(true);
      // payload.name = name;
      // payload.ar_name = ar_name;
      // payload.is_active = status;
      // payload.image = image && image.length > 0 ? image[0].url : "";
      // console.log(payload, "hfdjhjkhgjkfhgjkfhg");
      // request({
      //   url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
      //   method: data ? "PUT" : "POST",
      //   data: payload,
      //   onSuccess: (data) => {
      //     setLoading(false);
      //     if (data.status) {
      //       ShowToast(data.message, Severty.SUCCESS);
      //       hide();
      //       refresh();
      //     } else {
      //       ShowToast(data.message, Severty.ERROR);
      //     }
      //   },
      //   onError: (error) => {
      //     ShowToast(error.response.data.message, Severty.ERROR);
      //     setLoading(false);
      //   },
      // });
    }
  };

  const steps = [
    {
      content: (
        <Form id="create" form={form} onFinish={onStepSubmit} layout="vertical">
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <Form.Item label="Upload Thumbnail" name="thumbnail">
                <div className="mb-1"></div>
                <SingleImageUpload
                  fileType={FileType}
                  imageType={"blog"}
                  btnName={"Thumbnail"}
                  onChange={(data) => handleImage(data)}
                />
                {image && image.length > 0 && (
                  <div className="mt-2">
                    <Image
                      width={120}
                      src={image !== "" ? image : notfound}
                    ></Image>
                  </div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <TextInputBox
              label="Discount Name"
              name="name"
              placeholder="Enter Discount Name"
              rules={[
                {
                  max: 250,
                  message: "Name should contain more then 250 characters!",
                },
                {
                  required: true,
                  message: "Please Enter Restaurant Name",
                },
              ]}
            />
            <TextInputBox
              label="Discount Name Arabic"
              name="ar_name"
              placeholder="Enter Discount Name"
              rules={[
                {
                  max: 250,
                  message: "Name should contain more then 250 characters!",
                },
                {
                  required: true,
                  message: "Please Enter Restaurant Name",
                },
              ]}
            />
          </Row>
        </Form>
      ),
    },
    {
      content: (
        <Form id="create" form={form} onFinish={onStepSubmit} layout="vertical">
          <Row>
            <SelectInput
              name="city_id"
              label="Select City"
              placeholder="Select City"
              cover={{ md: 24 }}
              options={foodCategories}
              rules={[{ required: true, message: "Missing Type Selection" }]}
            />
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="Select Discount For" name="discount_for">
                <Radio.Group>
                  <Radio
                    value={DiscountTypes.DISH}
                    defaultChecked={discountFor === DiscountTypes.DISH}
                    onChange={handleDiscountForTypeChange}
                  >
                    {DiscountTypes.DISH}
                  </Radio>
                  <Radio
                    value={DiscountTypes.VENDOR}
                    defaultChecked={discountFor === DiscountTypes.VENDOR}
                    onChange={handleDiscountForTypeChange}
                  >
                    {DiscountTypes.VENDOR}
                  </Radio>
                  <Radio
                    value={DiscountTypes.ALLVENDOR}
                    defaultChecked={discountFor === DiscountTypes.ALLVENDOR}
                    onChange={handleDiscountForTypeChange}
                  >
                    {DiscountTypes.ALLVENDOR}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {discountFor === DiscountTypes.VENDOR && (
            <Row>
              <MultiSelect
                name="vendor_id"
                label="Select Vendors"
                cover={{ md: 24 }}
                placeholder="Select Vendorsy"
                options={foodCategories}
                rules={[{ required: true, message: "Missing Type Selection" }]}
              />
            </Row>
          )}
          {discountFor === DiscountTypes.DISH && (
            <>
              <SelectInput
                name="category_id"
                label="Choose Food Category"
                placeholder="Select Food Category"
                cover={{ md: 24 }}
                options={foodCategories}
                rules={[{ required: true, message: "Missing Type Selection" }]}
              />
              <SelectInput
                name="food_id"
                label="Choose Food Item"
                placeholder="Select Food Item"
                cover={{ md: 24 }}
                options={foodCategories}
                rules={[{ required: true, message: "Missing Type Selection" }]}
              />
            </>
          )}
        </Form>
      ),
    },
    {
      content: (
        <Form id="create" form={form} onFinish={onStepSubmit} layout="vertical">
          <Row>
            <Col span={24}>
              <Row>
                <TextInputBox
                  label="Coupon Code"
                  name="code"
                  placeholder="Enter Coupon Code"
                  cover={{ md: 24 }}
                  rules={[
                    {
                      max: 7,
                      message: "Name should contain more then 250 characters!",
                    },
                    {
                      required: true,
                      message: "Please Enter Coupon Code",
                    },
                  ]}
                  inputProps={{
                    maxLength: 7,
                  }}
                />

                {/*
                 // user type 
                 <SelectInput
                  name="user_type"
                  label="Select User Type"
                  placeholder="Select User Type"
                  cover={{ md: 24 }}
                  options={DISCOUNT_AMOUNT_TYPES}
                  rules={[
                    { required: true, message: "Missing Type Selection" },
                  ]}
                /> */}
              </Row>

              <SelectInput
                name="discount_type"
                label="Select Discount Type"
                placeholder="Select Discount Type"
                cover={{ md: 24 }}
                options={DISCOUNT_AMOUNT_TYPES}
                rules={[{ required: true, message: "Missing Type Selection" }]}
                defaultValue={discountAmountType}
                handleChange={handleDiscountAmountTypeChange}
              />

              <Col md={12}>
                <Form.Item
                  name="amount"
                  label="Discount Percentage"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Discount Percentage",
                    },
                  ]}
                >
                  <InputNumber
                    maxLength={2}
                    max={100}
                    placeholder="10 %"
                    disabled={
                      discountAmountType === DISCOUNT_AMOUNT_TYPES[1]._id
                    }
                  />
                </Form.Item>
              </Col>

              <Col md={12}>
                <Form.Item
                  name="amount"
                  label="Fix Amount"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Fix Amount",
                    },
                  ]}
                >
                  <InputNumber
                    maxLength={10}
                    placeholder="Enter Fix Amount"
                    disabled={
                      discountAmountType === DISCOUNT_AMOUNT_TYPES[0]._id
                    }
                  />
                </Form.Item>
              </Col>

              <Row>
                <Col md={12}>
                  <Form.Item
                    name="admin_percentage"
                    label="Admin Discount Percentage"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Admin Discount Percentage",
                      },
                    ]}
                  >
                    <InputNumber maxLength={2} max={100} placeholder="10 %" />
                  </Form.Item>
                </Col>

                <Col md={12}>
                  <Form.Item
                    name="vendor_percentage"
                    label="Vendor Discount Percentage"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Vendor Discount Percentage",
                      },
                    ]}
                  >
                    <InputNumber maxLength={2} max={100} placeholder="10 %" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="Start Date"
                    name="start_date"
                    rules={[
                      {
                        required: true,
                        message: "Please select the start date",
                      },
                    ]}
                  >
                    <DatePicker placeholder="Select Start Date" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="End Date"
                    name="end_date"
                    rules={[
                      {
                        required: true,
                        message: "Please select the end date",
                      },
                    ]}
                  >
                    <DatePicker placeholder="Select End Date" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Form.Item
                    name="max_discount"
                    label="Enter Max Discount"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Max Discount Amount",
                      },
                    ]}
                  >
                    <InputNumber maxLength={10} placeholder="10 %" />
                  </Form.Item>
                </Col>

                <Col md={12}>
                  <Form.Item
                    name="max_users"
                    label="Enter Max Users"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Max Users",
                      },
                    ]}
                  >
                    <InputNumber maxLength={10} placeholder="Enter Max Users" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <TextInputBox
                  label="Coupon Code"
                  name="code"
                  placeholder="Enter Coupon Code"
                  cover={{ md: 24 }}
                  rules={[
                    {
                      max: 7,
                      message: "Name should contain more then 250 characters!",
                    },
                    {
                      required: true,
                      message: "Please Enter Coupon Code",
                    },
                  ]}
                  inputProps={{
                    maxLength: 7,
                  }}
                />

                {/*
                 // user type 
                 <SelectInput
                  name="user_type"
                  label="Select User Type"
                  placeholder="Select User Type"
                  cover={{ md: 24 }}
                  options={DISCOUNT_AMOUNT_TYPES}
                  rules={[
                    { required: true, message: "Missing Type Selection" },
                  ]}
                /> */}
              </Row>
              <Row>
                <TextInputBox
                  label="Custom Message"
                  name="description"
                  placeholder="Enter Custom Message"
                  rules={[
                    {
                      max: 250,
                      message:
                        "Message should contain more then 250 characters!",
                    },
                    {
                      required: true,
                      message: "Please Enter Message",
                    },
                  ]}
                />
                <TextInputBox
                  label="Custom Message Arabic"
                  name="ar_description"
                  placeholder="Enter Custom Message"
                  rules={[
                    {
                      max: 250,
                      message:
                        "Message should contain more then 250 characters!",
                    },
                    {
                      required: true,
                      message: "Please Enter Message",
                    },
                  ]}
                />
              </Row>
            </Col>
          </Row>
        </Form>
      ),
    },
  ];

  useEffect(() => {
    console.log(formData, "form data");
  }, [formData]);

  useEffect(() => {
    if (!data) return;
    console.log(data);
    form.setFieldsValue({ ...data });
    setFile([data.image]);
  }, [data]);

  useEffect(() => {
    if (currentStep === 0) {
      getFoodCategory();
      getFoodItems();
    }
  }, [currentStep]);

  return (
    <Modal
      visible={show}
      width={750}
      okText={currentStep === steps.length - 1 ? "Add" + " " + section : "Next"}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        loading: loading,
        htmlType: "submit",
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <h4 className="modal_title_cls">Add New {section}</h4>

      <Steps current={currentStep}>
        {steps.map((step) => (
          <Step key={step.title} title={step.title} />
        ))}
      </Steps>

      <div style={{ marginTop: "20px" }}>
        <div>{steps[currentStep]?.content}</div>
      </div>
    </Modal>
  );
};

export default AddDiscountForm;
