import {
    Button,
    Col,
    Layout,
    Row,
    Typography,
    Skeleton
} from "antd";
import React, { useEffect, useState } from "react";

import useRequest from "../../hooks/useRequest";
import { Container } from "react-bootstrap";

const { Title } = Typography;
const { Content } = Layout;

const Terms = () => {

    const { request } = useRequest()
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)

    const getData = () => {
        request({
            url: '/app/terms-and-conditions-restaurant',
            method: "GET",
            onSuccess: (res) => {
                setLoading(false)
                const { data } = res
                if (data.length) {
                    setData(data[0])
                }

            },
        });
    };

    useEffect(() => {
        getData()
    }, []);

    const closeTab = () => {
        window.opener = null;
        window.open("", "_self");
        window.close();
    };

    return (
        <>
            <Layout className="layout-default layout-signin">
                <Content className="signin">
                    <Container>
                        <h5 className="wlt-terms">Contract Terms and Conditions</h5>
                       {loading ? [1,2,3,4,5,6,12,8,9].map(item=><Skeleton active key={item}/>) : 
                        <>
                            <Row>

                                <Col span={24} xl={12}>
                                    <div className="terms-left-outer" dangerouslySetInnerHTML={{ __html: data ? data.description : '' }} />
                                </Col>
                                <Col span={24} xl={12}>
                                    <div className="terms-right-outer" dangerouslySetInnerHTML={{ __html: data ? data.ar_description : '' }} />
                                </Col>
                            </Row>
                            <Button className="primary_btn btnStyle" onClick={closeTab}>
                                okay
                            </Button>
                        </>
                        }
                    </Container>
                </Content>

            </Layout>
        </>
    );
};

export default Terms;
