import { Button, Image, Select, Switch, Table, Tooltip, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import notfound from "../../assets/images/not_found.png";
import Plus from "../../assets/images/plus.svg";
import ConfirmationBox from "../../components/ConfirmationBox";
import DeleteModal from "../../components/DeleteModal";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import AddFoodItemForm from "./_AddFoodItemForm";
import { AuthContext } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";

export const IngredientTypes = {
  VEG: "vegetarian",
  NONVEG: "non-vegetarian",
};

const FoodItems = ({ section,selectedTab }) => {
  const sectionName = "Food Item";
  const routeName = "food";
  const params = useParams();

  const { currency } = useContext(AuthContext);

  const api = {
    food: apiPath.food,
    foodCategory: apiPath.listCategory,
    category: apiPath.common.ingredientCategories,
    size: apiPath.common.foodSize,
    choice: apiPath.common.choices,
    addOns: apiPath.common.ingredients,
    addOnCategory: apiPath.common.ingredientCategories,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();

  const [list, setList] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);

  const [deleteModal, showDeleteModal] = useState(false);
  const [selected, setSelected] = useState();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const debouncedSearchText = useDebounce(searchText, 300);

  const handleCategoryFilter = (value) => {
    fetchData(pagination, { category_id: value });
  };

  const handleChangeStatus = (id) => {
    request({
      url: api.food + "/" + id + "/status",
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        fetchData(pagination);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onDelete = (id) => {
    request({
      url: api.food + "/" + id,
      method: "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          fetchData(pagination);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const getCategories = () => {
    request({
      url: api.foodCategory + "/all",
      method: "GET",
      onSuccess: (data) => {
        setCategories(data.data);
      },
    });
  };

  const fetchData = (pagination, filters) => {
    let filter = {};

    const filterActive = filters ? filters.is_active : null;

    if (!!filters?.category_id) {
      filter.category = filters.category_id;
    }

    request({
      url:
        api.food +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${
          pagination ? pagination.current : 1
        }&pageSize=${
          pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}&category_id=${
          filter?.category ? filter.category : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status, total }) => {
        setLoading(false);
        setList(data);
        setPagination((prev) => ({
          ...prev,
          current: pagination.current,
          total: total,
        }));
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);

        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const { language } = useAppContext();

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    setPagination({ current: 1 });
  };

  const columns = [
    {
      title: lang("S. No"),
      dataIndex: "index",
      key: "index",
      render: (value, item, index) =>
        pagination.current === 1
          ? index + 1
          : (pagination.current - 1) * 10 + (index + 1),
    },
    {
      title: lang("Item Name"),
      dataIndex: "name",
      key: "name",
      render: (_, { name }) => {
        return name ? <span className="cap">{name}</span> : "-";
      },
    },
    {
      title: lang("Item Arabic Name"),
      dataIndex: "ar_name",
      key: "ar_name",
      render: (_, { ar_name }) => {
        return ar_name ? <span className="cap">{ar_name}</span> : "-";
      },
    },
    {
      title: lang("Price"),
      dataIndex: "size",
      key: "size",
      render: (_, { size, contain_size }) => {
        return size ? (
          <div style={{ display: "flex", gap: "20px" }}>
            {contain_size ? (
              <>
                {size.map((item, index) => {
                  return (
                    <div key={index}>
                      {/* <p>{item?.size_id?.name}</p>  */}
                      <p>
                        {language !== ("en" || null)
                          ? item?.size_id?.[`${language}_name`] ??
                            item?.size_id?.name
                          : item?.size_id?.name}
                      </p>
                      <p> {currency + " " + item.price}</p>
                    </div>
                  );
                })}
              </>
            ) : (
              <p>{currency + " " + size[0]?.price}</p>
            )}
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: lang("Image"),
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => (
        <Image src={image ? image : notfound} className="table-img" />
      ),
    },
    {
      title: lang("Action"),
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            <Tooltip
              title={lang("Edit")}
              color={"purple"}
              key={"edit" + routeName}
            >
              <Button
                title={lang("Edit")}
                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  setSelected(record);
                  setVisible(true);
                }}
              >
                <i className="fa fa-light fa-pen"></i>
                <span>{lang("Edit")}</span>
              </Button>
            </Tooltip>

            <Tooltip title={lang("Delete")} color={"purple"} key={"Delete"}>
              <Button
                title={lang("Delete")}
                className="btnStyle deleteDangerbtn"
                onClick={() => {
                  if (record.have_discount) {
                    return setShowMessage(true);
                  }
                  setSelected(record);
                  showDeleteModal(true);
                }}
              >
                <img src={deleteWhiteIcon} />
                <span>{lang("Delete")}</span>
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: lang("Status"),
      key: "is_active",
      dataIndex: "is_active",
      render: (_, { _id, is_active }) => {
        return (
          <Switch
            onChange={() => {
              handleChangeStatus(_id);
            }}
            checked={is_active}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
    getCategories();
  }, [refresh, debouncedSearchText,selectedTab]);

  return (
    <>
      <SectionWrapper
        cardHeading={lang("Items")}
        extra={
          <>
            <div>
              <Select
                placeholder={lang("All Category")}
                onChange={handleCategoryFilter}
              >
                {categories && categories && categories.length > 0
                  ? categories.map((item, index) => (
                      <Select.Option
                        key={item._id}
                        value={item._id}
                        label={item.name}
                        onChange={handleCategoryFilter}
                      >
                        <span className="cap">
                          {/* {
                        item.name
                        }  */}
                          {language !== ("en" || null)
                            ? item[`${language}_name`] ?? item?.name
                            : item?.name}
                        </span>
                      </Select.Option>
                    ))
                  : null}
              </Select>
            </div>
            <div className="pageHeadingSearch w-100 d-flex align-items-baseline text-head_right_cont">
              <Input.Search
                className="searchInput"
                placeholder={lang("Search by Item Name")}
                onChange={onSearch}
                allowClear
              />
            </div>
            <div className="w-100 text-head_right_cont">
              <Button
                className="primary_btn btnStyle"
                onClick={(e) => {
                  setVisible(true);
                  setSearchText("");
                }}
              >
                <span className="add-Ic">
                  <img src={Plus} />
                </span>
                {lang("Add New Item")}
              </Button>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination checkBoxSrNo">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {visible && (
        <AddFoodItemForm
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
          currency={currency}
        />
      )}

      {deleteModal && (
        <DeleteModal
          title={lang("Delete Food Item")}
          subtitle={lang("Are you sure you want to Delete this food item?")}
          show={deleteModal}
          hide={() => {
            showDeleteModal(false);
            setSelected();
          }}
          onOk={() => onDelete(selected?._id)}
        />
      )}

      {showMessage && (
        <DeleteModal
          title={"Delete Food Item"}
          subtitle={lang(
            "This food contains discounts, please delete discounts before deleting food item"
          )}
          show={showMessage}
          hide={() => {
            setShowMessage(false);
          }}
          onOk={() => setShowMessage(false)}
        />
      )}
    </>
  );
};

export default FoodItems;
