import {
    Card,
    Input,
    Typography,
    Progress,
    Row,
    Skeleton,
    Radio,
    Tabs
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
const Search = Input.Search;
const { Title, Text } = Typography;
const {TabPane} = Tabs

function RevenueCard() {
  
    const api = {
        status: apiPath.statusQuote,
        list: apiPath.listQuote,
    };
  
    const { request } = useRequest();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetchData({ current: 1 });
    }, [refresh]);

    const fetchData = () => {
        setLoading(true);
        const payload = {};
    
        request({
            url: api.list,
            method: "POST",
            data: payload,
            onSuccess: (data) => {
                setLoading(false);
                setList(data.data.list.docs);
            },
            onError: (error) => {
                setLoading(false);
                ShowToast(error, Severty.ERROR);
            },
        });
    };

    return (
        <Card bordered={false} className="criclebox h-full">
              <Title level={5}>Revenue Report</Title>
              <Row style={{ justifyContent: "end" }}>
                <Radio.Group defaultValue="month" buttonStyle="solid">
                  <Radio.Button onClick={() => null} value="week">Week</Radio.Button>
                  <Radio.Button onClick={() => null} value="month">Month</Radio.Button>
                  <Radio.Button onClick={() => null} value="year">Year</Radio.Button>
                </Radio.Group>
              </Row>
              <Tabs
                className="main_tabs"
                onTabClick={() => null}
                //activeKey={'country'}
                tabBarStyle={{ color: "green" }}
              >
                <TabPane tab="24K country" key="country">
                  {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) : <>
                    <Progress percent={25} />
                    <Progress percent={16} status="active" />
                    <Progress percent={13} status="exception" />
                    <Progress percent={11} />
                  </>}
                </TabPane>
                <TabPane tab="2k CITY" key="city">
                  {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) : <>
                    <Progress percent={30} />
                    <Progress percent={50} status="active" />
                    <Progress percent={70} status="exception" />
                    <Progress percent={12} />
                   
                  </>}
                </TabPane> 

                <TabPane tab="3.1k Area" key="area">
                  {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) : <>
                    <Progress percent={20} />
                    <Progress percent={14}  />
                    <Progress percent={23} />
                    <Progress percent={42} />
              
                  </>}
                </TabPane>

              </Tabs>

            </Card>
    );
}


export default RevenueCard;
