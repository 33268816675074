import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Radio, Row, Space } from "antd";
import React, { useEffect, useState } from "react";

import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";

const AddFoodChoice = ({ section, api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);

  const onCreate = (values) => {
    // return console.log(values, "-------------------values");
    const { name, ar_name, options, is_active } = values;
    console.log(values, "values");
    const payload = {};
    setLoading(true);
    payload.name = name;
    payload.ar_name = ar_name;
    payload.options = options;
    payload.is_active = is_active;

    request({
      url: `${data ? api.choice + "/" + data._id : api.choice}`,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    if (!data) return;
    console.log(data);
    form.setFieldsValue({ ...data });
  }, [data]);

  return (
    <Modal
      open={show}
      width={750}
      okText={lang("Save")}
      cancelText={lang("Cancel")}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <Form
        id="create"
        form={form}
        onFinish={onCreate}
        layout="vertical"
        initialValues={{
          is_active: true,
        }}
      >
        <h4 className="modal_title_cls">
          {(data ? lang("Edit") : lang("Add New")) + lang(" Choice")}
        </h4>
        <Row gutter={[16, 0]}>
          <Col span={24} sm={12}>
            <Form.Item
              label={lang(`Choice Name`)}
              name="name"
              rules={[
                {
                  required: true,
                  message: lang("Name is required"),
                },
                {
                  max: 100,
                  message: lang(
                    "Name should not contain more then 100 characters!",
                  ),
                },
                {
                  min: 2,
                  message: lang("Name should contain at least 2 characters!"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input
                autoComplete="off"
                placeholder={lang(`Enter Choice Name`)}
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label={lang(`Choice Name Arabic`)}
              name="ar_name"
              rules={[
                {
                  required: true,
                  message: lang("Arabic Name is required"),
                },
                {
                  max: 100,
                  message: lang(
                    "Name should not contain more then 100 characters!",
                  ),
                },
                {
                  min: 2,
                  message: lang("Name should contain at least 2 characters!"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder="أدخل اسم الاختيار" />
            </Form.Item>
          </Col>

          <Col span={24} sm={24}>
            <div className="status_wrap">
              <Form.Item label={lang("Status")} name="is_active">
                <Radio.Group style={{ font: "bold" }}>
                  <Radio value={true}>{lang("Active")}</Radio>
                  <Radio value={false}>{lang("De-Active")}</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </Col>

          <Col span={24} sm={24}>
            <Form.List
              name="options"
              className="mt-2"
              initialValue={[{ name: "", ar_name: "" }]}
            >
              {(fields, { add, remove }, { form }) => (
                <>
                  {fields.map((field_fr, index_fr) => (
                    <div key={field_fr.key}>
                      <Space
                        key={field_fr.key}
                        align="baseline"
                        className="gap-cls"
                      >
                        <Row gutter={[16, 0]}>
                          <Col span={24} sm={9}>
                            <Form.Item
                              className="qty-cls"
                              {...field_fr}
                              name={[field_fr.name, "name"]}
                              label={lang("Add Choice Option")}
                              rules={[
                                {
                                  required: true,
                                  message: lang("Please enter choice option"),
                                },
                              ]}
                              normalize={(value) => value.trimStart()}
                            >
                              <Input
                                autoComplete="off"
                                placeholder={lang(`Add Choice Option`)}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={16} sm={10} lg={9}>
                            <Form.Item
                              className="qty-cls"
                              {...field_fr}
                              name={[field_fr.name, "ar_name"]}
                              label={lang("Add Choice Option Arabic")}
                              rules={[
                                {
                                  required: true,
                                  message: lang("Please enter choice option"),
                                },
                              ]}
                              normalize={(value) => value.trimStart()}
                            >
                              <Input
                                autoComplete="off"
                                placeholder={`إضافة خيار الاختيار`}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8} sm={5} lg={6} className="mt-4">
                            <div className="add-menu-items-delete">
                              {index_fr > 0 ? (
                                <div className="minus-wrap">
                                  <DeleteOutlined
                                    className="delete-circal"
                                    onClick={() => remove(field_fr.name)}
                                    style={{ borderRadius: "50%" }}
                                  />
                                </div>
                              ) : null}
                              <Form.Item className="addon-items">
                                <Button
                                  onClick={() => add()}
                                  block
                                  className="primary_btn btnStyle"
                                >
                                  <i class="fas fa-plus me-1"></i>
                                  {lang("Add")}
                                </Button>
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </Space>
                    </div>
                  ))}
                  <Col span={12} sm={4}></Col>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddFoodChoice;
