import { Button, Card, Col, Form, Image, Input, Modal, Rate, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import apiPath from "../../constants/apiPath";
import { lang } from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { LoadingOutlined } from '@ant-design/icons';
function Index() {

  const sectionName = "Ratings And Reviews";

  const { request } = useRequest();
  const [hasMore, setHasMore] = useState(true);
  const [reviewList, setReviewList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [selectedReview, setSelectedReview] = useState();
  const [toggle, setToggle] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [view, setView] = useState({});
  const [page, setPage] = useState(1);

  const fetchData = (page) => {
    request({
      url: `${apiPath.review}?page=${page}&pageSize=10`,
      method: "GET",
      onSuccess: ({ data, status, total }) => {
        setPageLoading(false)
        if (status) {
          const reviews = [...reviewList, ...data]
          setReviewList(reviews);
          console.log(reviews.length != total, reviews.length, total, "TTT");
          if (data.length === 0) return setHasMore(false)
          /*
                              if (_.isEmpty(properties) || properties?.data.length === 0) return setHasMore(false)

                    page === 1 ? setProperties(properties.data) : setProperties(prev => ([...prev, ...properties.data]))
          */
          // if(reviews.length != total)  setHasMore(true);
          // if(reviews.length === total)  setHasMore(false);
          // setPage
        }

      },
      onError: () => { setPageLoading(false) },
    });
  };

  useEffect(() => {
    setPageLoading(true);
    fetchData(page);
  }, [page]);


  const onNext = () => {
    console.log("handleLoadMore Called");
    if (hasMore && !pageLoading) {
      setPage(prev => prev + 1)
    }
  }

  const [openReviewIndex, setOpenReviewIndex] = useState(null);

  const toggleView = (index) => {
    setOpenReviewIndex((prevIndex) =>
      prevIndex === index ? !(prevIndex === index) : index,
    );
    setToggle(!toggle);
  };

  return (
    <>
      <div
        // id="scrollableDiv" 
        className="tabled quoteManagement">
        <div>
          <div className="d-flex align-items-center mb-3">
            <div className="tab-ratting-wrap">
              <h4>{lang("Reviews")}</h4>
            </div>
            <div className="w-100 text-head_right_cont">
              {/* <div className="role-wrap">
                <Select width="110px" placeholder="Year" />
              </div>
              <div className="role-wrap">
                <Select width="110px" placeholder="Month" />
              </div> */}
            </div>
          </div>
        </div>
        {/* <Row gutter={[24, 0]}> 
         */}
        <div
          id="scrollableDiv"
          style={{ height: '80vh', overflowY: 'auto' }}
        >
          <InfiniteScroll
            dataLength={reviewList.length}
            next={onNext}
            hasMore={hasMore}
            loader={(pageLoading) && <Spin indicator={(<LoadingOutlined style={{ fontSize: 24, }} spin />)} />}
            scrollableTarget="scrollableDiv"
          >
            <Row gutter={[24, 0]}>
              {reviewList.map((item, index) => (
                <Col span={24} xl={12} key={index}>
                  <Card bordered={false} className="criclebox tablespace mb-24">
                    <div className="ratings-card-box">
                      <div className="ratings-card-img review-img">
                        <Image src={item?.reviewer_id?.image} className="w-100" />
                      </div>
                      <div className="ratings-card-text">
                        <div className="d-flex justify-content-between align-items-center rating-right">
                          <h4 className="mb-0">{item?.reviewer_id?.name}</h4>
                          <Rate disabled defaultValue={item.rating} />
                        </div>
                        <div className="card-date-time">
                          <span className="ftp_text">
                            {moment(item?.created_at).format("MMM D, YYYY | hh:mm a",)}
                          </span>
                          <p className="ftp_text">{item?.review}</p>

                          {!item.replies ||
                            (item?.replies?.length === 0 && (
                              <Button
                                className="primary_btn btnStyle"
                                onClick={() => {
                                  setSelectedReview(item);
                                  setCommentModalVisible(true);
                                }}
                              >
                                {lang("Reply")}{" "}
                                <i class="fa fa-light fa-paper-plane"></i>
                              </Button>
                            ))}
                          {item?.replies?.length > 0 && (
                            <Button
                              onClick={() => {
                                toggleView(index);
                              }}
                              className="ms-3 primary_btn btnStyle"
                            >
                              {lang("View")}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>

                    {item?.replies &&
                      item.replies.map((reply, Index) => (
                        <div>
                          {index === openReviewIndex && (
                            <div className="ratings-card-box sub-reply" key={Index}>
                              <div className="ratings-card-img review-img">
                                <Image
                                  src={reply?.sender_id?.image}
                                  className="w-100"
                                />
                              </div>

                              <div className="ratings-card-text sub-review-text">
                                <h4 className="mb-0">{reply?.sender_id?.name}</h4>
                                <div className="card-date-time sub-review-time">
                                  <span className="ftp_text">
                                    {moment(reply?.created_at).format(
                                      "MMM D, YYYY | hh:mm a",
                                    )}
                                  </span>
                                  <p className="ftp_text sub-ftp">
                                    {reply?.message}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </Card>
                </Col>
              ))}
            </Row>
          </InfiniteScroll>

          {commentModalVisible && (
            <CommentModal
              section={sectionName}
              show={commentModalVisible}
              hide={() => {
                setSelectedReview();
                setCommentModalVisible(false);
              }}
              data={selectedReview}
              refresh={() => setRefresh((prev) => !prev)}
            />
          )}
          {/* </Row> */}
        </div>
      </div>
    </>
  );
}
const CommentModal = ({ section, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();

  const handleComment = (values) => {
    const payload = {
      message: values.message,
    };
    request({
      url: apiPath.review + "/reply/" + data?._id,
      method: "POST",
      data: payload,
      onSuccess: ({ status, message }) => {
        if (status) {
          ShowToast(message, Severty.SUCCESS);
          hide();
          refresh();
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    console.log(data);
  }, [data]);
  return (
    <Modal
      open={show}
      width={750}
      okText={lang("Comment")}
      cancelText={lang("Cancel")}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <Form
        id="create"
        form={form}
        onFinish={handleComment}
        layout="vertical"
        initialValues={{
          is_active: true,
        }}
      >
        <h4 className="modal_title_cls">{lang("Comment")}</h4>
        <Row gutter={[16, 0]}>
          <Form.Item
            name="message"
            label={lang("Your Comment")}
            rules={[
              {
                required: true,
                message: lang("Please enter your comment"),
              },
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder={lang("Type your comment here")}
            />
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default Index;
