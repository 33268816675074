import { message, Upload } from "antd";
import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { uploadFile, deleteFile } from "react-s3";
import { s3Config } from "../config/s3Config";
import { getFileExtension } from "../helper/functions";
import { ShowToast, Severty } from "../helper/toast";
import lang from "../helper/langHelper";
import { uploadToS3 } from "../helper/s3";
const MultipleImageUpload = ({
  data,
  fileType,
  imageType,
  btnName,
  onDelete,
  onChange,
  disabled = false,
  isDimension = false,
  ...props
}) => {
  const [fileList, setFileList] = useState([]);


  const checkImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img");
      console.log("Image Upload", img.width, img.height);
      img.onload = () => {
        if (img.width === 720 && img.height === 480) {
          resolve();
        } else {
          reject(
            `Please upload an image with dimensions 720 X 480. uploaded image is ${img.width} X ${img.height}`,
          );
        }
      };

      img.src = URL.createObjectURL(file);
    });
  };

  const beforeUpload = async (file) => {
    try {
      if (fileType.includes(file.type)) {
      } else {
        message.error("File format is not correct");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isLt2M) {
        message.error(`Image must be smaller than 5 MB!`);
        return false;
      }

      isDimension && (await checkImageDimensions(file));

      return true;
    } catch (err) {
      ShowToast(err, Severty.ERROR);
      return false;
    }
  };


  useEffect(() => {
    if (!data) return;
    const multipleFileList = data.map((url, index) => ({
      uid: `${index + 1}`,
      name: url,
      status: "done",
      url: url,
    }));
    setFileList(multipleFileList);
  }, [data]);

  const handleImgChange = async (event) => {
    const { file } = event;
    const extension = getFileExtension(file.name);
    const name = `TAWASI_${new Date().getTime()}.${extension}`;

    const newFile = new File([file], name, { type: file.type });

    uploadToS3({ file: newFile, type: imageType })
      .then((data) => {
        console.log("File Upload", newFile, data);
        const fileData = {
          uid: file.uid,
          name: name,
          status: "done",
          url: data.location,
          thumbUrl: data.location,
        };
        setFileList([...fileList, fileData]);
        if (onChange) {
          onChange([...fileList, fileData]);
        }
      })
      .catch((err) => console.error(err));

    /* uploadFile(newFile, s3Config(imageType))
       .then((data) => {
         const fileData = {
           uid: file.uid,
           name: file.name,
           status: "done",
           url: data.location,
           thumbUrl: data.location,
         };
         setFileList([...fileList, fileData]);
         if (onChange) {
           onChange([...fileList, fileData]);
         }
       })
       .catch((err) => console.error(err)); */


  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <div>
      {" "}
      <PlusOutlined /> <div style={{ marginTop: 8 }}>{lang("Upload")} {btnName}</div>{" "}
    </div>
  );

  const Delete = (filename) => {

    console.log(filename, "filename", imageType);
    const fileName = filename.split('/')
    console.log(fileName[fileName.length - 1], "filename[fileName.length-1]");
    deleteFile(fileName[fileName.length - 1], s3Config(imageType))
      .then(response => console.log(response))
      .catch(err => console.error(err))
  }

  const handleRemove = (file) => {
    console.log(fileList, file);
    // Delete(file.url,"")
    const newFile = fileList.filter((item) => item.uid != file.uid);
    setFileList(newFile);
    if (onChange) {
      onChange([...newFile]);
    }
    // onDelete(file.url)
  };

  return (
    <Upload
      disabled={disabled}
      listType="picture-card"
      onRemove={handleRemove}
      maxCount={8}
      beforeUpload={beforeUpload}
      fileList={fileList}
      //onChange={handleChange}
      customRequest={handleImgChange}
    >
      {fileList.length >= 8 ? null : uploadButton}
    </Upload>
  );
};

export default MultipleImageUpload;
