import { UndoOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Skeleton,
  Tabs,
  Typography,
} from "antd";

import React, { useEffect, useState } from "react";
import Money from "../../assets/images/money-w.png";
import Ypayment from "../../assets/images/payments-y.png";
import Paymentimg from "../../assets/images/payments.png";
import Reve from "../../assets/images/revenue.png";
import Solar from "../../assets/images/solar_cart.png";
import apiPath from "../../constants/apiPath";
import { Months } from "../../constants/var";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import Currency from "../../components/Currency";
import DoughnutCart from "../../components/DoughnutCart";
import LineChart from "./_LineChart";
import BarChart from "./_BarChart";
import { useAuthContext } from "../../context/AuthContext";
import WithdrawModal from "../Settings/_WithdrawModal";
import DepsiteModal from "../Settings/_DepositModal";
import { useNavigate } from "react-router-dom";
const Search = Input.Search;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

function Revenue() {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [totalSale, setTotalSale] = useState();
  const [commission, setCommission] = useState();
  const [revenue, setRevenue] = useState();
  const [orders, setOrders] = useState();
  const { request } = useRequest();
  const [withdrawModal, showWithdrawModal] = useState(false);
  const [depositModal, showDepositModal] = useState(false);
  const [walletData, setWalletData] = useState();

  const { userProfile } = useAuthContext();
  const navigate = useNavigate()

  const getFilter = () => {
    request({
      url: `${apiPath.revenue}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    GetWallet();
    setLoading(true);
    fetchData();
    getFilter();
  }, [filter]);

  const fetchData = (pagination, filters) => {
    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    request({
      url: apiPath.revenue + `${queryString ? `?${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({
        data,
        status,
        total_sale,
        message,
        platform_commission,
        restaurant_revenue,
        orders,
      }) => {
        setLoading(false);
        if (status) {
          total_sale && setTotalSale(total_sale);
          platform_commission && setCommission(platform_commission);
          restaurant_revenue && setRevenue(restaurant_revenue);

          if (orders) {
            const order = orders.reduce((acc, item) => {
              acc[item.status] = item.count;
              return acc;
            }, {});

            setOrders(order);
          }
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const GetWallet = () => {
    request({
      url: "vendor/auth/get-wallet",
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log("walletdata", data);
        if (status) {
          setWalletData(data);
        }
      },
    });
  };

  return (
    <>
      <Row gutter={[18, 0]}>
        <Col span={24} md={24} xl={18}>
          <Card bordered={false} className="criclebox h-full">
            <div className="revenue-card-box pt-0">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <Title level={5}>{lang("Total Sales")}</Title>
                <div className="text-head_right_cont flex-wrap">
                  <div className="role-wrap">
                    <Select
                      width="110px"
                      placeholder={lang("year")}
                      showSearch
                      value={filter.year}
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      options={years.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                      onChange={(value) => onChange("year", value)}
                    />
                  </div>
                  <div className="role-wrap">
                    <Select
                      width="110px"
                      placeholder={lang("month")}
                      showSearch
                      value={filter.month}
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      options={months.map((item) => ({
                        ...item,
                      }))}
                      onChange={(value) => onChange("month", value)}
                    />
                  </div>
                  <Button
                    onClick={() =>
                      setFilter({
                        country_id: undefined,
                        city_id: undefined,
                        year: undefined,
                        month: undefined,
                      })
                    }
                    type="primary"
                    className="resetBtn"
                    icon={<UndoOutlined />}
                  >
                    {lang("reset")}
                  </Button>
                </div>
              </div>
              <Row gutter={[24 , 24]} className="mb-3 mt-3">
                <Col span={20} xl={6} md={9}>
                  <div className="cls-total">
                    <div className="sales-img-icon">
                      <img src={Solar}></img>
                    </div>
                    <div className="sales-text-outer ">
                      <h6>{lang("total Online Sales")}</h6>
                      <h4>
                        <Currency
                          price={totalSale ? totalSale.online_amount : 0}
                        />
                      </h4>
                    </div>
                  </div>
                </Col>
                <Col span={4} xl={3} md={3}>
                  <div className="add-saler-amt">
                    <span>
                      <i class="fas fa-plus"></i>
                    </span>
                  </div>
                </Col>
                <Col span={20} xl={6} md={9}>
                  <div className="cls-total">
                    <div className="sales-img-icon">
                      <img src={Money}></img>
                    </div>
                    <div className="sales-text-outer">
                      <h6>{lang("total COD Sales")}</h6>
                      <h4>
                        <Currency
                          price={totalSale ? totalSale.cod_amount : 0}
                        />
                      </h4>
                    </div>
                  </div>
                </Col>
                <Col span={4} xl={3} md={3}>
                  <div className="add-saler-amt">
                    <span>
                      <i class="fas fa-equals"></i>
                    </span>
                  </div>
                </Col>
                <Col span={24} xl={6} md={24}>
                  <div className="cls-total">
                    <div className="sales-img-icon first-revenue">
                      <img src={Reve}></img>
                    </div>
                    <div className="sales-text-outer">
                      <h6>{lang("total Revenue")}</h6>
                      <h4>
                        <Currency
                          price={
                            totalSale
                              ? totalSale.cod_amount + totalSale.online_amount
                              : 0
                          }
                        />
                      </h4>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="revenue-card-box">
              <Title level={5}>
                {`${lang("Platform Commission")}
                `}

                {/* (${ userProfile?.restaurant_id?.commission_rate ?? 5}%)  */}
              </Title>
              <Row className="mb-3 mt-3">
                <Col span={20} xl={6} md={9}>
                  <div className="cls-total">
                    <div className="sales-img-icon fs-icon">
                      <img src={Solar}></img>
                    </div>
                    <div className="sales-text-outer">
                      <h6>{lang("Online")}</h6>
                      <h4>
                        <Currency
                          price={commission ? commission.online_amount : 0}
                        />
                      </h4>
                    </div>
                  </div>
                </Col>
                <Col span={4} xl={3} md={3}>
                  <div className="add-saler-amt">
                    <span>
                      <i class="fas fa-plus"></i>
                    </span>
                  </div>
                </Col>
                <Col span={20} xl={6} md={9}>
                  <div className="cls-total">
                    <div className="sales-img-icon fs-icon">
                      <img src={Money}></img>
                    </div>
                    <div className="sales-text-outer">
                      <h6>{lang("COD")}</h6>
                      <h4>
                        <Currency
                          price={commission ? commission.cod_amount : 0}
                        />
                      </h4>
                    </div>
                  </div>
                </Col>
                <Col span={4} xl={3} md={3}>
                  <div className="add-saler-amt">
                    <span>
                      <i class="fas fa-equals"></i>
                    </span>
                  </div>
                </Col>
                <Col span={24} xl={6} md={24}>
                  <div className="cls-total admin-amt">
                    <div className="sales-img-icon">
                      <img src={Ypayment}></img>
                    </div>
                    <div className="sales-text-outer ">
                      <h6>{lang("Total Platform Commision")}</h6>
                      <h4>
                        <Currency
                          price={
                            commission
                              ? commission.online_amount + commission.cod_amount
                              : 0
                          }
                        />
                      </h4>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="revenue-card-box">
              <Title level={5}>{lang("Wallet")}</Title>
              <Row gutter={[24 , 24]} className="mb-3 mt-3">
                <Col span={24} xl={12} md={24}>
                  <div className="cls-total received-amt">
                    <div className="sales-img-icon">
                      <img src={Paymentimg} alt="Payment" />
                    </div>
                    <div className="sales-text-outer deposite-main">
                      <div className="wallet-balance-1" onClick={() => navigate(`/transactions?wallet-transaction`)} style={{ cursor: 'pointer' }}>
                        <h6 style={{ fontWeight: "600" }}>
                          {lang("Wallet balance")}
                        </h6>
                        <h4>
                          {" "}
                          <Currency
                            price={
                              walletData?.balance ? walletData?.balance : 0
                            }
                          />
                        </h4>
                      </div>
                      <div className="deposite-wallet">
                        <h6>
                          {walletData?.balance > 0
                            ? lang("Cash Collection")
                            : lang("Add Money")}
                        </h6>

                        {walletData?.balance > 0 ? (
                          <>
                            {withdrawModal && (
                              <WithdrawModal
                                show={withdrawModal}
                                hide={() => showWithdrawModal(false)}
                              />
                            )}
                            <Button
                              onClick={() => showWithdrawModal(true)}
                              className="cash-btn"
                            >
                              {lang("Withdraw")}
                            </Button>
                          </>
                        ) : (
                          <>
                            {depositModal && (
                              <DepsiteModal
                                show={depositModal}
                                hide={() => showDepositModal(false)}
                                data={walletData}
                              />
                            )}
                            <Button
                              onClick={() => showDepositModal(true)}
                              className="wlt-btn"
                            >
                              {lang("Deposit")}
                            </Button>
                          </>
                        )}
                      </div>

                      <h4></h4>
                    </div>
                  </div>
                </Col>
                <Col span={24} xl={12} md={24}>
                  <div className="text-end rtl-left">
                    <Button className="resetBtn" onClick={() => navigate(`/invoice?wallet-transaction`)}>{lang("View Invoice")}</Button>
                  </div>

                </Col>
              </Row>
            </div>
          </Card>
        </Col>
        <Col span={24} xl={6}>
          <Card>
            <DoughnutCart
              dataset={
                orders ? [orders?.delivered ?? 0, orders?.cancelled ?? 0] : []
              }
            />
            <div className="sales-chart-detail">
              <h2>{((orders?.delivered ?? 0) + (orders?.cancelled ?? 0))?.toLocaleString("en-US")}</h2>
              <h6>{lang("Total Orders")}</h6>
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={[24, 24]} className="mt-2">
        <Line months={months} years={years} filter={filter} />

        <Bar months={months} years={years} filter={filter} />
      </Row>
    </>
  );
}

const Line = ({ months, years, filter }) => {
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState([]);
  const [prev, setPrev] = useState([]);

  // const [filter, setFilter] = useState({
  //   country_id: undefined,
  //   city_id: undefined,
  //   year: undefined,
  //   month: undefined,
  // });

  const { request } = useRequest();

  // const onChange = (key, value) => {
  //   setFilter((prev) => ({ ...prev, [key]: value }));
  // };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [filter]);

  const fetchData = () => {
    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    request({
      url:
        `${apiPath.revenue}/online` + `${queryString ? `?${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ data, status, current, prev }) => {
        setLoading(false);
        if (status) {
          const curr = current.map((item) => item.total_restaurant_amount);
          const prevs = prev.map((item) => item.total_restaurant_amount);

          setCurrent(current);
          setPrev(prev);
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  return (
    <Col xs={24} xl={12} className="mb-24">
      <Card bordered={false} className="criclebox h-full">
        <div className="graph-title">
          <Title level={5}>{lang("Online Revenue")}</Title>
          {/* <div className="custom_select">
            <Select
              allowClear
              width="110px"
              placeholder={lang("year")}
              showSearch
              value={filter.year}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={years.map((item) => ({
                value: item,
                label: item,
              }))}
              onChange={(value) => onChange("year", value)}
            />

            <Select
              allowClear
              width="110px"
              placeholder={lang("month")}
              showSearch
              value={filter.month}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={months.map((item) => ({
                ...item,
              }))}
              onChange={(value) => onChange("month", value)}
            />
          </div> */}
        </div>
        {loading ? (
          [1, 2, 3].map((item) => <Skeleton active key={item} />)
        ) : (
          <LineChart current={current} prev={prev} />
        )}
      </Card>
    </Col>
  );
};

const Bar = ({ months, years, filter }) => {
  const [loading, setLoading] = useState(true);

  const [restaurant, setRestaurant] = useState([]);
  const [commission, seCommission] = useState([]);
  // const [filter, setFilter] = useState({
  //   country_id: undefined,
  //   city_id: undefined,
  //   year: undefined,
  //   month: undefined,
  // });
  const { request } = useRequest();

  // const onChange = (key, value) => {
  //   setFilter((prev) => ({ ...prev, [key]: value }));
  // };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [filter]);

  const fetchData = () => {
    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    request({
      url: `${apiPath.revenue}/cod` + `${queryString ? `?${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({
        data,
        status,
        total_sale,
        message,
        platform_commission,
        restaurant_revenue,
        orders,
      }) => {
        setLoading(false);
        if (status) {
          setRestaurant(data);
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  return (
    <Col xs={24} xl={12} className="mb-24">
      <Card bordered={false} className="criclebox h-full">
        <div className="graph-title">
          <Title level={5}>{lang("COD Revenue")}</Title>
          {/* <div className="custom_select">
            <Select
              width="110px"
              placeholder={lang("year")}
              showSearch
              allowClear
              value={filter.year}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={years.map((item) => ({
                value: item,
                label: item,
              }))}
              onChange={(value) => onChange("year", value)}
            />

            <Select
              width="110px"
              placeholder={lang("month")}
              showSearch
              allowClear
              value={filter.month}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={months.map((item) => ({
                ...item,
              }))}
              onChange={(value) => onChange("month", value)}
            />
          </div> */}
        </div>
        {loading ? (
          [1, 2, 3].map((item) => <Skeleton active key={item} />)
        ) : (
          <BarChart restaurant={restaurant} />
        )}
      </Card>
    </Col>
  );
};
export default Revenue;
