import { Button, Card, Col, Image, Row, Switch, Table, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import notfound from "../../assets/images/not_found.png";
import Plus from "../../assets/images/plus.svg";
import DeleteModal from "../../components/DeleteModal";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { AuthContext } from "../../context/AuthContext";
import { dateString } from "../../helper/functions";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import AddForm from "./AddDiscountForm";

export const DISCOUNT_TABS = {
  discount: "discount",
  revenue: "discount revenue",
};

function Index() {
  const sectionName = lang("discount");
  const routeName = "discount";

  const { currency } = useContext(AuthContext);

  const api = {
    discount: apiPath.discount,
    revenue: apiPath.revenue,
  };

  const [visible, setVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();

  const [list, setList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const [showDelete, setShowDelete] = useState(false);
  const [selectedTab, setSelectedTab] = useState(DISCOUNT_TABS.discount);

  const [selectedDiscount, setSelectedDiscount] = useState();

  const onDelete = (id) => {
    request({
      url: api.discount + "/" + id,
      method: "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChangeStatus = (id) => {
    request({
      url: api.discount + "/" + id + "/status",
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const view = (id) => {
    navigate(`/${routeName}/view/${id}`);
  };

  const discountColumns = [
    {
      title: lang("S. No"),
      dataIndex: "index",
      key: "index",
      render: (value, item, index) =>
        pagination.current === 1
          ? index + 1
          : (pagination.current - 1) * 10 + (index + 1),
    },
    {
      title: lang("Discount Name"),
      dataIndex: "name",
      render: (_, { name }) => {
        return name ? <span className="cap">{name ? name : "-"}</span> : "-";
      },
    },
    {
      title: lang("Discount Name Arabic"),
      dataIndex: "ar_name",
      render: (_, { ar_name }) => {
        return ar_name ? (
          <span className="cap">{ar_name ? ar_name : "-"}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: lang("Banner Image"),
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => (
        <Image src={image ? image : notfound} className="table-img" />
      ),
    },
    {
      title: lang("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (_, { amount, discount_type }) => {
        return amount
          ? discount_type === "percentage"
            ? amount + " " + "%"
            : amount + "(" + currency + ")"
          : "-";
      },
    },
    {
      title: lang("Used Count"),
      dataIndex: "count",
      render: (_, { count }) => {
        return count ? <span className="cap">{count ? count : "-"}</span> : 0;
      },
    },
    {
      title: lang("Start Date"),
      dataIndex: "start_date",
      key: "start_date",
      render: (_, { start_date }) => {
        return dateString(start_date,'lll');
      },
    },
    {
      title: lang("End Date"),
      dataIndex: "end_date",
      key: "end_date",
      render: (_, { end_date }) => {
        return dateString(end_date,'lll');
      },
    },
    {
      title: lang("Action"),
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            <Tooltip
              title={lang("Edit")}
              color={"purple"}
              key={"update" + routeName}
            >
              <Button
                title={lang("Edit")}
                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  setSelectedDiscount(record);
                  setVisible(true);
                }}
              >
                <i className="fa fa-light fa-pen"></i>
                <span>{lang("Edit")}</span>
              </Button>
            </Tooltip>

            <Tooltip title={lang("delete")} color={"purple"} key={"Delete"}>
              <Button
                title={lang("delete")}
                className="btnStyle deleteDangerbtn"
                onClick={() => {
                  setSelectedDiscount(record);
                  setShowDelete(true);
                }}
              >
                <img src={deleteWhiteIcon} />
                <span>{lang("delete")}</span>
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: lang("Status"),
      key: "is_active",
      dataIndex: "is_active",
      render: (_, { _id, is_active }) => {
        return (
          <Switch
            onChange={() => {
              handleChangeStatus(_id);
            }}
            checked={is_active}
          />
        );
      },
    },
  ];

  const fetchDiscountList = (payload) => {
    request({
      url:
        api.discount +
        `?status=${""}&page=${pagination ? pagination.current : 1}&pageSize=${
          pagination && pagination?.pageSize ? pagination?.pageSize : 10
        }&search=${debouncedSearchText}`,
      method: "GET",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
        setPagination((prev) => ({
          ...prev,
          current: pagination.current,
          total: data.total ?? 10,
        }));
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const fetchData = (pagination, status) => {
    setLoading(true);

    const payload = {};
    payload.page = pagination ? pagination.current : 1;
    payload.pageSize =  pagination && pagination?.pageSize ? pagination?.pageSize : 10;
    payload.status = selectedTab ? selectedTab : DISCOUNT_TABS.discount;
    payload.search = debouncedSearchText;
    if (selectedTab === DISCOUNT_TABS.discount) {
      fetchDiscountList(payload);
    } else {
  
    }
  };

  const handleChange = (pagination, sorter, filters) => {
    if (!sorter) {
      fetchData(pagination);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 }, selectedTab);
  }, [refresh, debouncedSearchText, selectedTab]);

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <SectionWrapper
                cardHeading={lang("Discounts")}
                extra={
                  <>
                    <div className="w-100 text-head_right_cont">
                      <Button
                        className="primary_btn btnStyle"
                        onClick={(e) => {
                          setVisible(true);
                          setSearchText("");
                        }}
                      >
                        <span className="add-Ic">
                          <img src={Plus} />
                        </span>
                        {lang("Create Discount")}
                      </Button>
                    </div>
                  </>
                }
              >
                <div className="table-responsive customPagination">
                  <Table
                    loading={loading}
                    columns={discountColumns}
                    dataSource={list}
                    pagination={{
                      defaultPageSize: 10,
                      responsive: true,
                      total: pagination.total,
                      showSizeChanger: true,
                      showQuickJumper: true,
                      pageSizeOptions: ["10", "20", "30", "50"],
                    }}
                    onChange={handleChange}
                    className="ant-border-space"
                  />
                </div>
              </SectionWrapper>
            </Card>
          </Col>
        </Row>
        {visible && (
          <AddForm
            section={sectionName}
            api={api}
            show={visible}
            hide={() => {
              setSelectedDiscount();
              setVisible(false);
            }}
            data={selectedDiscount}
            refresh={() => setRefresh((prev) => !prev)}
          />
        )}
      </div>
      {showDelete && (
        <DeleteModal
          title={lang("Delete Discount")}
          subtitle={lang("Are you sure you want to Delete this discount?")}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelectedDiscount();
          }}
          onOk={() => onDelete(selectedDiscount?._id)}
        />
      )}
    </>
  );
}

export default Index;
