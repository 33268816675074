export const FoodTypes = {
  VEG: "vegetarian",
  NONVEG: "non-vegetarian",
};

export const NonVegTypes = {
  HALAL: "halal",
  NONHALAL: "non-halal",
  NULL: null,
};
