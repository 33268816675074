import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import lang from "../helper/langHelper";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      fullSize: true,
      align: "start",
      labels: {
        padding: 18,
        textAlign: "center",
        color: "#252420",
        pointStyle: "circle",
        usePointStyle: true,
      },
    },
  },
};

const DoughnutCart = ({ dataset }) => {
  const data = {
    labels: [`${lang("Orders Delivered")} : `, `${lang("Orders Canceled")} :`],
    datasets: [
      {
        data: dataset ?? [20, 6], // Example data, you can replace this with your own values
        backgroundColor: ["#F3E008", "#FF0000"],
        hoverBackgroundColor: ["#F3E008", "#FF0000"],
      },
    ],
  };

  return <Doughnut data={data} options={options} />;
};

export default DoughnutCart;
