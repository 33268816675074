import {
    Card,
    Input,
    Typography,
    Table
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
const Search = Input.Search;
const { Title, Text } = Typography;

function DeliveryCard() {
  
    const api = {
        status: apiPath.statusQuote,
        list: apiPath.listQuote,
    };
   
    const [searchText, setSearchText] = useState("");
    const { request } = useRequest();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    const debouncedSearchText = useDebounce(searchText, 300);

    useEffect(() => {
        setLoading(true);
        fetchData({ current: 1 });
    }, [refresh, debouncedSearchText]);


    const fetchData = (pagination) => {
        setLoading(true);
        const payload = {};
        payload.search = debouncedSearchText;
        request({
            url: api.list,
            method: "POST",
            data: payload,
            onSuccess: (data) => {
                setLoading(false);
                setList(data.data.list.docs);
                setPagination((prev) => ({
                    ...prev,
                    current: pagination.current,
                    total: data.data.list.totalDocs,
                }));
            },
            onError: (error) => {
                setLoading(false);
                ShowToast(error, Severty.ERROR);
            },
        });
    };

    const columns = [
        {
            title: "Driver Profile",
            dataIndex: "index",
            key: "index",
            render: (_, { uid }) => (uid ? <span className="cap">#{uid}</span> : "-"),
        },
        {
            title: "Driver Id",
            dataIndex: "name",
            key: "name",
            render: (_, { customer_id }) => (customer_id ? <span className="cap">{customer_id.name}</span> : "-"),
        },
        {
            title: "Rating",
            dataIndex: "name",
            key: "name",
            render: (_, { customer_id }) => (customer_id ? <span className="cap">{customer_id.name}</span> : "-"),
        },
        {
            title: "Export",
            key: "created_at",
            render: (_, { driver_id }) => (driver_id ? <span className="cap">{driver_id.name}</span> : "Not Assign"),
        },
    ];

    return (
        <Card bordered={false} className="criclebox h-full">
            <Search />
            <Title level={5}>Delivery Person Report</Title>
            <div className="table-responsive customPagination withOutSearilNo">
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        defaultPageSize: 10,
                        responsive: true,
                        total: pagination.total,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ["10", "20", "30", "50"],
                    }}
                    className="ant-border-space"
                />
            </div>
        </Card>
    );
}


export default DeliveryCard;
