import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { currency } from "../../components/Currency";
import { useAuthContext } from "../../context/AuthContext";
import lang from "../../helper/langHelper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
    },
  },
};

const labels = [
  lang("1st Week"),
  lang("2nd Week"),
  lang("3rd Week"),
  lang("4th Week"),
];

const BarChart = ({ restaurant }) => {
  let cod = Array.from({ length: 4 }, (_, index) => 0);
  let comm = Array.from({ length: 4 }, (_, index) => 0);

  const { currency } = useAuthContext();

  restaurant.forEach((item) => {
    const { _id, total_platform_commission, total_restaurant_amount } = item;
    cod[_id] = total_restaurant_amount;
    comm[_id] = total_platform_commission;
  });

  const totalCod = cod.reduce((acc, curr) => acc + curr, 0);
  const totalComm = comm.reduce((acc, curr) => acc + curr, 0);

  const data = {
    labels,
    datasets: [
      {
        label: `${lang("Total COD")} : ${currency} ${totalCod?.toFixed(2)}`,
        data: cod,
        backgroundColor: "#4990F2",
      },
      {
        label: `${lang("Platform Commission")} :${currency} ${totalComm?.toFixed(2)}`,
        data: comm,
        backgroundColor: "#F3E008",
      },
    ],
  };
  return <Bar options={options} data={data} />;
};

export default BarChart;
