import { DownOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Dropdown,
  Input,
  Menu,
  Modal,
  Table,
  Tag,
  Form,
  Row,
  Col,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";

import ConfirmationBox from "../../components/ConfirmationBox";
import Currency from "../../components/Currency";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import {
  DriverOrderStatus,
  OrderStatus,
} from "../../constants/order-status.constants";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import AddForm from "./AddForm";
import ViewOrderModal from "../../components/ViewOrderModal";
import { convertTime } from "../../helper/getTime";
import { dateString } from "../../helper/functions";

const statusItems = [
  {
    label: lang("Confirm schedule"),
    key: OrderStatus.CONFIRM_SCHEDULED,
  },
  {
    label: lang("Cancel"),
    key: OrderStatus.CANCEL,
  },
];

const ScheduleOrder = ({ section, data }) => {
  const sectionName = "ScheduleOrder";
  const params = useParams();

  const api = {
    status: apiPath.schedule,
    addEdit: apiPath.schedule,
    list: apiPath.schedule,
    importFile: apiPath.schedule + "/" + params.type,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);

  const [statusModalVisible, setStatusModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });

  useEffect(() => {
    getCountry();
  }, []);

  const getCountry = () => {
    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (data) {
          setCountries(data);
        }
      },
    });
  };

  const getCities = (id) => {
    request({
      url: `/country-city/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          setCities(data);
        }
      },
    });
  };

  const handleChangeStatus = (status, id, values) => {
    request({
      url: api.status + "/" + id + "/" + status,
      method: "PUT",
      data: { cancel_reason: values },
      onSuccess: (data) => {
        setLoading(false);
        fetchData({ ...pagination, current: 1 });
        if (data?._doc?.status === OrderStatus.READY) {
          setStatusModalVisible(true);
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const columns = [
    {
      title: lang("Order ID"),
      dataIndex: "index",
      key: "index",
      render: (_, { uid }) => (uid ? <span className="cap">#{uid}</span> : "-"),
    },
    {
      title: lang("customer"),
      dataIndex: "name",
      key: "name",
      render: (_, { customer_id }) =>
        customer_id ? (
          <div style={{ display: "flex", gap: 2, flexDirection: "column" }}>
            {customer_id?.name && (
              <span className="cap">{customer_id.name}</span>
            )}
            {customer_id?.country_code && customer_id?.mobile_number && (
              <span className="cap" style={{ fontSize: "14px", color: "gray" }}>
                ({customer_id.country_code}) {customer_id.mobile_number}
              </span>
            )}
            {customer_id?.email && (
              <span style={{ fontSize: "14px", color: "gray" }}>
                {customer_id.email}
              </span>
            )}
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: lang("items"),
      key: "items",
      render: (_, { items }) =>
        items ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {items.map((item, index) => {
              return (
                <span
                  className="cap"
                  key={index}
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  {item?.food_id?.name + " X " + item?.qty}
                </span>
              );
            })}
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Payment Method"),
      dataIndex: "payment_mod",
      key: "payment_mod",
      render: (_, { payment_mod }) =>
        payment_mod ? (
          <span style={{ textTransform: "uppercase" }}>{payment_mod}</span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Schedule time"),
      key: "created_at",
      render: (_, { schedule_time }) => {
        return schedule_time ? (
          <span className="cap">
            {/* {convertTime(created_at)} */}
            {dateString(schedule_time, "lll")}
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      title: lang("Price"),
      key: "total_payable",
      render: (_, { total_payable }) =>
        total_payable ? (
          <span className="cap">
            <Currency price={total_payable} />
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Platform Commission"), //TODO: pending
      key: "admin_commission",
      render: (_, { platform_commission }) =>
        platform_commission ? (
          <span className="cap">
            <Currency price={platform_commission.restaurant} />
          </span>
        ) : (
          "-"
        ),
    },
    // {
    //     title: lang("status"),
    //     key: "action",
    //     render: (_, { status }) => {
    //         let statusColor = "yellow";
    //         let text = status;
    //         if (status === OrderStatus.CONFIRM_SCHEDULED) {
    //             statusColor = "green";
    //             text = status;
    //         }

    //         return (
    //             <Badge
    //                 className="cap"
    //                 status={statusColor ? statusColor : "default"}
    //                 text={text}
    //             />
    //         );
    //     },
    // },
    {
      title: lang("View"),
      key: "info",
      render: (_, record) => {
        return (
          <Tag
            title="View"
            className="btnOutlineDelete"
            onClick={() => {
              setSelected(record);
              setIsViewOpen(true);
            }}
            style={{
              cursor: "pointer",
              padding: "5px 10px",
              borderRadius: "50px",
            }}
          >
            {lang("View")}
          </Tag>
        );
      },
    },

    {
      title: lang("Status"),
      key: "action",
      render: (_, record) => {
        let status = record.restaurant_status;

        let bgcolor = "#F3E008";
        let color;
        if (status === OrderStatus.READY) {
          if (record.driver_id) {
            status = "Ready to Pick Up";
            bgcolor = "#28DA28";
            color = "#414454";
          }
        } else if (status === OrderStatus.CANCEL) {
          bgcolor = "#ED1313";
          color = "#414454";
        } else if (status === OrderStatus.PROCESSING) {
          bgcolor = "#2AA0F5";
          color = "#fff";
        } else if (status === OrderStatus.CONFIRM_SCHEDULED) {
          bgcolor = "green";
        }
        const menu = (
          <Menu
            onClick={(e) => {
              const key = e?.key;
              setSelected(record);
              if (key === OrderStatus.CANCEL) {
                setCancelModalVisible(true);
              } else {
                handleChangeStatus(key, record._id);
              }
            }}
          >
            {statusItems.map((item) => (
              <Menu.Item key={item.key}>{item.label}</Menu.Item>
            ))}
          </Menu>
        );
        return (
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            className="data-dropdawn"
          >
            <div style={{ backgroundColor: bgcolor }}>
              {lang(status)}
              <DownOutlined />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const handleExport = (exportData) => {
    if (!exportData.length) return;

    const data = exportData.map((row) => ({
      OrderId: row.uid,
      CustomerName: row.customer_id.name,
      OrderItems: row.items
        .map((item, index) => item?.food?.name + " X " + item?.qty)
        .join(","),
      Restaurant: row.restaurant_id.name,
      PaymentMode: row.payment_mod,
      Phone: `${row.customer_id?.country_code}-${row.customer_id?.mobile_number}`,
      Amount: row.total_payable + "(ILS)",
      Status: row.status,
    }));
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");
    XLSX.writeFile(workbook, `${moment().format("DD-MM-YYYY h:m:s")}.xlsx`);
  };

  const getExportData = async () => {
    try {
      setExportLoading(true);
      request({
        url:
          api.list +
          `?page=1&pageSize=${pagination ? pagination.total : 100000}`,
        method: "GET",
        onSuccess: ({ data, status, total, message }) => {
          setExportLoading(false);
          if (status) {
            handleExport(data ?? []);
          }
        },
        onError: (error) => {
          console.log(error);
          setExportLoading(false);
          ShowToast(error, Severty.ERROR);
        },
      });
    } catch (err) {
      console.log(err);
      setExportLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 });
  }, [refresh, debouncedSearchText, filter]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url:
        api.list +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${
          pagination ? pagination.current : 1
        }&pageSize=${
          pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          setList(data);

          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);

        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang("Schedule Order List")}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
              <div className="pageHeadingSearch">
                <Input.Search
                  className="searchInput"
                  placeholder={lang("search")}
                  onChange={onSearch}
                  allowClear
                />
              </div>
              <div className="btn_grp">
                <Button
                  onClick={() => list.length && getExportData()}
                  loading={exportLoading}
                  className="primary_btn btnStyle"
                >
                  {lang("Export To Excel")}
                </Button>
              </div>
            </div>
          </>
        }
      >
        <div div className="table-responsive customPagination withOutSearilNo">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {visible && (
        <AddForm
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {statusModalVisible && (
        <WaitForDriverModal
          section={sectionName}
          api={api}
          show={statusModalVisible}
          hide={() => {
            setSelected();
            setStatusModalVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {cancelModalVisible && (
        <ConfirmationModal
          section={sectionName}
          api={api}
          show={cancelModalVisible}
          hide={() => {
            setSelected();
            setCancelModalVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
          cancelOrder={(values) => {
            handleChangeStatus(
              OrderStatus.CANCEL,
              selected._id,
              values.cancel_reason,
            );
            setCancelModalVisible(false);
          }}
        />
      )}

      {isViewOpen && selected && (
        <ViewOrderModal
          show={isViewOpen}
          hide={() => {
            setIsViewOpen(false);
            setRefresh(true);
          }}
          data={selected}
        />
      )}
    </>
  );
};

const WaitForDriverModal = ({
  status,
  hide,
  show,
  data,
  refresh,
  cancelOrder,
}) => {
  let text =
    "Please wait for delivery agent. They will come to pick the order.";

  return (
    <Modal
      visible={show}
      width={950}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
      footer={[
        <Button key="okay" type="primary" onClick={hide}>
          {lang("okay")}
        </Button>,
      ]}
    >
      <h4 className="modal_sub_title_cls mb-2">{lang(text)}</h4>
    </Modal>
  );
};

const ConfirmationModal = ({
  status,
  hide,
  show,
  data,
  refresh,
  cancelOrder,
}) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} onFinish={cancelOrder} layout="vertical">
      <Modal
        visible={show}
        width={950}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="tab_modal"
        onOk={() => form.submit()}
        onCancel={hide}
        okText={lang("Cancel Order")}
        cancelText={lang("leave")}
      >
        {lang("cancelOrderConfirmation")}
        <Row>
          <Form.Item
            label="Reason"
            name="cancel_reason"
            className="mb-0"
            rules={[
              {
                max: 500,
                message: "Reason should contain more than 500 characters!",
              },
              {
                required: true,
                message: "Please Enter Cancel Reason",
              },
            ]}
          >
            <Col md={24}>
              <Input.TextArea />
            </Col>
          </Form.Item>
        </Row>
      </Modal>
    </Form>
  );
};

export default ScheduleOrder;
