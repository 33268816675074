import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Image,
  Input,
  InputNumber,
  List,
  Modal,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";

import DeleteIcon from "../../assets/images/delete.svg";
import notfound from "../../assets/images/not_found.png";
import SingleImageUpload from "../../components/SingleImageUpload";
import { FoodTypes, NonVegTypes } from "../../constants/foodItems.constants";
import { defaultSize } from "../../constants/var";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { useAppContext } from "../../context/AppContext";

const { TextArea } = Input;

const AddFoodItemForm = ({
  section,
  api,
  show,
  hide,
  data,
  refresh,
  currency,
}) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const [categories, setCategories] = useState([]);
  const [ingredientCategories, setIngredientCategories] = useState([]);
  const [foodSizes, setFoodSizes] = useState([]);
  const [choices, setChoices] = useState([]);

  const { language } = useAppContext();
  const [activeCollapseKeyChoices, setActiveCollapseKeyChoices] = useState([]);
  const [activeCollapseKeyAddOns, setActiveCollapseKeyAddOns] = useState([]);

  const formValues = Form.useWatch([], form);

  useEffect(() => {
    console.log(formValues, "formValues");
  }, [formValues]);

  const handleChoiceCheckboxChange = (index, checked) => {
    setActiveCollapseKeyChoices((prevActiveCollapseKey) => {
      if (checked) {
        return [...prevActiveCollapseKey, index.toString()];
      } else {
        return prevActiveCollapseKey.filter((key) => key !== index.toString());
      }
    });
  };

  const handleAddOnsCheckboxChange = (index, checked) => {
    setActiveCollapseKeyAddOns((prevActiveCollapseKey) => {
      if (checked) {
        return [...prevActiveCollapseKey, index.toString()];
      } else {
        return prevActiveCollapseKey.filter((key) => key !== index.toString());
      }
    });

    const categoryId = ingredientCategories[index]?._id;
    form.setFieldsValue({
      add_ons: {
        [index]: {
          category_id: categoryId,
        },
      },
    });
  };

  const [isSizeContained, setIsSizeContained] = useState(
    data ? data?.contain_size : false,
  );
  const [isCustomizationAdded, setIsCustomizationAdded] = useState(
    data ? data?.contain_choice || data?.contain_add_on : false,
  );

  const handleSizeOption = (e) => {
    setIsSizeContained(e.target.checked);
  };

  const handleCustomization = (e) => {
    setIsCustomizationAdded(e.target.checked);
  };

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const handleImage = (data) => {
    data.length > 0 ? setImage(data[0].url) : setImage([]);
  };

  const getCategories = () => {
    request({
      url: api.foodCategory + "/all",
      method: "GET",
      onSuccess: (data) => {
        setCategories(data.data);
      },
    });
  };

  const getFoodSizes = () => {
    request({
      url: api.size,
      method: "GET",
      onSuccess: (data) => {
        setFoodSizes(data.data);
      },
    });
  };

  const reArrangeChoice = (a, b) => {
    const newArray = [];
    b.forEach((item) => {
      const find = a.find((aItem) => aItem._id == item.choice_id);
      const findIndex = a.findIndex((aItem) => aItem._id == item.choice_id);
      if (find) {
        newArray.push(find);
        a.splice(findIndex, 1);
      }
    });

    return newArray.concat(a);
  };

  const getFoodChoices = () => {
    request({
      url: api.choice,
      method: "GET",
      onSuccess: (res) => {
        if (data) {
          const cat = reArrangeChoice(res.data, data.choice ?? []);
          return setChoices(cat);
        }
        setChoices(res.data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const getIngredientCategories = () => {
    request({
      url: api.addOnCategory,
      method: "GET",
      onSuccess: (res) => {
        if (data) {
          const cat = reArrangeAddOn(res.data, data.add_on ?? []);
          return setIngredientCategories(cat);
        }
        setIngredientCategories(res.data);
      },
    });
  };

  const onCreate = (values) => {
    setLoading(true);
    const updatedChoices = values.choices?.filter((item) => item.is_selected);
    const updatedAddOns = values.add_ons?.filter((item) => item.is_selected);

    const sizes = values.size?.length
      ? values.size?.map((item) => {
          return {
            ...item,
            size: foodSizes.find((size) => size._id.toString() == item.size_id)
              ?.name,
          };
        })
      : [];

    let payload = {
      ...values,
      image: image,
      add_on: updatedAddOns,
      choice: updatedChoices,
      is_customized: !!values.is_customized,
      contain_add_on: !!values.contain_add_on,
      contain_alcohol: !!values.contain_alcohol,
      contain_size: !!values.contain_size,
      contain_choice: !!values.contain_choice,
      size: isSizeContained
        ? sizes //values.size
        : [
            {
              size: defaultSize.name,
              size_id: defaultSize._id,
              price: values.price,
            },
          ],
    };

    console.log(payload, "--- payload");

    request({
      url: `${data ? api.food + "/" + data._id : api.food}`,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const constFindUpdatedChoice = (list) => {
    if (!list || list.length <= 0) return null;
    const choiceIDS = list?.map((choice) => choice?.choice_id);

    const updatedChoices = choices?.map((item) => {
      const itemChoiceIndex = choiceIDS.indexOf(item._id.toString());

      if (itemChoiceIndex !== -1) {
        const updatedChoiceData = {
          is_selected: true,
          is_mandatory: !!list[itemChoiceIndex]?.data?.is_mandatory,
          is_multi_select: !!list[itemChoiceIndex]?.data?.is_multi_select,
          multi_select_count:
            list[itemChoiceIndex]?.data?.multi_select_count || 0,
        };

        return updatedChoiceData;
      }

      return item;
    });
    return updatedChoices;
  };

  const constFindUpdatedAddOns = (list) => {
    if (!list || list.length <= 0) return null;

    const addOnIds = list?.map((ing) => ing?.category_id);

    const updatedAddOns = ingredientCategories?.map((item) => {
      const addOnIndex = addOnIds.indexOf(item._id.toString());

      if (addOnIndex !== -1) {
        const updatedChoiceData = {
          is_selected: true,
          is_mandatory: !!list[addOnIndex]?.data?.is_mandatory,
          is_active: !!list[addOnIndex]?.data?.is_active,
          selection_type: list[addOnIndex]?.data?.selection,
          min_size: list[addOnIndex]?.data?.min_size,
          max_size: list[addOnIndex]?.data?.max_size,
        };

        return updatedChoiceData;
      }
      return item;
    });
    return updatedAddOns;
  };

  const reArrangeAddOn = (a, b) => {
    const newArray = [];
    b.forEach((item) => {
      const find = a.find((aItem) => aItem._id == item.category_id);
      const findIndex = a.findIndex((aItem) => aItem._id == item.category_id);
      if (find) {
        newArray.push(find);
        a.splice(findIndex, 1);
      }
    });
    return newArray.concat(a);
  };

  useEffect(() => {
    if (!data) return;

    setFormLoading(true);

    if (!ingredientCategories.length && !choices.length) return;

    const choice = constFindUpdatedChoice(data.choice ?? []);
    const addOns = constFindUpdatedAddOns(data.add_on ?? []);

    let price;
    const sizeArray = data?.size.map((item) => {
      return {
        size_id: item.size_id?._id ?? item.size,
        price: item.price,
      };
    });

    const updatedSize = sizeArray?.filter((item) => {
      if (
        item.size_id?.toString() === defaultSize._id?.toString() ||
        !item.size_id
      ) {
        price = item.price;
        return false;
      }
      return true;
    });

    if (updatedSize.length == 0) {
      const t = { price: "", size_id: "" };
      updatedSize.push(t);
    }

    form.setFieldsValue({
      ...data,
      choices: data.choice
        ? data.choice?.map((item) => ({ ...item, is_selected: true }))
        : [],
      add_ons: data.add_on?.map((item) => {
        const category = ingredientCategories.find(
          (category) => category._id == item.category_id,
        );
        return {
          ...item,
          is_selected: true,
          all_options: category
            ? item.ingredient_ids.length == category.ingredients.length
            : false,
        };
      }),
      price: price,
      size: updatedSize,
      is_customized: data?.contain_choice || data?.contain_add_on,
    });

    setIsCustomizationAdded(data?.contain_choice || data?.contain_add_on);
    choice?.forEach((item, index) => {
      if (item.is_selected) {
        handleChoiceCheckboxChange(index, true);
      }
    });

    addOns?.forEach((item, index) => {
      if (item.is_selected) {
        handleAddOnsCheckboxChange(index, true);
      }
    });

    setIsCustomizationAdded(data?.is_customized);

    setIsSizeContained(data?.contain_size);

    setImage(data.image);
    setFile([data.image]);

    setFormLoading(false);
  }, [data, choices, ingredientCategories]);

  useEffect(() => {
    getCategories();
    getFoodSizes();
    getFoodChoices();
    getIngredientCategories();
  }, []);

  return (
    <Modal
      open={show}
      width={990}
      okText={data ? lang("Update") : lang("Add")}
      cancelText={data ? lang("Cancel") : lang("Cancel")}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      {formLoading ? (
        <Skeleton />
      ) : (
        <Form
          id="create"
          className="menu-choese-item"
          form={form}
          onFinish={onCreate}
          layout="vertical"
          initialValues={{
            is_active: true,
            food_type: FoodTypes.VEG,
          }}
        >
          <h4 className="modal_title_cls">
            {data ? lang("Edit Item") : lang("Add New Item")}
          </h4>
          <Row gutter={[16, 0]}>
            <Col span={24} sm={18}>
              <Form.Item
                className="qty-cls"
                name="category_id"
                label={lang("Category")}
                rules={[
                  {
                    required: true,
                    message: lang("Please Select Category"),
                  },
                ]}
              >
                <Select placeholder={lang("Select Category")}>
                  {categories && categories && categories.length > 0
                    ? categories.map((item) => (
                        <Select.Option key={item._id} value={item._id}>
                          <span className="cap">
                            {language !== ("en" || null)
                              ? item[`${language}_name`] ?? item?.name
                              : item?.name}
                          </span>
                        </Select.Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} sm={9}>
              <Form.Item
                label={lang("Item Name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: lang("Name is required"),
                  },
                  {
                    max: 100,
                    message: lang(
                      "Name should not contain more then 100 characters!",
                    ),
                  },
                  {
                    min: 2,
                    message: lang("Name should contain at least 2 characters!"),
                  },
                ]}
                normalize={(value) => value.trimStart()}
              >
                <Input autoComplete="off" placeholder="Enter Item Name" />
              </Form.Item>
            </Col>
            <Col span={24} sm={9}>
              <Form.Item
                label={lang("Item Name Arabic")}
                name="ar_name"
                rules={[
                  {
                    required: true,
                    message: lang("Item Name is required"),
                  },
                  {
                    max: 100,
                    message: lang(
                      "Name should not contain more then 100 characters!",
                    ),
                  },
                  {
                    min: 2,
                    message: lang("Name should contain at least 2 characters!"),
                  },
                ]}
                normalize={(value) => value.trimStart()}
              >
                <Input autoComplete="off" placeholder="أدخل اسم العنصر" />
              </Form.Item>
            </Col>
            <Row gutter={24}>
              <Col span={24} sm={9}>
                <Form.Item
                  className="upload_wrap"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (image) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(lang("Image is required")),
                        );
                      },
                    },
                  ]}
                  label={lang("Upload Image")}
                  name="image"
                >
                  <SingleImageUpload
                    value={image}
                    fileType={FileType}
                    imageType={"category"}
                    btnName={""}
                    onChange={(data) => handleImage(data)}
                    isDimension={true}
                  />
                  <p className="img-size-details">
                    {lang(
                      "**Images should be 720x480 for best view in gallery image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 1 MB file size..!!!",
                    )}
                  </p>
                </Form.Item>
                <div className="mt-2">
                  <Image
                    width={120}
                    src={
                      image && image.length > 0 && image !== ""
                        ? image
                        : notfound
                    }
                  ></Image>
                </div>
              </Col>
            </Row>

            <Col span={24} sm={9}>
              <Form.Item
                label={lang("Item Description")}
                name="description"
                rules={[
                  {
                    required: true,
                    message: lang("Item Description is required"),
                  },
                  {
                    max: 200,
                    message: lang(
                      "Description should not contain more then 200 characters!",
                    ),
                  },
                  {
                    min: 2,
                    message: lang(
                      "Description should contain at least 2 characters!",
                    ),
                  },
                ]}
                normalize={(value) => value.trimStart()}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col span={24} sm={9}>
              <Form.Item
                label={lang("Item Description Arabic")}
                name="ar_description"
                rules={[
                  {
                    required: true,
                    message: lang("Item Description is required"),
                  },
                  {
                    max: 200,
                    message: lang(
                      "Description should not contain more then 200 characters!",
                    ),
                  },
                  {
                    min: 2,
                    message: lang(
                      "Description should contain at least 2 characters!",
                    ),
                  },
                ]}
                normalize={(value) => value.trimStart()}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>

            <Col className="d-flex mb-4" span={24} md={18}>
              <Form.Item
                className="cc-check-box m-0 cc-check-box"
                label={lang("Contain Size")}
                name="contain_size"
                valuePropName="checked"
              >
                <Checkbox
                  value={true}
                  onChange={handleSizeOption}
                  defaultChecked={isSizeContained}
                />
              </Form.Item>
            </Col>

            {isSizeContained && (
              <Col span={24} sm={24}>
                <Form.List
                  name="size"
                  className="mt-2"
                  initialValue={[{ price: "", size_id: "" }]}
                >
                  {(fields, { add, remove }, { form }) => (
                    <>
                      {fields.map((field_fr, index_fr) => (
                        <div key={field_fr.key}>
                          <Space
                            key={field_fr.key}
                            align="baseline"
                            className="gap-cls"
                          >
                            <Row gutter={[16, 0]}>
                              <Col span={24} md={7}>
                                <Form.Item
                                  className="qty-cls"
                                  {...field_fr}
                                  name={[field_fr.name, "price"]}
                                  label={
                                    lang("Price") + " ( " + currency + " )"
                                  }
                                  rules={[
                                    {
                                      required: isSizeContained,
                                      message: "Please enter price",
                                    },
                                  ]}
                                  normalize={(value) => value.trimStart()}
                                >
                                  <Input
                                    className=""
                                    type="number"
                                    autoComplete="off"
                                    placeholder={lang("Add Price")}
                                    maxLength={7}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={12} md={6}>
                                <Form.Item
                                  className="qty-cls"
                                  {...field_fr}
                                  name={[field_fr.name, "size_id"]}
                                  label={lang("Select Size")}
                                  rules={[
                                    {
                                      required: isSizeContained,
                                      message: lang("Please Select Size"),
                                    },
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        const selectedSizes =
                                          getFieldValue("size") || [];
                                        const selectedSizeIds =
                                          selectedSizes.map(
                                            (item) => item.size_id,
                                          );
                                        if (
                                          selectedSizeIds
                                            .slice(0, index_fr)
                                            .includes(value)
                                        ) {
                                          return Promise.reject(
                                            "You have already selected this size for this item.",
                                          );
                                        }
                                        return Promise.resolve();
                                      },
                                    }),
                                  ]}
                                >
                                  <Select
                                    getPopupContainer={(triggerNode) =>
                                      triggerNode.parentNode
                                    }
                                    placeholder={lang("Select Size")}
                                  >
                                    {foodSizes.map((item) => (
                                      <Select.Option
                                        key={item._id}
                                        value={item._id}
                                      >
                                        <span className="cap">
                                          {language !== ("en" || null)
                                            ? item[`${language}_name`] ??
                                              item?.name
                                            : item?.name}
                                        </span>
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={12} md={5}>
                                <div className="addDelete_option">
                                  {index_fr > 0 ? (
                                    <div
                                      className="minus-wrap"
                                      style={{ marginTop: "23px" }}
                                    >
                                      <div
                                        className="delete_icon_cls"
                                        onClick={() => remove(field_fr.name)}
                                        style={{
                                          borderRadius: "50%",
                                          color: "#000",
                                        }}
                                      >
                                        <img src={DeleteIcon} />
                                      </div>
                                    </div>
                                  ) : null}

                                  <Form.Item style={{ marginTop: "23px" }}>
                                    <Button
                                      onClick={() => add()}
                                      block
                                      className="primary_btn btnStyle add-item-btn"
                                    >
                                      <i class="fas fa-plus"></i>
                                    </Button>
                                  </Form.Item>
                                </div>
                              </Col>
                            </Row>
                          </Space>
                        </div>
                      ))}
                    </>
                  )}
                </Form.List>
              </Col>
            )}

            <Row gutter={24}>
              {!isSizeContained && (
                <Col span={24} sm={9}>
                  <Form.Item
                    label={lang("Price") + " ( " + currency + " )"}
                    name="price"
                    rules={[
                      {
                        required: !isSizeContained,
                        message: lang("Price is required"),
                      },
                    ]}
                  >
                    <InputNumber
                      autoComplete="off"
                      placeholder={lang("Enter Item Price")}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Col span={24} md={8}>
              <div className="box-mannage-outer">
                <Form.Item
                  label={lang("Item Status")}
                  name="is_active"
                  rules={[{ required: true, message: "please select a type" }]}
                >
                  <Radio.Group>
                    <Radio value={true}>{lang("Active")}</Radio>
                    <Radio value={false}>{lang("De Active")}</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  className=" m-0 cc-check-box "
                  label={lang("Contain Alcohol")}
                  name="contain_alcohol"
                  valuePropName="checked"
                >
                  <Checkbox
                    className="contain-checkbox"
                    value={true}
                    // onChange={handleSizeOption}
                    // defaultChecked={isSizeContained}
                  />
                </Form.Item>
              </div>
            </Col>

            <Col span={24} md={10}>
              <div className="box-mannage-outer colorUpdate">
                <Form.Item
                  label={lang("Type")}
                  name="food_type"
                  className=""
                  rules={[
                    { required: true, message: lang("please select a type") },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={FoodTypes.VEG}>{lang("Vegetarian")}</Radio>
                    <Radio value={FoodTypes.NONVEG}>
                      {lang("Non-Vegetarian")}
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  className="mb-0 veg-type-menu"
                  label={lang("Non Veg types")}
                  name="non_veg_type"
                  rules={[
                    {
                      required: formValues?.food_type === FoodTypes.NONVEG,
                      message: lang("Please Select A Type"),
                    },
                  ]}
                >
                  <Radio.Group
                    disabled={formValues?.food_type === FoodTypes.VEG}
                  >
                    <Radio value={NonVegTypes.HALAL}>{lang("HALAL")}</Radio>
                    <Radio value={NonVegTypes.NONHALAL}>
                      {lang("NON-HALAL")}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Col>

            <Col span={24} md={24}>
              <div className="d-flex mb-3 mt-3">
                <Form.Item
                  className=" m-0 cc-check-box"
                  label={lang("Add More Customization")}
                  name="is_customized"
                  valuePropName="checked"
                >
                  <Checkbox
                    checked={isCustomizationAdded}
                    onChange={handleCustomization}
                  />
                </Form.Item>
              </div>
            </Col>

            {formValues?.is_customized && (
              <Col span={24} md={24} className="custom-items-inner">
                <Row gutter={24}>
                  <>
                    <Col md={24}>
                      <Form.Item
                        className="m-0 cc-check-box"
                        label={lang("Do This Item Contains Choice?")}
                        name="contain_choice"
                        valuePropName="checked"
                      >
                        <Checkbox
                          onChange={(e) => {
                            // handleChangeChoice(e);
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={24}>
                      {formValues?.contain_choice && (
                        <Row className="">
                          {choices.map((item, index) => (
                            <Col span={24} md={7} lg={6} key={index}>
                              <div
                                className="food-crust-text"
                                style={{ border: "1px solid #ccc" }}
                              >
                                <div className="d-flex  main-more-content">
                                  <Form.Item
                                    className="cc-check-box m-0"
                                    label={
                                      language !== ("en" || null)
                                        ? item[`${language}_name`] ?? item?.name
                                        : item?.name
                                    }
                                    initialValue={false}
                                    name={["choices", index, "is_selected"]}
                                    valuePropName="checked"
                                  >
                                    <Checkbox
                                      onChange={(e) => {
                                        handleChoiceCheckboxChange(
                                          index,
                                          e.target.checked,
                                        );

                                        form.setFieldsValue({
                                          choices: {
                                            [index]: {
                                              choice_id: item._id,
                                            },
                                          },
                                        });
                                        form.setFieldsValue({
                                          choices: {
                                            [index]: {
                                              choice: item.name,
                                            },
                                          },
                                        });
                                      }}
                                    />
                                  </Form.Item>
                                </div>
                                <Form.Item
                                  name={["choices", index, "choice_id"]}
                                  hidden={true}
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  name={["choices", index, "choice"]}
                                  hidden={true}
                                >
                                  <Input />
                                </Form.Item>
                                <Collapse
                                  activeKey={activeCollapseKeyChoices}
                                  className="collapse-items-key"
                                >
                                  <Collapse.Panel
                                    className="collapse-panel-box"
                                    key={index.toString()}
                                  >
                                    <List
                                      className="demo-loadmore-list choice-item-loadmore"
                                      itemLayout="horizontal"
                                      dataSource={item.options}
                                      renderItem={(option) => (
                                        <List.Item>
                                          <List.Item description="Ant Design, a design language for background applications, is refined by Ant UED Team" />
                                          <div className="opti-text">
                                            {language !== ("en" || null)
                                              ? option[`${language}_name`] ??
                                                option?.name
                                              : option?.name}
                                          </div>
                                        </List.Item>
                                      )}
                                    />
                                    <div className="d-flex">
                                      <Form.Item
                                        className="cc-check-box m-0"
                                        label={lang("Multiple Select Option")}
                                        initialValue={false}
                                        name={[
                                          "choices",
                                          index,
                                          "is_multi_select",
                                        ]}
                                        valuePropName="checked"
                                      >
                                        <Checkbox />
                                      </Form.Item>
                                    </div>
                                    {formValues.choices &&
                                      formValues.choices[index]
                                        ?.is_multi_select && (
                                        <Form.Item
                                          className="mb-0"
                                          initialValue={1}
                                          name={[
                                            "choices",
                                            index,
                                            "multi_select_count",
                                          ]}
                                          label={lang("Multi Select Option")}
                                          rules={[
                                            {
                                              required:
                                                formValues.choices &&
                                                formValues.choices[index]
                                                  ?.is_multi_select,
                                              message: "Please Enter Count",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            className="option-multi-p"
                                            max={item?.options?.length}
                                          />
                                        </Form.Item>
                                      )}
                                  </Collapse.Panel>
                                </Collapse>

                                <div className="d-flex ps-3 pb-3 pe-3">
                                  <Form.Item
                                    className="cc-check-box m-0"
                                    label={lang("Is Mandatory")}
                                    initialValue={false}
                                    name={["choices", index, "is_mandatory"]}
                                    valuePropName="checked"
                                  >
                                    <Checkbox />
                                  </Form.Item>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      )}
                    </Col>

                    <Col md={24}>
                      <Form.Item
                        className="cc-check-box m-0"
                        label={lang("Do This Item Contains Add Ons?")}
                        name="contain_add_on"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>

                    <Col md={24}>
                      {formValues?.contain_add_on && (
                        <Form.Item name="add_on" style={{ width: "100%" }}>
                          <Row className="">
                            {ingredientCategories.map((item, index) => (
                              <Col className="colum-auto" key={index}>
                                <div
                                  className="food-crust-text"
                                  style={{ border: "1px solid #ccc" }}
                                >
                                  <h6>
                                    {language !== ("en" || null)
                                      ? item[`${language}_name`] ?? item?.name
                                      : item?.name}
                                  </h6>
                                  <div className="mendatory-colam-box">
                                    <div className="d-flex  main-more-content">
                                      <Form.Item
                                        className="cc-check-box m-0 "
                                        label={lang("Include this Category")}
                                        initialValue={false}
                                        name={["add_ons", index, "is_selected"]}
                                        valuePropName="checked"
                                      >
                                        <Checkbox
                                          onChange={(e) =>
                                            handleAddOnsCheckboxChange(
                                              index,
                                              e.target.checked,
                                            )
                                          }
                                        />
                                      </Form.Item>

                                      <Form.Item
                                        name={["add_ons", index, "category_id"]}
                                        hidden={true}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </div>

                                    <Collapse
                                      activeKey={activeCollapseKeyAddOns}
                                      className="collapse-items-key"
                                    >
                                      <Collapse.Panel
                                        className="collapse-panel-box"
                                        key={index.toString()}
                                      >
                                        <div className="space-items-box">
                                          <div className="sec-first-status">
                                            <h5>{lang("Status")}</h5>
                                            <div className="status_item_cls">
                                              <Form.Item
                                                className="cc-check-box m-0 mandatory-status"
                                                label={lang("Is Mandatory")}
                                                initialValue={false}
                                                name={[
                                                  "add_ons",
                                                  index,
                                                  "is_mandatory",
                                                ]}
                                                valuePropName="checked"
                                              >
                                                <Checkbox />
                                              </Form.Item>
                                              <Form.Item
                                                className="mb-0 on-status-in-mandatory"
                                                label=""
                                                initialValue={true}
                                                name={[
                                                  "add_ons",
                                                  index,
                                                  "is_active",
                                                ]}
                                                rules={[
                                                  {
                                                    required:
                                                      !!formValues?.add_ons &&
                                                      !!formValues?.add_ons[
                                                        index
                                                      ].is_selected,
                                                    message: lang(
                                                      "Please Select a Type",
                                                    ),
                                                  },
                                                ]}
                                              >
                                                <Radio.Group className="check0-right">
                                                  <Radio value={true}>
                                                    {lang("Active")}
                                                  </Radio>
                                                  <Radio value={false}>
                                                    {lang("De Active")}
                                                  </Radio>
                                                </Radio.Group>
                                              </Form.Item>
                                            </div>
                                            <div className="d-flex align-items-center flex-wrap">
                                              <Form.Item
                                                className="cc-check-box m-0 recr-status"
                                                name={[
                                                  "add_ons",
                                                  index,
                                                  "all_options",
                                                ]}
                                                valuePropName="checked"
                                              >
                                                <Checkbox
                                                  onChange={({ target }) => {
                                                    if (target.checked) {
                                                      form.setFieldsValue({
                                                        add_ons: {
                                                          [index]: {
                                                            ingredient_ids:
                                                              item.ingredients.map(
                                                                (item) =>
                                                                  item._id,
                                                              ),
                                                          },
                                                        },
                                                      });
                                                    } else {
                                                      form.setFieldsValue({
                                                        add_ons: {
                                                          [index]: {
                                                            ingredient_ids: [],
                                                            all_options: false,
                                                          },
                                                        },
                                                      });
                                                    }
                                                  }}
                                                >
                                                  {lang("Select All")}
                                                </Checkbox>
                                              </Form.Item>

                                              <Form.Item
                                                className="cc-check-box m-0 recr-status"
                                                initialValue={[]}
                                                name={[
                                                  "add_ons",
                                                  index,
                                                  "ingredient_ids",
                                                  // "all-options",
                                                ]}
                                                // valuePropName="checked"
                                              >
                                                <Checkbox.Group
                                                  onChange={(value) => {
                                                    value.length !=
                                                      item.ingredients &&
                                                      form.setFieldsValue({
                                                        add_ons: {
                                                          [index]: {
                                                            all_options: false,
                                                          },
                                                        },
                                                      });
                                                  }}
                                                >
                                                  {item.ingredients.map(
                                                    (item) => (
                                                      <Checkbox
                                                        value={item._id}
                                                      >
                                                        {language !==
                                                        ("en" || null)
                                                          ? item[
                                                              `${language}_name`
                                                            ] ?? item?.name
                                                          : item?.name}{" "} {`${currency} ${item.ingredient_size[0].price}`}
                                                      </Checkbox>
                                                    ),
                                                  )}
                                                </Checkbox.Group>
                                              </Form.Item>
                                            </div>
                                          </div>

                                          <div className="type-of-last">
                                            <Form.Item
                                              className="mb-0 "
                                              label={lang("Type Of Selection")}
                                              initialValue={"single"}
                                              name={[
                                                "add_ons",
                                                index,
                                                "selection_type",
                                              ]}
                                              rules={[
                                                {
                                                  required:
                                                    !!formValues?.add_ons &&
                                                    !!formValues?.add_ons[index]
                                                      .is_selected,
                                                  message: lang(
                                                    "Please Select a Type",
                                                  ),
                                                },
                                              ]}
                                            >
                                              <Radio.Group>
                                                <Radio value={"single"}>
                                                  {lang("Single")}
                                                </Radio>
                                                <Radio value={"multi"}>
                                                  {lang("Multi")}
                                                </Radio>
                                              </Radio.Group>
                                            </Form.Item>
                                            {formValues?.add_ons &&
                                              formValues?.add_ons[index]
                                                ?.selection_type ===
                                                "multi" && (
                                                <>
                                                  <div className="d-flex align-items-center gap-3 justify-content-between">
                                                    <Form.Item
                                                      className="mb-2 "
                                                      initialValue={1}
                                                      name={[
                                                        "add_ons",
                                                        index,
                                                        "min_size",
                                                      ]}
                                                      label={lang("Min Size")}
                                                      rules={[
                                                        {
                                                          required:
                                                            formValues.add_ons &&
                                                            formValues.add_ons[
                                                              index
                                                            ]?.is_multi_select,
                                                          message:
                                                            lang(
                                                              "Please Enter Count",
                                                            ),
                                                        },
                                                      ]}
                                                    >
                                                      <InputNumber
                                                        placeholder="1"
                                                        className="option-multi-p sort-input-type"
                                                        max={
                                                          item?.options?.length
                                                        }
                                                      />
                                                    </Form.Item>
                                                    <Form.Item
                                                      className="mb-2"
                                                      initialValue={2}
                                                      name={[
                                                        "add_ons",
                                                        index,
                                                        "max_size",
                                                      ]}
                                                      label={lang(
                                                        "Enter Max Size",
                                                      )}
                                                      rules={[
                                                        {
                                                          required:
                                                            formValues.add_ons &&
                                                            formValues.add_ons[
                                                              index
                                                            ]?.is_multi_select,
                                                          message:
                                                            "Please Enter Count",
                                                        },
                                                      ]}
                                                    >
                                                      <InputNumber
                                                        placeholder="2"
                                                        className="option-multi-p sort-input-type"
                                                        max={
                                                          item?.options?.length
                                                        }
                                                      />
                                                    </Form.Item>
                                                  </div>
                                                </>
                                              )}
                                          </div>
                                        </div>
                                      </Collapse.Panel>
                                    </Collapse>
                                  </div>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </Form.Item>
                      )}
                    </Col>
                  </>
                </Row>
              </Col>
            )}
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default AddFoodItemForm;
