import { Card, Input, Radio, Row, Skeleton, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";

import DoughnutCart from "../../components/DoughnutCart";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";

const Search = Input.Search;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

function SalesProfitChart() {
  const api = {
    status: apiPath.statusQuote,
    list: apiPath.listQuote,
  };

  const { request } = useRequest();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchData({ current: 1 });
  }, [refresh]);

  const fetchData = () => {
    setLoading(true);
    const payload = {};

    request({
      url: api.list,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data.list.docs);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  return (
    <Card bordered={false} className="criclebox h-full">
      <Title level={5}>Sales/Revenue/Profit</Title>
      <Row style={{ justifyContent: "end" }}>
        <Radio.Group defaultValue="month" buttonStyle="solid">
          <Radio.Button onClick={() => null} value="week">
            Week
          </Radio.Button>
          <Radio.Button onClick={() => null} value="month">
            Month
          </Radio.Button>
          <Radio.Button onClick={() => null} value="year">
            Year
          </Radio.Button>
        </Radio.Group>
      </Row>
      {loading ? (
        [1, 2, 3].map((item) => <Skeleton active key={item} />)
      ) : (
        <>
          {" "}
          <DoughnutCart />{" "}
        </>
      )}
    </Card>
  );
}

export default SalesProfitChart;
