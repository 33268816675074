import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Rate,
  Row,
  Select,
  Table,
  Tabs,
  Tooltip,
  Typography,
  TimePicker,
  DatePicker,
  InputNumber,
  Tag
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Money from "../../assets/images/money-w.png";
import Reve from "../../assets/images/revenue.png";
import Solar from "../../assets/images/solar_cart.png";
import Currency from "../../components/Currency";
import apiPath from "../../constants/apiPath";
import { Last20Years, Months } from "../../constants/var";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import SectionWrapper from "../../components/SectionWrapper";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import Payment from "./Payment";
// import ViewModal from "./_ViewModal";
import { dateString } from "../../helper/functions";
import { UndoOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import SingleImageUpload from "../../components/SingleImageUpload";
import { useAuthContext } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";
import ViewOrderModal from "../../components/ViewOrderModal";
import ExcelDownload from "./_Excel";


const Search = Input.Search;
const { TabPane } = Tabs;
const { Title } = Typography;

function Invoice() {
  const [selectedTab, setSelectedTab] = useState("RecentInvoice");

  const handleTabChange = (status) => {
    setSelectedTab(status);
  };


  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <Tabs
                className="main_tabs"
                onTabClick={handleTabChange}
                activeKey={selectedTab}
                tabBarStyle={{ color: "green" }}
              >
                <TabPane tab={lang("Recent Invoice")} key="RecentInvoice">
                  <RecentInvoice selectedTab={selectedTab} />
                </TabPane>

                <TabPane tab={lang("Invoices")} key={`PendingInvoices`}>
                  <PendingInvoices selectedTab={selectedTab} />
                </TabPane> 

                {/* <TabPane tab={lang("Pending Invoices")} key={`PendingInvoices`}>
                  <PendingInvoices selectedTab={selectedTab} />
                </TabPane> */}

                {/* <TabPane
                  tab={lang("Pending Approval Invoices")}
                  key={`PendingAppprovalInvoices`}
                >
                  <PendingApprovalInvoices selectedTab={selectedTab} />
                </TabPane> */}

                {/* <TabPane tab={lang("Payment History")} key={"PaymentHistory"}>
                  <PaymentHistory selectedTab={selectedTab} />
                </TabPane> */}
               
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

const RecentInvoice = ({ selectedTab }) => {
  const [list, setList] = useState([]);
  const [list2, setList2] = useState([]);
  const [driverOrders, setDriverOrders] = useState([]);
  const [invoice, setInvoice] = useState();
  const [loading, setLoading] = useState(false);
  const [paymentModal, showPaymentModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [month, setMonth] = useState();
  const { request } = useRequest();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState([]);

  const { language } = useAppContext();

  const api = {
    list: apiPath.invoice,
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const fetchData = (pagination) => {
    setLoading(true);
    const payload = {};
    payload.page = pagination ? pagination.current : 1;
    payload.pageSize = pagination ? pagination?.pageSize : 10;

    const queryString = Object.entries(payload)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    request({
      url: apiPath.invoice + `${queryString ? `?${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({
        data,
        total,
        status,
        orders,
        discount_orders,
        request_orders,
      }) => {
        setLoading(false);
        if (status) {
          setList(orders);
          setList2(discount_orders);
          setDriverOrders(request_orders ?? []);
          setInvoice(data);
          const month = Months.find((item) => item.value == data.month);
          console.log(month, "month");
          setMonth(month);
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    fetchData(pagination);
  }, [refresh, selectedTab]);

  const columns = [
    {
      title: lang("Order ID"),
      render: (_, { uid }) => `#${uid}`,
    },
    {
      title: lang("customer"),
      dataIndex: "name",
      render: (_, { customer_id }) =>
        customer_id ? (
          <div style={{ display: "flex", gap: 2, flexDirection: "column" }}>
            {customer_id?.name && (
              <span className="cap">{customer_id.name}</span>
            )}
            {customer_id?.country_code && customer_id?.mobile_number && (
              <span className="cap" style={{ fontSize: "14px", color: "gray" }}>
                ({customer_id.country_code}) {customer_id.mobile_number}
              </span>
            )}
            {customer_id?.email && (
              <span style={{ fontSize: "14px", color: "gray" }}>
                {customer_id.email}
              </span>
            )}
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Order Items"),
      dataIndex: "dateTime",
      key: "dateTime",
      render: (_, { items }) =>
        items ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {items.map((item, index) => {
              return (
                <span
                  className="cap"
                  key={index}
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  {language !== "en" && language !== null
                    ? item?.food_id?.[`${language}_name`] ?? item?.food_id?.name
                    : item?.food_id?.name}
                  {" X " + item?.qty}
                  {/* {item?.food_id?.name + " X " + item?.qty} */}
                </span>
              );
            })}
          </div>
        ) : (
          "-"
        ),
    },
    // {
    //   title: "QYT",
    //   dataIndex: "pickup_point",
    //   key: "pickup_point",
    // },
    {
      title: lang("Payment Type"),
      dataIndex: "payment_type",
      render: (_, { payment_mod }) =>
        payment_mod == "cod" ? lang(`cod`) : lang(`Online`),
    },
    {
      title: lang("Date"),
      dataIndex: "date",
      key: "date",
      render: (_, { created_at }) => moment(created_at).format("ll"),
    },
    // {
    //   title: "Order Type",
    //   dataIndex: "order_type",
    //   key: "order_type",
    // },
    {
      title: lang("Amount"),
      dataIndex: "amount",
      render: (_, { total_payable }) =>
        total_payable ? (
          <span className="cap">
            <Currency price={total_payable} />
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Platform Commission"), //TODO: pending
      key: "admin_commission",
      render: (_, { platform_commission }) =>
        platform_commission ? (
          <span className="cap">
            <Currency price={platform_commission.restaurant} />
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Action"),
      render: (_, record) => {
        return (
          <>
            <Tooltip title="View Detail" color={"purple"} key={"viewDetail"}>
              <Button
                className="ms-sm-2 mt-xs-2 primary_btn btnStyle"
                onClick={(e) => {
                  setShow(true);
                  setSelected(record);
                }}
              >
                {lang("View Details")}
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
 
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [viewModalOpen, setviewModalOpen] = useState(false);
 
  const handleView = () => {
    setviewModalOpen(false);
  }; 

  const download =()=>{
    const data= {
      order:{
        data:list.filter(item=>!item.coupon && item.type !== "Driver Request").map((row) => ({
          Category: row.type + (row.is_vendor_pay_delivery ? ("-VENDOR FREE DELIVERY") : (row.coupon ? "-VENDOR DISCOUNT" : (row.delivery_coupon ? "-ADMIN DISCOUNT" : (row.type == "Driver Request" ? "" : "-NO DISCOUNT")))),
          "Order ID": row.uid || "-",
          "Order Date": row.created_at ? moment(row.created_at).format("ll") : "-",
          "Order Time": row.created_at ? moment(row.created_at).format("HH:mm a") : "-",
          "Order Type": row.type || "-",
          "Order Status": row.status || "-",
          "Payment Mode": row.payment_mod || "-",
          "Order Total": row.total_payable || 0,
          "Coupon Code": row.coupon?.code || "NO DISCOUNT",
          "Discount Amount": row.discount || 0,
          "Delivery Coupon Code": row.delivery_coupon?.code || "NO DISCOUNT",
          "Delivery Discount Amount": row.delivery_discount || 0,
          "Platform Commission - Restaurant (Percentage)": row.platform_commission_rates?.restaurant || 0,
          "Platform Commission - Restaurant (Amount)": row.platform_commission?.restaurant || 0,
          "Tax (Percentage)": row.platform_commission_rates?.tax || 0,
          "Tax (Amount)": row.tax > 0 ? row.tax : 0,
        "Total for Restaurant": ((row?.total_amount) - (row?.discount) - (row?.platform_commission?.restaurant ?? 0) + (row?.tax ?? 0) - (row?.is_vendor_pay_delivery ? row.delivery_discount : 0)) || 0,
        })),
        filename:'Orders Without Discount'
      },
      discount:{
        data:list2.filter(item=>item.coupon && item.type !== "Driver Request").map((row) => ({
          Category: row.type + (row.is_vendor_pay_delivery ? ("-VENDOR FREE DELIVERY") : (row.coupon ? "-VENDOR DISCOUNT" : (row.delivery_coupon ? "-ADMIN DISCOUNT" : (row.type == "Driver Request" ? "" : "-NO DISCOUNT")))),
          "Order ID": row.uid || "-",
          "Order Date": row.created_at ? moment(row.created_at).format("ll") : "-",
          "Order Time": row.created_at ? moment(row.created_at).format("HH:mm a") : "-",
          "Order Type": row.type || "-",
          "Order Status": row.status || "-",
          "Payment Mode": row.payment_mod || "-",
          "Order Total": row.total_payable || 0,
          "Coupon Code": row.coupon?.code || "NO DISCOUNT",
          "Discount Amount": row.discount || 0,
          "Delivery Coupon Code": row.delivery_coupon?.code || "NO DISCOUNT",
          "Delivery Discount Amount": row.delivery_discount || 0,
          "Platform Commission - Restaurant (Percentage)": row.platform_commission_rates?.restaurant || 0,
          "Platform Commission - Restaurant (Amount)":  row.platform_commission?.restaurant || 0,
          "Tax (Percentage)": row.platform_commission_rates?.tax || 0,
          "Tax (Amount)": row.tax > 0 ? row.tax : 0,
        "Total for Restaurant": ((row?.total_amount) - (row?.discount) - (row?.platform_commission?.restaurant ?? 0) + (row?.tax ?? 0) - (row?.is_vendor_pay_delivery ? row.delivery_discount : 0)) || 0,
        })),
        filename:'Orders With Discount'
      },
      request:{
        data:driverOrders.filter(item=>item.type === "Driver Request").map((row) => ({
          Category: row.type + (row.is_vendor_pay_delivery ? ("-VENDOR FREE DELIVERY") : (row.coupon ? "-VENDOR DISCOUNT" : (row.delivery_coupon ? "-ADMIN DISCOUNT" : (row.type == "Driver Request" ? "" : "-NO DISCOUNT")))),
          "Order ID": row.uid || "-",
          "Order Date": row.created_at ? moment(row.created_at).format("ll") : "-",
          "Order Time": row.created_at ? moment(row.created_at).format("HH:mm a") : "-",
          "Order Type": row.type || "-",
          "Order Status": row.status || "-",
          "Payment Mode": row.payment_mod || "-",
          "Order Total": row.total_payable || 0,
          "Coupon Code": row.coupon?.code || "NO DISCOUNT",
          "Discount Amount": row.discount || 0,
          "Delivery Coupon Code": row.delivery_coupon?.code || "NO DISCOUNT",
          "Delivery Discount Amount": row.delivery_discount || 0,
          "Platform Commission - Restaurant (Percentage)": row.platform_commission_rates?.restaurant || 0,
          "Platform Commission - Restaurant (Amount)":  row.platform_commission?.restaurant || 0,
          "Tax (Percentage)": row.platform_commission_rates?.tax || 0,
          "Tax (Amount)": row.tax > 0 ? row.tax : 0,
        "Total for Restaurant": ((row?.total_amount) - (row?.discount) - (row?.platform_commission?.restaurant ?? 0) + (row?.tax ?? 0) - (row?.is_vendor_pay_delivery ? row.delivery_discount : 0)) || 0,
        })),
        filename:'Driver Request Orders'
      }
    }
    ExcelDownload({data})
  }

  return (
    <>
      <>
        <div className="tabled categoryService">
          <Row gutter={[24, 0]}>
            <Col xs={24} xl={24}>
              <Card
                bordered={false}
                className="cap criclebox tablespace mb-24"
                title={
                  <>
                    <div className="arrow-ic me-1">
                      {/* <ArrowLeftOutlined /> */}
                    </div>
                    <div className="title-left ">
                      <h4>
                        {month ? month.label : ""} {lang("Invoice")}
                      </h4>
                    </div>
                  </>
                }
              >
                <div className="collection_dtl_body">
                  <Row gutter={24} style={{ padding: "6px 14px" }}>
                    <Col span={24} xxl={12} className="sm-padding-0">
                      <Row gutter={24}>
                        <Col xs={24} xl={12} xxl={10} className="sm-padding-0">
                          <div className="number">
                            {/* <Row align="middle" gutter={[24, 0]}>
                              <Col xs={6}>
                                <div className="icon_box">
                                </div>
                              </Col>
                              <Col xs={18}>
                                <span>{'total Orders'}</span>
                                <p className="ftp_text">25k</p>
                                <Title level={3}>{ }</Title>
                              </Col>
                            </Row> */}
                            <div className="cls-total">
                              <div className="sales-img-icon">
                                <img src={Solar}></img>
                              </div>
                              <div className="sales-text-outer">
                                <h6>{lang("total Orders")}</h6>
                                <h4>{pagination ? pagination.total : 0}</h4>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col xs={24} xl={12} xxl={14} className="sm-padding-0">
                          <div className="number">
                            {/* <Row align="middle" gutter={[24, 0]}>
                              <Col xs={18}>
                                <span>{'total Sales (COD + Online Sales)'}</span>
                                <p className="ftp_text">AED 61,000 </p>
                                <Title level={3}>{ }</Title>
                              </Col>
                              <Col xs={6}>
                                <div className="icon_box">
                                </div>
                              </Col>
                            </Row> */}
                            <div className="cls-total">
                              <div className="sales-img-icon">
                                <img src={Reve}></img>
                              </div>
                              <div className="sales-text-outer">
                                <h6>
                                  {lang("total Sales (COD + Online Sales)")}
                                </h6>

                                <h4>
                                  <Currency
                                    price={
                                      invoice ? invoice.total_online_and_cod : 0
                                    }
                                  />{" "}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24} xxl={12} className="sm-padding-0">
                      <Card className="collection_card">
                        <div className="cls-total received-amt collection-inner">
                          <div className="sales-img-icon">
                            <img src={Money}></img>
                          </div>
                          <div className="sales-text-outer ">
                            <h6>
                              {invoice
                                ? invoice.who_pay == "Admin"
                                  ? `${lang("You Will Receive")}`
                                  : `${lang("Total Platform Commission")}`
                                : ""}
                            </h6>
                            <h4>
                              <Currency
                                price={invoice ? invoice.remaining_amount : 0}
                              />{" "}
                            </h4>
                          </div>
                        </div>
                        <div className="invoice-btn-sec">
                          {/* {invoice && invoice.who_pay == "Restaurant" && (
                            <div className="pay-btn">
                              <button onClick={() => showPaymentModal(true)}>
                                {lang("Pay")}
                              </button>
                            </div>
                          )} */}
                          {/* <div className="view-more-btn">
                            <button onClick={viewshowModal}>
                              View Details
                            </button>
                          </div> */}
                          <div className="view-dawnload-btn">
                            <button onClick={download}>
                              <i class="fas fa-arrow-down me-2"></i>
                              {lang("Download Invoice")}
                            </button>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div></div>
              </Card>
            </Col>
          </Row>
        </div>
        <Card bordered={false} className="criclebox tablespace mb-24">
          <SectionWrapper cardHeading={lang("Order Without Discount")}>
            <div className="table-responsive customPagination">
              <Table
                loading={loading}
                columns={columns}
                dataSource={list}
                // pagination={{
                //   defaultPageSize: 10,
                //   responsive: true,
                //   total: pagination.total,
                //   showSizeChanger: true,
                //   showQuickJumper: true,
                //   pageSizeOptions: ["10", "20", "30", "50"],
                // }}
                pagination={true}
                // onChange={handleChange}
                className="ant-border-space"
              />
            </div>
          </SectionWrapper>
        </Card>
        <Card bordered={false} className="criclebox tablespace mb-24">
          <SectionWrapper cardHeading={lang("Order With Discount")}>
            <div className="table-responsive customPagination">
              <Table
                loading={loading}
                columns={columns}
                dataSource={list2}
                // pagination={{
                //   defaultPageSize: 10,
                //   responsive: true,
                //   total: pagination.total,
                //   showSizeChanger: true,
                //   showQuickJumper: true,
                //   pageSizeOptions: ["10", "20", "30", "50"],
                // }}
                pagination={true}
                // onChange={handleChange}
                className="ant-border-space"
              />
            </div>
          </SectionWrapper>
        </Card>
        <Card bordered={false} className="criclebox tablespace mb-24">
          <SectionWrapper cardHeading={lang("Driver Request Order")}>
            <div className="table-responsive customPagination">
              <Table
                loading={loading}
                columns={columns}
                dataSource={driverOrders}
                // pagination={{
                //   defaultPageSize: 10,
                //   responsive: true,
                //   total: pagination.total,
                //   showSizeChanger: true,
                //   showQuickJumper: true,
                //   pageSizeOptions: ["10", "20", "30", "50"],
                // }}
                pagination={true}
                // onChange={handleChange}
                className="ant-border-space"
              />
            </div>
          </SectionWrapper>
        </Card>
        {paymentModal && (
          <Payment
            show={paymentModal}
            data={invoice}
            hide={() => showPaymentModal(false)}
          />
        )}
        {/* payment modal start */}
        <Modal
          width={750}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          className="tab_modal"
        >
          <Form id="create" layout="vertical">
            <h4 className="modal_title_cls">{lang("Payment")}</h4>

            <Row gutter={[16, 0]}>
              <Col span={24}>
                <div className="payment-mannage-outer">
                  <Form.Item
                    label="Choose a Payment Method"
                    name="is_active"
                    rules={[
                      { required: true, message: "please select a type" },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value={true}>{lang("Wire Transfer")}</Radio>
                      <Radio value={false}>{lang("Cash")}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <Form.Item className="qty-cls" label={"Collector ID"}>
                  <Input className="" autoComplete="off" placeholder={"#123"} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="qty-cls" label={"Collector Name"}>
                  <Input
                    className=""
                    autoComplete="off"
                    placeholder={"Zaire Aminoff"}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item className="qty-cls" label={"Restaurant Name"}>
                  <Input
                    className=""
                    autoComplete="off"
                    placeholder={"Urla Restaurant"}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item className="qty-cls" label={"City"}>
                  <Input
                    className=""
                    autoComplete="off"
                    placeholder={"Abu Dhabi"}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item className="qty-cls" label={"Area"}>
                  <Input
                    className=""
                    autoComplete="off"
                    placeholder={"Downtown"}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col span={24} md={12}>
                <Form.Item className="qty-cls" label={"Date"}>
                  <Input
                    className=""
                    autoComplete="off"
                    placeholder={"1 Sept, 2023"}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item className="qty-cls" label={"Time"}>
                  <Input
                    className=""
                    autoComplete="off"
                    placeholder={"10:30 AM"}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item className="qty-cls" label={"Total Amount (AED)"}>
                  <Input
                    className=""
                    autoComplete="off"
                    placeholder={"2,000"}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <div className="payment-mannage-outer">
                  <Form.Item
                    label="Choose a Payment"
                    name="is_active"
                    rules={[
                      { required: true, message: "please select a type" },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value={false}>Full Payment</Radio>
                      <Radio value={true}>Partial Payment</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal>
        {/* payment modal end */}

        {/* view modal start */}
        <Modal
          width={1070}
          open={viewModalOpen}
          onOk={handleView}
          onCancel={handleView}
          className="tab_modal"
        >
          <Form id="create" layout="vertical">
            <h4 className="modal_title_cls">Invoice Payment Details</h4>
            <div className="payment-modal-top">
              <h4>Without Campaign Sales</h4>
              <div className="payment-card">
                <Row gutter={[18, 0]} className="    justify-content-between">
                  <Col span={24} lg={11}>
                    <div className="total-sales">
                      <h6 className="payment-total-h">Total Cash Sales</h6>
                      <div className="total-body">
                        <span>Total</span>
                        <p>AED 20,000.00</p>
                      </div>
                      <div className="total-body">
                        <span>Platform Commission 10%</span>
                        <p>AED -2000.00</p>
                      </div>
                      <div className="total-footer">
                        <h6>Total Amount</h6>
                        <h6>AED 18,000.00</h6>
                      </div>
                    </div>
                  </Col>
                  <Col span={24} lg={11}>
                    <div className="total-sales">
                      <h6 className="payment-total-h">Total Online Sales</h6>
                      <div className="total-body">
                        <span>Total</span>
                        <p>AED 1,000.00</p>
                      </div>
                      <div className="total-body">
                        <span>Platform Commission 10%</span>
                        <p>AED -100.00</p>
                      </div>
                      <div className="total-footer">
                        <h6>Total Amount</h6>
                        <h6>AED 900.00</h6>
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="revenue-card-box p-0">
                      <Title level={5}>Total Payment</Title>
                      <Row className="mb-3 mt-3">
                        <Col span={20} xl={6} md={9}>
                          <div className="cls-total">
                            <div className="sales-img-icon fs-icon">
                              <img src={Solar}></img>
                            </div>
                            <div className="sales-text-outer">
                              <h6>pay to Admin</h6>
                              <h4>AED 2,000</h4>
                            </div>
                          </div>
                        </Col>
                        <Col span={4} xl={3} md={3}>
                          <div className="add-saler-amt">
                            <span>
                              <i class="fas fa-minus"></i>
                            </span>
                          </div>
                        </Col>
                        <Col span={20} xl={6} md={9}>
                          <div className="cls-total">
                            <div className="sales-img-icon fs-icon">
                              <img src={Solar}></img>
                            </div>
                            <div className="sales-text-outer">
                              <h6>Admin Pay to Vendor</h6>
                              <h4>AED 900</h4>
                            </div>
                          </div>
                        </Col>
                        <Col span={4} xl={3} md={3}>
                          <div className="add-saler-amt">
                            <span>
                              <i class="fas fa-equals"></i>
                            </span>
                          </div>
                        </Col>
                        <Col span={24} xl={6} md={24}>
                          <div className="cls-total received-amt">
                            <div className="sales-img-icon">
                              <img src={Money}></img>
                            </div>
                            <div className="sales-text-outer ">
                              <h6>Pay to Admin</h6>
                              <h4>AED 1,100</h4>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="payment-modal-top mt-3">
              <h4>Online Campaign Sales</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  className="ant-border-space"
                />
              </div>
            </div>
            <div className="payment-modal-top mt-3">
              <h4>COD Campaign Sales</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  className="ant-border-space"
                />
              </div>
            </div>
            <Row>
              <Col span={24} className="mt-3">
                <div className="revenue-card-box p-0">
                  <Title level={5}>Total Campaign Amount</Title>
                  <Row className="mb-3 mt-3">
                    <Col span={20} xl={6} md={9}>
                      <div className="cls-total">
                        <div className="sales-img-icon fs-icon">
                          <img src={Solar}></img>
                        </div>
                        <div className="sales-text-outer">
                          <h6>Online Sale</h6>
                          <h4>AED 38,750</h4>
                        </div>
                      </div>
                    </Col>
                    <Col span={4} xl={3} md={3}>
                      <div className="add-saler-amt">
                        <span>
                          <i class="fas fa-minus"></i>
                        </span>
                      </div>
                    </Col>
                    <Col span={20} xl={6} md={9}>
                      <div className="cls-total">
                        <div className="sales-img-icon fs-icon">
                          <img src={Solar}></img>
                        </div>
                        <div className="sales-text-outer">
                          <h6>COD Sales</h6>
                          <h4>AED 1,250</h4>
                        </div>
                      </div>
                    </Col>
                    <Col span={4} xl={3} md={3}>
                      <div className="add-saler-amt">
                        <span>
                          <i class="fas fa-equals"></i>
                        </span>
                      </div>
                    </Col>
                    <Col span={24} xl={6} md={24}>
                      <div className="cls-total received-amt">
                        <div className="sales-img-icon">
                          <img src={Money}></img>
                        </div>
                        <div className="sales-text-outer ">
                          <h6>Total Campaign Sales</h6>
                          <h4>AED 40,000</h4>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={24} className="mt-3">
                <div className="revenue-card-box p-0">
                  <Title level={5}>Final Amount</Title>
                  <Row className="mb-3 mt-3">
                    <Col span={20} xl={6} md={9}>
                      <div className="cls-total">
                        <div className="sales-img-icon fs-icon">
                          <img src={Solar}></img>
                        </div>
                        <div className="sales-text-outer">
                          <h6>Total Campaign Sales</h6>
                          <h4>AED 40,000</h4>
                        </div>
                      </div>
                    </Col>
                    <Col span={4} xl={3} md={3}>
                      <div className="add-saler-amt">
                        <span>
                          <i class="fas fa-minus"></i>
                        </span>
                      </div>
                    </Col>
                    <Col span={20} xl={6} md={9}>
                      <div className="cls-total">
                        <div className="sales-img-icon fs-icon">
                          <img src={Solar}></img>
                        </div>
                        <div className="sales-text-outer">
                          <h6>Pay to Admin</h6>
                          <h4>AED 1,100</h4>
                        </div>
                      </div>
                    </Col>
                    <Col span={4} xl={3} md={3}>
                      <div className="add-saler-amt">
                        <span>
                          <i class="fas fa-equals"></i>
                        </span>
                      </div>
                    </Col>
                    <Col span={24} xl={6} md={24}>
                      <div className="cls-total received-amt">
                        <div className="sales-img-icon">
                          <img src={Money}></img>
                        </div>
                        <div className="sales-text-outer ">
                          <h6>Total Received Amount</h6>
                          <h4>AED 38,900</h4>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal>
        {/* payment modal end */}
        {show && (
          <ViewOrderModal
            show={show}
            hide={() => {
              setSelected();
              setShow(false);
            }}
            data={selected}
            hidePdfBtn={true}
          />
        )}
      </>
    </>
  );
};

const PendingInvoices = ({ selectedTab }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { request } = useRequest();

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [cities, setCities] = useState([]);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [filter, setFilter] = useState({
    year: undefined,
    month: undefined,
  });

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination);
  };

  const download =(order)=>{
    const data= {
      order:{
        data:order.filter(item=>!item.coupon && item.type !== "Driver Request").map((row) => ({
          Category: row.type + (row.is_vendor_pay_delivery ? ("-VENDOR FREE DELIVERY") : (row.coupon ? "-VENDOR DISCOUNT" : (row.delivery_coupon ? "-ADMIN DISCOUNT" : (row.type == "Driver Request" ? "" : "-NO DISCOUNT")))),
          "Order ID": row.uid || "-",
          "Order Date": row.created_at ? moment(row.created_at).format("ll") : "-",
          "Order Time": row.created_at ? moment(row.created_at).format("HH:mm a") : "-",
          "Order Type": row.type || "-",
          "Order Status": row.status || "-",
          "Payment Mode": row.payment_mod || "-",
          "Order Total": row.total_payable || 0,
          "Coupon Code": row.coupon?.code || "NO DISCOUNT",
          "Discount Amount": row.discount || 0,
          "Delivery Coupon Code": row.delivery_coupon?.code || "NO DISCOUNT",
          "Delivery Discount Amount": row.delivery_discount || 0,
          "Platform Commission - Restaurant (Percentage)": row.platform_commission_rates?.restaurant || 0,
          "Platform Commission - Restaurant (Amount)": row.platform_commission?.restaurant || 0,
          "Tax (Percentage)": row.platform_commission_rates?.tax || 0,
          "Tax (Amount)": row.tax > 0 ? row.tax : 0,
        "Total for Restaurant": ((row?.total_amount) - (row?.discount) - (row?.platform_commission?.restaurant ?? 0) + (row?.tax ?? 0) - (row?.is_vendor_pay_delivery ? row.delivery_discount : 0)) || 0,
        })),
        filename:'Orders Without Discount'
      },
      discount:{
        data:order.filter(item=>item.coupon && item.type !== "Driver Request").map((row) => ({
          Category: row.type + (row.is_vendor_pay_delivery ? ("-VENDOR FREE DELIVERY") : (row.coupon ? "-VENDOR DISCOUNT" : (row.delivery_coupon ? "-ADMIN DISCOUNT" : (row.type == "Driver Request" ? "" : "-NO DISCOUNT")))),
          "Order ID": row.uid || "-",
          "Order Date": row.created_at ? moment(row.created_at).format("ll") : "-",
          "Order Time": row.created_at ? moment(row.created_at).format("HH:mm a") : "-",
          "Order Type": row.type || "-",
          "Order Status": row.status || "-",
          "Payment Mode": row.payment_mod || "-",
          "Order Total": row.total_payable || 0,
          "Coupon Code": row.coupon?.code || "NO DISCOUNT",
          "Discount Amount": row.discount || 0,
          "Delivery Coupon Code": row.delivery_coupon?.code || "NO DISCOUNT",
          "Delivery Discount Amount": row.delivery_discount || 0,
          "Platform Commission - Restaurant (Percentage)": row.platform_commission_rates?.restaurant || 0,
          "Platform Commission - Restaurant (Amount)":  row.platform_commission?.restaurant || 0,
          "Tax (Percentage)": row.platform_commission_rates?.tax || 0,
          "Tax (Amount)": row.tax > 0 ? row.tax : 0,
        "Total for Restaurant": ((row?.total_amount) - (row?.discount) - (row?.platform_commission?.restaurant ?? 0) + (row?.tax ?? 0) - (row?.is_vendor_pay_delivery ? row.delivery_discount : 0)) || 0,
        })),
        filename:'Orders With Discount'
      },
      request:{
        data:order.filter(item=>item.type === "Driver Request").map((row) => ({
          Category: row.type + (row.is_vendor_pay_delivery ? ("-VENDOR FREE DELIVERY") : (row.coupon ? "-VENDOR DISCOUNT" : (row.delivery_coupon ? "-ADMIN DISCOUNT" : (row.type == "Driver Request" ? "" : "-NO DISCOUNT")))),
          "Order ID": row.uid || "-",
          "Order Date": row.created_at ? moment(row.created_at).format("ll") : "-",
          "Order Time": row.created_at ? moment(row.created_at).format("HH:mm a") : "-",
          "Order Type": row.type || "-",
          "Order Status": row.status || "-",
          "Payment Mode": row.payment_mod || "-",
          "Order Total": row.total_payable || 0,
          "Coupon Code": row.coupon?.code || "NO DISCOUNT",
          "Discount Amount": row.discount || 0,
          "Delivery Coupon Code": row.delivery_coupon?.code || "NO DISCOUNT",
          "Delivery Discount Amount": row.delivery_discount || 0,
          "Platform Commission - Restaurant (Percentage)": row.platform_commission_rates?.restaurant || 0,
          "Platform Commission - Restaurant (Amount)":  row.platform_commission?.restaurant || 0,
          "Tax (Percentage)": row.platform_commission_rates?.tax || 0,
          "Tax (Amount)": row.tax > 0 ? row.tax : 0,
        "Total for Restaurant": ((row?.total_amount) - (row?.discount) - (row?.platform_commission?.restaurant ?? 0) + (row?.tax ?? 0) - (row?.is_vendor_pay_delivery ? row.delivery_discount : 0)) || 0,
        })),
        filename:'Driver Request Orders'
      }
    }
    ExcelDownload({data})
  }
  const getFilter = () => {
    request({
      url:  `${apiPath.invoice}/filters/pending`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const fetchData = (pagination, status) => {
    setLoading(true);
    const payload = { ...filter };
    payload.page = pagination ? pagination.current : 1;
    payload.pageSize = pagination ? pagination?.pageSize : 10;

    const queryString = Object.entries(payload)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    request({
      url:
        `${apiPath.invoice}/pending` +
        `${queryString ? `?${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ data, total, status }) => {
        setLoading(false);
        if (status) {
          setList(data);
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    fetchData(pagination);
    getFilter();
  }, [refresh, selectedTab, filter]);

  const columns = [
    {
      title: lang("Invoice No."),
      render: (_, { uid }) => `#${uid}`,
    },
    {
      title: lang("Invoice Date"),
      dataIndex: "dateTime",
      key: "dateTime",
      render: (_, { created_at }) => moment(created_at).format("ll"),
    },
    {
      title: lang("Total Amount"),
      dataIndex: "amount",
      render: (_, { total_amount }) =>
        total_amount ? (
          <span className="cap">
            <Currency price={total_amount} />
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Invoice"),
      dataIndex: "link",
      key: "link",
      render: (_, record) => {
        return (
          <>
            <Link
              className="ant-btn ant-btn-default ms-sm-2 mt-xs-2 outline-btn"
              to={`/invoice/${record._id}`}
            >
              {lang("View")}
            </Link>

            <Tooltip title="Download" color={"purple"} key={"Download"}>
              <Button
                className="primary_btn btnStyle"
                 onClick={(e) => download(record.order_ids)}
              >
                {lang("Download")}
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: lang("Payment"),
      dataIndex: "payment_to",
      key: "payment_to",
      render: (_, { who_pay }) =>
        who_pay == "Admin"
          ? `${lang("Receive from Admin")}`
          : `${lang("Pay to Admin")}`,
    },
    {
      title: lang("Status"),
      render: (_, { status }) => {
        return (
          <>
            <Tooltip title="View Detail" color={"purple"} key={"viewDetail"}>
              <Button
                className="ms-sm-2 mt-xs-2 primary_btn btnStyle"
                // onClick={(e) => view(record._id)}
              >
                {lang(status)}
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="tab_inner_tit">
        <div className="tab-upload-wrap d-flex align-items-center justify-content-between flex-wrap">
          <h3>{lang("List of All Invoices")}</h3>
          <div className="d-flex align-items-center gap-3 flex-wrap">
            <div className="role-wrap">
              <Select
                width="110px"
                placeholder={lang("Year")}
                showSearch
                value={filter?.year}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={years?.map((item) => ({
                  value: item,
                  label: item,
                }))}
                onChange={(value) => onChange("year", value)}
              />
            </div>
            <div className="role-wrap">
              <Select
                width="110px"
                placeholder={lang("Month")}
                showSearch
                value={filter.month}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={months.map((item) => ({
                  ...item,
                }))}
                onChange={(value) => onChange("month", value)}
              />
            </div>

            <Button
              className="ms-sm-2 mt-xs-2 primary_btn manag-btn"
              onClick={() =>
                setFilter({
                  country_id: undefined,
                  city_id: undefined,
                  year: undefined,
                  month: undefined,
                })
              }
              type="primary"
              icon={<UndoOutlined />}
            >
              {lang("Reset")}
            </Button>
          </div>
        </div>
      </div>
      {/* <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4> */}

      <div className="table-responsive customPagination withOutSearilNo">
        <Table
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={{
            defaultPageSize: 10,
            responsive: true,
            total: pagination.total,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50"],
          }}
          onChange={handleChange}
          className="ant-border-space"
        />
      </div>
    </>
  );
};

const PaymentHistory = ({ selectedTab }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { request } = useRequest();
  const api = {
    status: apiPath.statusQuote,
    list: apiPath.history,
  };

  const { language } = useAppContext();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [paymentModal, showPaymentModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);
  const [page, setPage] = useState(1);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [show, setShow] = useState(false);

  const [selected, setSelected] = useState([]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  useEffect(() => {
    getCountry();
  }, []);

  const getCountry = () => {
    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (data) {
          setCountries(data);
        }
      },
    });
  };

  const getCities = (id, search, page) => {
    if (!id) return;
    request({
      url: `/country-city/${id}?page=${page}&pageSize=30${
        search ? `&search=${search}` : ""
      }`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          search
            ? setCities((prev) => [...data])
            : setCities((prev) => [...prev, ...data]);
        }
      },
    });
  };

  const getFilter = () => {
    request({
      url: `${apiPath.filters}`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (!filter?.country_id) return;
    getCities(filter.country_id, debouncedSearchCity, page);
  }, [page]);

  useEffect(() => {
    if (!filter?.country_id) return;
    getCities(filter.country_id, debouncedSearchCity, 1);
  }, [debouncedSearchCity]);

  const handleChange = (pagination, sorter, filters) => {
    if (!sorter) {
      fetchData(pagination);
    }
  };

  const fetchData = (pagination, status) => {
    setLoading(true);

    const payload = { ...filter };
    payload.page = pagination ? pagination.current : 1;
    payload.pageSize = pagination ? pagination?.pageSize : 10;

    const queryString = Object.entries(payload)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    request({
      url: apiPath.paymentHistory + `${queryString ? `?${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ data, total, status }) => {
        setLoading(false);
        if (status) {
          setList(data);
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    //  setLoading(true);
    fetchData(pagination);
    getFilter();
  }, [filter, refresh, selectedTab]);

  const columns = [
    {
      title: lang("Rest. ID"),
      dataIndex: "id",
      render: (_, { restaurant_id }) =>
        `#${restaurant_id ? restaurant_id.uid : ""}`,
    },
    {
      title: lang("Collection Date"),
      dataIndex: "dateTime",
      key: "dateTime",
      render: (_, { created_at }) => `${dateString(created_at, "ll")}`,
    },

    {
      title: lang("Area"),
      dataIndex: "Area",
      key: "Area",
      render: (_, { restaurant_id }) =>
        `${
          restaurant_id.area
            ? language !== "en" && language !== null
              ? restaurant_id.area?.[`${language}_name`] ??
                restaurant_id.area?.name
              : restaurant_id.area?.name
            : ""
        }`,
    },
    {
      title: lang("Total Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (_, { amount }) => <Currency price={amount ? amount : 0} />,
    },
    {
      title: lang("Payment Mode"),
      dataIndex: "payment_mode",
      key: "payment_mode",
      render: (_, { payment_mod }) => (payment_mod ? lang(payment_mod) : 0),
    },
    {
      title: lang("receipt No."),
      dataIndex: "receiptNo",
      key: "receipt No",
      render: (_, { uid }) => (uid ? uid : 0),
    },
    {
      title: lang("receipt Image"),
      dataIndex: "receiptImage",
      key: "receiptImage",
      render: (_, { image, name }) => (
        <Image src={image ? image : null} className="table-img image-doc" />
      ),
    },

    {
      title: lang("Status"),
      render: (_, record) => {
        return (
          <>
            <Tooltip
              title={lang("Payment Status")}
              color={"purple"}
              key={"viewDetail"}
            >
              <Button className="btnStyle btnOutlineDelete">
                {lang(`${record.status}`)}
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="tab_inner_tit">
        <div className="tab-upload-wrap d-flex align-items-center justify-content-between flex-wrap">
          <h3>{lang("PAYMENT HISTORY")}</h3>
          <div className="d-flex align-items-center gap-3 flex-wrap">
            <div className="role-wrap">
              <Select
                width="110px"
                placeholder={lang("Year")}
                showSearch
                value={filter?.year}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={years?.map((item) => ({
                  value: item,
                  label: item,
                }))}
                onChange={(value) => onChange("year", value)}
              />
            </div>
            <div className="role-wrap">
              <Select
                width="110px"
                placeholder={lang("Month")}
                showSearch
                value={filter.month}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={months.map((item) => ({
                  ...item,
                }))}
                onChange={(value) => onChange("month", value)}
              />
            </div>

            <Button
              className="ms-sm-2 mt-xs-2 primary_btn manag-btn"
              onClick={() =>
                setFilter({
                  country_id: undefined,
                  city_id: undefined,
                  year: undefined,
                  month: undefined,
                })
              }
              type="primary"
              icon={<UndoOutlined />}
            >
              {lang("Reset")}
            </Button>
          </div>
        </div>
      </div>
      {/* <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4> */}

      <div className="table-responsive customPagination withOutSearilNo">
        <Table
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={{
            defaultPageSize: 10,
            responsive: true,
            total: pagination.total,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50"],
          }}
          onChange={handleChange}
          className="ant-border-space"
        />
      </div>
      {paymentModal && (
        <PaymentModal
          data={selectedInvoice}
          show={paymentModal}
          hide={() => {
            showPaymentModal(false);
          }}
          refresh={() => setRefresh(!refresh)}
        />
      )}
    </>
  );
};

const PaymentModal = ({ show, hide, data, refresh }) => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(false);
  const [form] = useForm();
  const { request } = useRequest();

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  useEffect(() => {
    if (!data) return;
    console.log("data--", data);
    form.setFieldsValue({
      ...data,
      pay_date: moment(data?.pay_date),
      pay_time: moment(data?.pay_time),
    });
    setImage(data.image);
  }, [data]);

  const { currency } = useAuthContext();

  const onSubmit = (value) => {
    setLoading(true);
    const { amount, pay_date, pay_time, payment_type } = value;

    const payload = {
      amount,
      pay_date,
      pay_time,
      payment_type,
      image,
      invoice_id: data._id,
      restaurant_id: data.restaurant_id._id,
    };

    request({
      url: `${apiPath.pendingPaymentUpdate}/${data._id}`,
      method: "PUT",
      data: payload,
      onSuccess: ({ status, message }) => {
        setLoading(false);
        if (status) {
          hide();
          refresh();
        } else {
          ShowToast(message, Severty.ERROR);
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleImage = (data) => {
    setImage(data.length ? data[0].url : null);
  };

  return (
    <Modal
      width={750}
      open={show}
      onOk={() => null}
      onCancel={hide}
      // title={`${data ? "Update " + section : "Create a New " + section}`}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <Form
        id="create"
        layout="vertical"
        form={form}
        initialValues={{
          is_active: true,
          name: data.restaurant_id.name,
          city: data.city_id.name,
          area: data.restaurant_id.area?.name ?? "",
        }}
        onFinish={onSubmit}
      >
        <h4 className="modal_title_cls">Receive Payment</h4>
        <span>Reason : {data?.reason}</span>

        <Row gutter={[16, 0]}>
          <Col span={24} sm={12}>
            <Form.Item
              label={lang("Restaurant Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: lang("Name is required"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input
                disabled={true}
                autoComplete="off"
                placeholder={lang("Urla Restaurant")}
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item label={lang("City")} name="city">
              <Input disabled autoComplete="off" placeholder={"Abu Dhabi"} />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item label={lang("Area")} name="area">
              <Input disabled autoComplete="off" placeholder={"Downtown"} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={24} sm={12}>
            <Form.Item
              label={lang("Date")}
              name="pay_date"
              rules={[
                {
                  required: true,
                  message: lang("Date is required"),
                },
              ]}
            >
              <DatePicker autoComplete="off" placeholder={"Select Date"} />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              label={lang("Time")}
              name="pay_time"
              rules={[
                {
                  required: true,
                  message: lang("Time is required"),
                },
              ]}
            >
              <TimePicker
                use12Hours
                format="h:mm a"
                autoComplete="off"
                placeholder={"Select Time"}
              />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              label={lang(`Total Amount (${currency})`)}
              name="amount"
              // rules={[
              //   {
              //     required: true,
              //     message: lang("Amount is required"),
              //   },
              //   {
              //     validator: (_, value) => {
              //       if (value <= data.remaining_amount) {
              //         return Promise.resolve();
              //       }
              //       return Promise.reject(
              //         new Error(
              //           "Amount is not greater then invoice remaining amount",
              //         ),
              //       );
              //     },
              //   },
              // ]}
            >
              <InputNumber
                disabled={true}
                autoComplete="off"
                placeholder={"2,000"}
                onChange={(value) => {
                  if (value === data.remaining_amount) {
                    form.setFieldsValue({ payment_type: "Full payment" });
                  } else {
                    console.log("e.target.value", value);
                    form.setFieldsValue({ payment_type: "Partial payment" });
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              className="upload_wrap"
              label={"Upload Image"}
              name="image"
              rules={[
                {
                  validator: (_, value) => {
                    if (image) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Image is required"));
                  },
                },
              ]}
            >
              <SingleImageUpload
                value={image}
                imageType={"invoice"}
                onChange={(data) => handleImage(data)}
                fileType={FileType}
              />
            </Form.Item>
            <div>
              <Image
                src={image ? image : null}
                className="table-img image-doc"
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={24} sm={24}>
            <div className="status_wrap">
              <Form.Item
                label={lang("Choose a Payment")}
                name="payment_type"
                className="payment-form"
                rules={[
                  {
                    required: true,
                    message: lang("Payment Type is required"),
                  },
                ]}
              >
                <Radio.Group
                  disabled={true}
                  onChange={(e) => {
                    if (e.target.value === "Full payment") {
                      console.log("e.target.value", e.target.value);
                      form.setFieldsValue({ amount: data.remaining_amount });
                    } else {
                      console.log("e.target.value", e.target.value);
                      form.setFieldsValue({ amount: null });
                    }
                  }}
                >
                  <Radio value={"Full payment"}>{lang("Full payment")}</Radio>
                  <Radio value={"Partial payment"}>
                    {lang("Partial payment")}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};



export default Invoice;
